import { Avatar } from '@mui/material';
import { buildInitials } from '../../utils/formHelper';
import { useProfileActionsStyles } from './useProfileActionsStyles';
import AuthService from '../../services/AuthService';
import { useTranslation } from 'react-i18next';

export default function AvatarProfile({ src, alt = '.' }) {
  const classes = useProfileActionsStyles();
  const { first_name, last_name } = AuthService.getUserInfo();
  const { t } = useTranslation();

  return src ? (
    <Avatar alt={alt || '.'} src={src} className={classes.avatarInButton} />
  ) : (
    <Avatar
      className={classes.orangeAvatarInButton}
      aria-label={t('navbar.links.portfolio.avatarInitials')}
      alt={alt || '.'}
    >
      {buildInitials(first_name, last_name)}
    </Avatar>
  );
}
