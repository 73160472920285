import makeStyles from '@mui/styles/makeStyles';

export const IconWithMenuStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& svg': {
      path: theme.palette.primary.white,
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  paper: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondaryAccent.main,
    maxHeight: 'unset',
    maxWidth: 'unset',
    top: '56px !important',
    left: '0 !important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  containerNoti: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textTransform: 'initial',
    textAlign: 'start',
  },
  buttonNoti: {
    width: '100%',
    padding: 0,
    '&:focus-visible': {
      outlineColor: 'black !important',
      padding: '10px',
    },
  },
  boldLink: {
    fontWeight: 'bold',
    textAlign: 'left !important',
    flexGrow: 1,
  },
  notificationsMenu: {
    minWidth: '300px',
  },
  notificationsHeader: {
    backgroundColor: theme.palette.primary.white,
  },
  headerMessage: {
    color: 'red',
  },
  notificationItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondaryAccent.main,
    },
  },
  viewAllLink: {
    width: 'auto !important',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
  itemRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minWidth: 'unset',
    padding: theme.spacing(1),
    '& svg': {
      path: theme.palette.primary.white,
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    flexGrow: 1,
    textTransform: 'capitalize',
    textAlign: 'left !important',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  badge: {
    '& span': {
      height: 18,
      width: 18,
      fontSize: 10,
      marginTop: '10px',
      marginRight: '8px',
    },
  },
  badgeConvertionsUnRead: {
    '& span': {
      height: 12,
      width: 12,
      minWidth: 0,
      marginTop: '10px',
      marginRight: '8px',
    },
  },
  messagePaper: {
    color: theme.palette.secondaryAccent.main,
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.75)',
    borderRadius: 5,
    width: 400,
  },
  messageHeaderWrapper: {
    cursor: 'default',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.875, 0),
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  messageHeader: {
    fontSize: 18,
    fontWeight: 700,
  },
  messageNotEmpty: {
    placeContent: 'start',
    paddingLeft: '16px',
  },
  messageFooterWrapper: {
    backgroundColor: theme.palette.secondaryAccent.main,
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#393A39',
      color: theme.palette.secondaryAccent.main,
    },
  },
  allMenssage: {
    textDecoration: 'none',
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    placeContent: 'center',
    '&:focus-visible': {
      backgroundColor: '#393A39',
      color: theme.palette.secondaryAccent.main,
      outlineColor: 'white !important',
      padding: '10px',
    },
  },
  messageFooter: {
    color: theme.palette.primary.white,
    fontSize: 14,
    fontWeight: 700,
  },
  notificationBody: {
    display: 'flex',
    margin: 0,
    textTransform: 'initial',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(1.875, 3),
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  notificationTitleWrapper: {
    width: 264,
  },
  notificationTitle: {
    color: theme.palette.secondaryAccent.main,
    display: '-webkit-box',
    fontSize: 14,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  notificationDate: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 13,
  },
  emptyNotificationHeaderWrapper: {
    cursor: 'default',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.875, 0),
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  notificationHeaderWrapper: {
    cursor: 'default',
    borderRadius: 5,
    display: 'flex',
    padding: theme.spacing(1.875, 3),
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
}));
