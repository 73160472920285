import { Box, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { EntitiesType } from '../../../utils/Constants';
import { CallToAction } from '../../CallToAction/CallToAction';
import { useNotificationTabStyles } from '../useNotificationTabStyles';

export const PublishedNotification = ({ notification, firstName, onClose }) => {
  const entityName = notification.record_relation?.name;
  const imgSrc = notification?.record_relation?.url_media;
  const hiddenPortfolio = notification?.record_relation?.hidden;

  const isHiddenPortfolio = () => {
    return hiddenPortfolio === true ? true : false;
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useNotificationTabStyles();
  const history = useHistory();

  const goToEdit = () => {
    const type = notification.record_relation?.element_type;
    const id = notification.record_relation?.id;
    const portfolioURL = '/usuario/' + AuthService.getUserId();
    let url = '';
    switch (type) {
      case EntitiesType.PORTFOLIO:
      default:
        url = portfolioURL;
        break;
      case EntitiesType.SECTION:
        url = portfolioURL + '/portfolio/seccion/' + id;
        break;
      case EntitiesType.ARTICLE:
        url = portfolioURL + '/catalogo/articulo/' + id;
        break;
    }
    onClose();
    history.push(url);
  };
  const goToAdministrator = () => {
    const type = notification.record_relation?.element_type;
    const portfolioURL = '/tu-mica/';
    let url = '';
    switch (type) {
      default:
        url = portfolioURL;
        break;
      case EntitiesType.SECTION:
        url = portfolioURL + 'portfolio';
        break;
      case EntitiesType.ARTICLE:
        url = portfolioURL + 'catalogo';
        break;
    }
    onClose();
    history.push(url);
  };

  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Typography variant='body1'>
        {t('messages.notifications.dialogs.published_item.welcome', {
          firstName: firstName,
        })}
        <br />
        {isHiddenPortfolio()
          ? t('messages.notifications.dialogs.published_item.congratsHidden', {
              ...notification,
              entity: entityName,
            })
          : t('messages.notifications.dialogs.published_item.congrats', {
              ...notification,
              entity: entityName,
            })}
      </Typography>
      <Box
        display='flex'
        alignItems='center'
        style={{ margin: theme.spacing(3, 0) }}
      >
        <img
          src={imgSrc}
          alt={''}
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
        <Box
          display='flex'
          flexDirection='column'
          style={{ marginLeft: theme.spacing(3) }}
        >
          <Typography variant='body1'>{entityName}</Typography>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='flex-start'>
        {isHiddenPortfolio() ? (
          <CallToAction
            kind='primary'
            onClickHandler={goToAdministrator}
            classes={[classes.dialogButton]}
            label={t(`portfolio.bio.preview.go-to-entity-admin`)}
          />
        ) : (
          <CallToAction
            kind='primary'
            onClickHandler={goToEdit}
            classes={[classes.dialogButton]}
            label={t(
              'messages.notifications.dialogs.published_item.view-product'
            )}
          />
        )}
      </Box>
    </Container>
  );
};
