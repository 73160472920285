import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IconsLib from '../../icons/icons';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    width: '25px',
    height: '25px',
  },
  iconContainerRounded: {
    width: '62px',
    height: '62px',
  },
  iconContainerCustom: {
    width: (props) => props.size + 'px',
    height: (props) => props.size + 'px',
  },
  iconContainerMain: {
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

export const CustomIcon = (props) => {
  const classesCustom = useStyles(props);
  const {
    icon,
    classesIn,
    svgProps = { fillRule: 'evenodd', clipRule: 'evenodd' },
    color,
    onClick,
    iconSrc,
    size,
    iconAlt = '',
    styleSVG,
    height,
    width,
    iconStyle,
    viewBox,
    container = true,
  } = props;
  const theme = useTheme();

  return (
    <>
      {container ? (
        <div
          className={classnames(
            classesIn,
            classesCustom.iconContainerMain,
            size !== undefined
              ? classesCustom.iconContainerCustom
              : classesCustom.iconContainer
          )}
          onClick={onClick}
        >
          {icon ? (
            <svg
              viewBox={viewBox || '0 0 25.5 25'}
              width='100%'
              height='100%'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              style={styleSVG}
            >
              <path
                fill={color || theme.palette.secondary.main}
                d={IconsLib[icon]}
                {...svgProps}
              />
            </svg>
          ) : (
            <img
              src={iconSrc}
              alt={iconAlt}
              height={height}
              width={width}
              style={iconStyle}
            />
          )}
        </div>
      ) : (
        <img
          src={iconSrc}
          alt={iconAlt}
          height={height}
          width={width}
          style={iconStyle}
        />
      )}
    </>
  );
};
