import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondaryAccent.main,
    color: theme.palette.secondaryAccent.contrastText,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondaryAccent.light,
    },
    fontWeight: 'light',
    padding: theme.spacing(0.5, 1),
    // minWidth: '200px',
    borderRadius: theme.artistActionButtons.borderRadius,
    textTransform: 'initial',
    fontSize: '1rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
    '&[disabled]': {
      backgroundColor: theme.palette.secondaryAccent.extraLight,
      color: `${theme.palette.secondaryAccent.contrastText} !important`,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionDelete = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      endIcon={<ClearIcon />}
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
};
