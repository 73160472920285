import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';
import { CreateTextLinks } from '../../shared/CreateTextLinks';
import NotificationAppointmentDetail from '../backoffice/NotificationsMassive/NotificationAppointmentDetail';
import { CallToAction } from '../CallToAction/CallToAction';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },
  roundName: {
    marginBottom: theme.spacing(2),
    fontWeight: '600',
  },
  roundBanner: {
    marginBottom: theme.spacing(2),
  },
  roundContent: {
    marginBottom: theme.spacing(3),
    '& p': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
  },
  buttonBox: {
    marginTop: theme.spacing(2),
  },
}));

export const RoundNotificationContent = ({
  title,
  roundName,
  roundDescription,
  img,
  goToEvent,
  isSeller,
  date_text,
  location_text,
  cancelled_turn,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography
        className={classes.subtitle}
        dangerouslySetInnerHTML={{
          __html: t(title, {
            userName: AuthService.getUserInfo().first_name,
            roundName: roundName,
          }),
        }}
      />
      <Box display='flex' flexDirection='column'>
        <Typography className={classes.roundName}>{roundName}</Typography>
        <img src={`${img}`} alt={roundName} className={classes.roundBanner} />
        <Container
          maxWidth='md'
          disableGutters
          className={classes.roundContent}
        >
          <CreateTextLinks>{roundDescription}</CreateTextLinks>
        </Container>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <NotificationAppointmentDetail
          location_text={location_text}
          date_text={date_text}
          title={title}
          isSeller={isSeller}
          cancelled_turn={cancelled_turn}
        />

        {isSeller ? (
          <>
            <Typography className={classes.title}>
              {t('round-notification-dialog.confirm-title')}
            </Typography>
            <CallToAction
              kind='secondary'
              label={t('round-notification-dialog.confirm')}
              onClickHandler={goToEvent}
            />
          </>
        ) : (
          <Box
            className={classes.buttonBox}
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
          >
            <CallToAction
              kind='primary'
              onClickHandler={goToEvent}
              classes={[classes.dialogButton]}
              label={t('round-notification-dialog.details')}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
