import { createContext, useState } from 'react';

const CurrentUserContext = createContext({});

export function CurrentUserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [isLoadingTokenUser, setIsLoadingTokenUser] = useState(true);

  return (
    <CurrentUserContext.Provider
      value={{
        userData,
        setUserData,
        isLoadingTokenUser,
        setIsLoadingTokenUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserContext;
