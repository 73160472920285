import { Button, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.main,
      borderColor: 'currentcolor',
    },
    border: theme.shape.border,
    borderColor: '#000',
    color: theme.palette.secondaryAccent.light,
    fontWeight: 'light',
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0, 0.5),
    borderRadius: theme.artistActionButtons.borderRadius,
    fontSize: '.85rem',
    // minWidth: '100px',
    // width: 'fit-content',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionExtra = (props, rounded) => {
  const classes = useStyles();
  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Tooltip title={!props.disabled && props?.title ? props?.title : ''}>
      <Button
        ref={props.forwardedRef}
        className={[classes.button, extraClasses].join(' ')}
        onClick={(e) => props.onClickHandler?.(e)}
        onBlur={(e) => props.onBlur?.(e)}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
        id={props?.id}
        name={props?.name}
      >
        <Typography
          className={[classes.text, extraClasses].join(' ')}
          variant='button'
        >
          {props.icon && props.icon(classes.buttonIcon)}
          {props.label}
        </Typography>
      </Button>
    </Tooltip>
  );
};
