import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import store from './redux/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//desactivo Sentry para el entorno de desarrollo
if (process.env.NODE_ENV !== 'development') {
  const environment = process.env.NODE_ENV;
  const dns = process.env.REACT_APP_SENTRY_DNS;
  //uso el hash del commit como release de sentry para linkear los sourcemaps
  const release = process.env.REACT_APP_COMMIT; //(viene del dockerfile y gitlab)
  Sentry.init({
    dsn: dns,
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: environment,
    release: release,
    replaysOnErrorSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
  });
}

// https://enbonnet.me/article/47/aplicando-la-accesibilidad-en-reactjs
if (process.env.NODE_ENV !== 'production') {
  //TODO: saque react-axe que está deprecado, pasar a @axe-core/react
}

if (process.env.NODE_ENV === 'production') {
  console.log(
    '%cMercado de Industrias Culturales Argentinas',
    'font-size: 48px;font-weight: bold; margin-top: 20px;color: red;text-shadow: 2px 2px #lightgray;'
  );
  console.log(
    '%cConocé a los desarrolladores de ARSAT https://mica.gob.ar/developers',
    'font-size: 16px;font-weight: lighter;margin-top: 30px;'
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});
