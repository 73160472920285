import { Typography, useMediaQuery } from '@mui/material';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import {
  AUDIOVISUAL_CONTENT_TYPES,
  HIGHLIGHT_IMAGE,
  IMAGE_SIZE,
  VIDEO_ORIGINS,
} from '../../../../utils/Constants';
import { addHttp } from '../../../../utils/utils';
import { CreateTextLinks } from '../../../../shared/CreateTextLinks';
import { HighlightStyles } from './HighlightStyles';
import { ImageLazy } from '../../../../components/Imagelazy/ImageLazy';
import { PlayIcon } from '../../../../shared/Elements/PlayIcon';
import { theme } from '../../../../theme';
import { useHomeStyles } from './useHomeStyles';

export default function Highlight({ highlight }) {
  const classes = HighlightStyles();
  const classesHome = useHomeStyles();
  const { t } = useTranslation();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getImageHeight = () =>
    isUpLg
      ? HIGHLIGHT_IMAGE.UP_LG.HEIGHT
      : isUpMd
      ? HIGHLIGHT_IMAGE.UP_MD.HEIGHT
      : isUpSm
      ? HIGHLIGHT_IMAGE.UP_SM.HEIGHT
      : HIGHLIGHT_IMAGE.DEFAULT.HEIGHT;

  const getImageWidth = () =>
    isUpLg
      ? HIGHLIGHT_IMAGE.UP_LG.WIDTH
      : isUpMd
      ? HIGHLIGHT_IMAGE.UP_MD.WIDTH
      : isUpSm
      ? HIGHLIGHT_IMAGE.UP_SM.WIDTH
      : HIGHLIGHT_IMAGE.DEFAULT.WIDTH;

  const getVideoUrl = () => {
    const isUrlLocal = highlight?.video_origin_type === VIDEO_ORIGINS.LOCAL;
    const localUrl = highlight?.content?.video?.local_video?.versions?.[0]?.url;
    const externalUrl = highlight?.video_url;
    return isUrlLocal ? localUrl : externalUrl;
  };

  return (
    <>
      {highlight && (
        <article className={classesHome.root}>
          <section
            id='highlight'
            aria-label={t('home.highlight.label') + highlight.title}
          >
            <div className={classes.container}>
              <div className={classes.containerBackground}>
                <div className={classes.mediaContainer}>
                  {highlight?.av_content_type ===
                  AUDIOVISUAL_CONTENT_TYPES.PHOTO ? (
                    <ImageLazy
                      sizePreferer={IMAGE_SIZE.ORIGINAL}
                      srcVersions={highlight?.content?.image?.versions}
                      alt={highlight?.content?.image?.alt_text ?? ''}
                      classes={classes.image}
                      height={getImageHeight()}
                      width={getImageWidth()}
                    />
                  ) : (
                    <div className={classes.playerWrapper}>
                      <ReactPlayer
                        className={classes.player}
                        url={getVideoUrl()}
                        loop={false}
                        muted={false}
                        width='100%'
                        playing={true}
                        playIcon={
                          <PlayIcon
                            ariaLabel={t('home.highlight.videoLabel', {
                              imgAltText: highlight?.content?.image?.alt_text,
                            })}
                          />
                        }
                        light={highlight?.content?.image?.versions[0]?.url}
                        config={{
                          file: {
                            forceVideo: true,
                            attributes: {
                              controlsList: 'nodownload',
                            },
                          },
                        }}
                        controls={true}
                        onContextMenu={(e) => e.preventDefault()}
                        previewTabIndex={-1}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.infoWrapper}>
                  <div className={classes.titleWrapper}>
                    <Typography
                      id='highlight-title'
                      variant='h2'
                      className={classes.title}
                    >
                      {highlight.title}
                    </Typography>
                  </div>
                  {highlight?.abstract_text && (
                    <div variant='h5' className={classes.abstractTextWrapper}>
                      <CreateTextLinks id='highlight-text'>
                        {highlight.abstract_text}
                      </CreateTextLinks>
                    </div>
                  )}
                  {highlight?.button_display && (
                    <a
                      href={addHttp(highlight?.button_link)}
                      target={highlight?.button_new_window_opt ? '_blank' : ''}
                      rel='noreferrer'
                      className={classes.falseButton}
                      aria-label={highlight?.button_text}
                    >
                      {highlight?.button_text}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>
        </article>
      )}
    </>
  );
}
