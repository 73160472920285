import { useContext, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import SectorsContext from './../context/SectorsContext';

export function useSectors() {
  const [isLoadingSectors, setIsLoadingSectors] = useState(false);
  const [errorSectors, setErrorSectors] = useState('');
  const { sectors, setSectors } = useContext(SectorsContext);

  useEffect(() => {
    shouldGetSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldGetSectors = async () => {
    if (sectors.length === 0) await getSectors();
  };

  const getSectors = async () => {
    await AuthService.getSectorsOptions()
      .then(({ data }) => {
        setIsLoadingSectors(true);
        setSectors(data);
      })
      .catch((error) => {
        setErrorSectors(error);
      })
      .finally(() => setIsLoadingSectors(false));
  };

  const findSectorByKey = (key) => {
    return sectors.find((sector) => sector.key === key);
  };

  const getSectorDescriptionByKey = (key) => {
    let foundSector = null;
    let labelSector = '';
    if (sectors.length !== 0) {
      foundSector = findSectorByKey(key);
      labelSector = foundSector ? foundSector.description : '';
    }
    return labelSector;
  };

  const getDescriptionSectors = (sectorsKeys) => {
    return sectorsKeys.map((sector) => getSectorDescriptionByKey(sector));
  };
  const getSectorsPromise = async () => {
    return new Promise((resolve, reject) => {
      AuthService.getSectorsOptions()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getSectorsFilter = (sectorKey) => {
    return new Promise(async (resolve) => {
      if (sectorKey) {
        let sectorKeys = sectorKey.split(',');
        let arrayFilters = [];
        let sec = await getSectorsPromise();
        sectorKeys.forEach((element) => {
          arrayFilters.push(sec.find((sector) => sector.key === element));
        });

        resolve(arrayFilters?.map((sector) => sector.key));
      } else {
        resolve([]);
      }
    });
  };

  return {
    errorSectors,
    isLoadingSectors,
    sectors,
    getSectors,
    getSectorDescriptionByKey,
    getDescriptionSectors,
    findSectorByKey,
    getSectorsFilter,
  };
}
