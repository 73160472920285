import { Container, Dialog } from '@mui/material';
import { NotificationDetails } from './NotificationDetails';

export const NotificationDetailModal = ({ isOpen, onClose, notification }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Container maxWidth='md'>
        <NotificationDetails notification={notification} doClose={onClose} />
      </Container>
    </Dialog>
  );
};
