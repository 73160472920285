import { useState } from 'react';
import { useTheme } from '@mui/material';
import { getImageBySize } from '../../utils/utils';
import {
  IMAGE_LOADING,
  IMAGE_PRIORITY,
  IMAGE_SIZE,
  IMAGE_SIZES_SORTED,
} from '../../utils/Constants';

export const ImageLazy = (props) => {
  const theme = useTheme();
  const sm = theme.breakpoints.values['sm']; // sm, small: 600px
  // const md = theme.breakpoints.values['md']; // md, large: 900px
  const lg = theme.breakpoints.values['lg']; // lg, large: 1200px
  const xl = theme.breakpoints.values['xl']; // xl, extra-large: 1536px
  const [onErrorRun, setOnErrorRun] = useState(true);

  const {
    srcVersions = [],
    src,
    alt = '',
    classes = '',
    width = '100%',
    height = 'auto',
    float = 'left',
    loading = IMAGE_LOADING.LAZY,
    sizePreferer = IMAGE_SIZE.RESPONSIVE,
    onError,
    priority = IMAGE_PRIORITY.AUTO,
    pictureWidth,
    pictureHeight,
    pictureClass,
    imageIndex,
    maxHeight = 'auto',
    maxWidth = 'auto',
    inAgendaStrip,
  } = props;

  const MEDIA_BREAKPOINTS = {
    thumbnail: '',
    small: `(max-width: ${sm}px)`,
    medium: `(max-width: ${lg}px)`,
    large: `(max-width: ${xl}px)`,
    original: ``,
  };

  const srcVersionFiltered =
    sizePreferer === IMAGE_SIZE.RESPONSIVE
      ? srcVersions.sort(function (a, b) {
          return (
            IMAGE_SIZES_SORTED.indexOf(a.desc) -
            IMAGE_SIZES_SORTED.indexOf(b.desc)
          );
        })
      : srcVersions.filter((v) => sizePreferer === v?.desc);

  return (
    <picture
      className={pictureClass}
      style={{
        width: pictureWidth || width,
        float: float,
        height: pictureHeight || height,
        backgroundColor: inAgendaStrip ? '#ffffff' : 'transparent',
      }}
    >
      {srcVersions.length > 0 &&
        !imageIndex &&
        srcVersionFiltered.map(({ desc, url, content_type }, index) => (
          <source
            desc={desc}
            srcSet={url}
            type={content_type}
            media={
              sizePreferer === IMAGE_SIZE.RESPONSIVE
                ? MEDIA_BREAKPOINTS[desc]
                : ''
            }
            key={index}
          />
        ))}
      <img
        loading={loading}
        src={src || getOriginalImage(srcVersions)}
        alt={alt}
        className={classes}
        width={
          width ||
          (imageIndex ? srcVersions[imageIndex]?.width : srcVersions[0]?.width)
        }
        height={
          height ||
          (imageIndex
            ? srcVersions[imageIndex]?.height
            : srcVersions[0]?.height)
        }
        style={{ maxHeight: maxHeight, maxWidth: maxWidth }}
        fetchpriority={priority}
        onError={(e) => {
          if (onError && onErrorRun) {
            onError(e);
            setOnErrorRun(false);
          }
        }}
      />
    </picture>
  );
};

const getOriginalImage = (srcVersions) => {
  return getImageBySize(srcVersions, IMAGE_SIZE.ORIGINAL);
};
