import { useNotesStyles } from './useNotesStyles';
import { NoteCardLandscape } from '../../containers/views/public/home/NoteCardLandscape';
import { createFriendlyLink } from '../../utils/utils';
import ExternalLink from './ExternalLink';

const HomeNote = ({ note, linkOn = true, width, height }) => {
  const classes = useNotesStyles();
  return (
    <ExternalLink
      to={`/notas/${createFriendlyLink(note?.id_event, note?.title)}`}
      href={note?.link}
      newWindow={note?.link_new_window}
      classes={classes.noveltyCardLink}
      linkOn={linkOn}
    >
      <NoteCardLandscape note={note} width={width} height={height} />
    </ExternalLink>
  );
};

export default HomeNote;
