import { Box, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ArtistLocation from '../../Artist/ArtistLocation';
import PortfolioImage from '../../PorfolioImage/PorfolioImage';
import { CallToAction } from '../../CallToAction/CallToAction';
import { useNotificationTabStyles } from '../useNotificationTabStyles';

export const NewFollowerNotification = ({
  notification,
  firstName,
  onClose,
}) => {
  const followerName =
    notification.from_user?.first_name +
    ', ' +
    notification.from_user?.last_name;
  const followerSrc = notification.record_relation?.url_media;
  const followerLocation =
    notification.from_user?.state + ', ' + notification.from_user?.country;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useNotificationTabStyles();
  const history = useHistory();

  const goToPortfolio = () => {
    onClose();
    history.push('/usuario/' + notification.from_user?.id_user);
  };

  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Typography variant='body1'>
        {t('messages.notifications.dialogs.' + notification.type + '.welcome', {
          firstName: firstName,
        })}
        <br />
        {t('messages.notifications.dialogs.' + notification.type + '.congrats')}
      </Typography>
      <Box
        display='flex'
        alignItems='center'
        style={{ marginTop: theme.spacing(2) }}
      >
        <PortfolioImage imageSrc={followerSrc} size='lg' />
        <Box
          display='flex'
          flexDirection='column'
          style={{ marginLeft: theme.spacing(3) }}
        >
          <Typography
            variant='body1'
            style={{ fontWeight: theme.typography.fontWeightBold }}
          >
            {followerName}
          </Typography>
          <ArtistLocation location={followerLocation} />
        </Box>
      </Box>
      <Typography variant='body1'>
        <br />
        {t('messages.notifications.dialogs.follow.text-1')}
        <br />
        <br />
        {t('messages.notifications.dialogs.follow.text-2')}
        <br />
        <br />
      </Typography>
      <Box display='flex' alignItems='center' style={{ marginTop: '1rem' }}>
        <CallToAction
          kind='primary'
          onClickHandler={goToPortfolio}
          classes={[classes.dialogButton]}
          label={t('messages.notifications.dialogs.follow.view-portfolio')}
        />
        <CallToAction
          kind='extra'
          onClickHandler={() => {}}
          classes={[classes.dialogButton]}
          label={t('messages.notifications.dialogs.follow.send-message')}
        />
      </Box>
    </Container>
  );
};
