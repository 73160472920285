import React, { useEffect, useRef, useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import classnames from 'classnames';
import { IMAGE_SIZE, IntervalTimes } from '../../utils/Constants';
import { ImageLazy } from '../Imagelazy/ImageLazy';
import DOMPurify from 'dompurify';
import { getSliderColor, getSliderColorText } from '../../utils/utils';
import { useCustomCarouselWithTextStyles } from './CustomCarouselWithTextStyles';
import SkipLink from '../../shared/Elements/SkipLinks/SkipLink';
import { useTranslation } from 'react-i18next';
import ArrowIconCustomCarrousel from './ArrowIconCustomCarrousel';
// https://github.com/leandrowd/react-responsive-carousel

function CustomCarouselWithText({
  arrows = true,
  thumbs = false,
  indicators = true,
  stopOnHover = true,
  showStatus = false,
  autoPlay = false,
  infiniteLoop = false,
  navigateWithKeyboard = false,
  items = [],
  interval = IntervalTimes.SLIDER,
  tabPressed = false,
  swipeable = false,
}) {
  const { t } = useTranslation();
  const classes = useCustomCarouselWithTextStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [sliderSelected, setsliderSelected] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  let carousel = useRef(null); // use useRef to get the carousel instance

  useEffect(() => {
    let timerId;
    if (isLastSlide) {
      timerId = setTimeout(() => {
        setsliderSelected(0);
        setIsLastSlide(false);
      }, interval);
    }
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastSlide]);

  useEffect(() => {
    // some validation to set the slider to 0
    if (carousel && carousel?.state?.selectedItem > 0) {
      carousel.state.selectedItem = 0;
    }
  }, [items, tabPressed]);

  const isSliderSelected = (index) => {
    return sliderSelected === index;
  };

  const updateCurrentSlide = (index) => {
    if (sliderSelected !== index) {
      setsliderSelected(index);
    }
    if (index === items.length - 1) {
      setIsLastSlide(true);
    } else if (index < sliderSelected) {
      setIsLastSlide(false);
    }
  };
  const handleMouseOver = () => {
    setShowArrows(true);
  };

  const handleMouseLeave = () => {
    setShowArrows(false);
  };

  return items?.length > 0 ? (
    <section
      id='slider'
      aria-label={t('home.slider.label', {
        size: items?.length,
      })}
    >
      <SkipLink href={`#sectors`}>{t('home.slider.skip-to-sectors')}</SkipLink>
      <div
        onFocus={handleMouseOver}
        onBlur={handleMouseLeave}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <Carousel
          ref={(el) => (carousel = el)} // useRef
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          showArrows={arrows}
          showThumbs={thumbs}
          showIndicators={indicators}
          showStatus={showStatus}
          renderArrowPrev={ArrowFactory('prev', classes, arrows, showArrows)}
          renderArrowNext={ArrowFactory('next', classes, arrows, showArrows)}
          className={classnames(classes.slider, classes.sliderCalc, classes)}
          autoPlay={autoPlay}
          infiniteLoop={infiniteLoop}
          stopOnHover={stopOnHover}
          interval={interval}
          useKeyboardArrows={navigateWithKeyboard}
          labels={{
            leftArrow: t('accessibility.slider.arrows.prev'),
            rightArrow: t('accessibility.slider.arrows.next'),
          }}
          selectedItem={sliderSelected}
          onChange={updateCurrentSlide}
          autoFocus={false}
        >
          {items?.length > 0 &&
            items.map((item, idx) => (
              <a
                // key={idx}
                key={item?.id_slide}
                rel='noreferrer'
                href={item?.link?.url ? item.link.url : null}
                className={classes.carouselContainer}
                target={item?.link?.target}
                tabIndex={isSliderSelected(idx) ? 0 : -1}
                style={{
                  backgroundColor: getSliderColor(item.color),
                }}
              >
                <div className={classes.sliderContainer}>
                  <div
                    className={classes.carouselImage}
                    style={{
                      height: isMobile ? '100%' : '300px',
                    }}
                  >
                    <div className={classes.text}>
                      {item.top_text && (
                        <Typography variant='h5' className={classes.top_text}>
                          {item.top_text}
                        </Typography>
                      )}
                      <div className={classes.boxTexts}>
                        {item.title && (
                          <Typography
                            variant='h4'
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(item.title),
                            }}
                            className={classes.title}
                            style={{ color: getSliderColorText(item.color) }}
                          />
                        )}
                        {item.description && (
                          <Typography
                            variant='h6'
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(item.description),
                            }}
                            className={classes.description}
                            style={{ color: getSliderColorText(item.color) }}
                          />
                        )}
                        {item.link && (
                          <div
                            tabIndex={-1}
                            rel='noreferrer'
                            href={item.link.url}
                            target={item.link.target}
                            className={classes.carouselLink}
                            style={{
                              color: getSliderColorText(item.color),
                              border: `1px solid ${getSliderColorText(
                                item.color
                              )}`,
                            }}
                          >
                            <Typography
                              className={classes.linkText}
                              style={{
                                color: getSliderColorText(item.color),
                              }}
                            >
                              {item.link.text}
                            </Typography>
                          </div>
                        )}
                      </div>
                      {item.button_text && (
                        <Typography variant='body1' className={classes.owner}>
                          {item.button_text}
                        </Typography>
                      )}
                    </div>
                    <div>
                      {item.image && (
                        <ImageLazy
                          srcVersions={item.image.versions}
                          alt={item.image.alt_text ?? '.'}
                          height={'100%'}
                          width={isMobile ? '100%' : '50%'}
                          loading={idx === 0 ? 'eager' : 'lazy'}
                          float={!isMobile ? 'right' : 'left'}
                          priority={idx === 0 ? 'hight' : 'low'}
                          sizePreferer={IMAGE_SIZE.ORIGINAL}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </a>
            ))}
        </Carousel>
      </div>
    </section>
  ) : null;
}

function ArrowFactory(direction, classes, arrows, showArrows) {
  const icon =
    direction === 'prev' ? (
      <ArrowIconCustomCarrousel
        backgroundColor={'white'}
        colorArrow='black'
        display={showArrows}
      />
    ) : (
      <ArrowIconCustomCarrousel
        backgroundColor={'white'}
        colorArrow='black'
        right
        display={showArrows}
      />
    );

  return (clickHandler, hasNext, label) => {
    const handlerClick = () => {
      clickHandler();
    };
    return arrows === false ? null : (
      <button
        className={classnames(
          classes.arrowContainer,
          classes[`arrow-${direction}`],
          {
            [classes.arrowDisabled]: !hasNext,
          }
        )}
        onClick={handlerClick}
        aria-label={label}
        aria-disabled={!hasNext}
      >
        {icon}
      </button>
    );
  };
}

export default CustomCarouselWithText;
