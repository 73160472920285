import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { buildPortfolioPublishedPath } from '../../../shared/BackOffice/Buttons/BOCommonButtons';
import { CustomIcon } from '../../CustomIcons/CustomIcons';
import { PortfolioSectorStyles } from './PortfoliosBySectorStyles';
import { ImageLazy } from '../../Imagelazy/ImageLazy';

const PortfolioCardSector = ({
  srcVersions,
  title,
  location,
  id,
  sector,
  colors,
}) => {
  const classes = PortfolioSectorStyles({ colors });
  return (
    <Link to={buildPortfolioPublishedPath(id)} className={classes.cardWrapper}>
      {sector && (
        <div className={classes.containerSector}>
          <div className={classes.containerArrow}>
            <CustomIcon
              icon='arrow-right'
              size={'14'}
              color={'black'}
              styleSVG={{
                stroke: 'black',
                strokeWidth: '1px',
                padding: '1px',
              }}
            />
          </div>
          <div className={classes.sectorTitle}>{sector}</div>
        </div>
      )}
      <div className={classes.card}>
        <ImageLazy
          width='110px'
          height='110px'
          srcVersions={srcVersions}
          classes={classes.imgCard}
        />
      </div>
      <div className={classes.containerTitlePortfolio}>
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.containerLocation}>
        <div className={classes.pointLocation}></div>
        <Tooltip title={location}>
          <div className={classes.location}>{location}</div>
        </Tooltip>
      </div>
    </Link>
  );
};

export default PortfolioCardSector;
