import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  marker: {
    width: '6px',
    height: '6px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '100%',
    marginTop: '7px',
    minWidth: '6px',
  },
  locationText: {
    fontSize: '.65rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '.75rem',
    },
  },
  locationTextCustom: {
    fontSize: (props) => props.fontSize,
    paddingLeft: theme.spacing(0.5),
  },
}));

function ArtistLocation(props) {
  const classes = useStyles(props);
  const fontSize = props;
  return (
    <div className={classes.root}>
      <div className={classes.marker}></div>
      <Typography
        variant='caption'
        color='textSecondary'
        className={fontSize ? classes.locationTextCustom : classes.locationText}
      >
        {props.location}
      </Typography>
    </div>
  );
}

export default ArtistLocation;
