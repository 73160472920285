import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    border: `1px solid ${theme.palette.dark.extraLight}`,
    fontWeight: '600',
    fontSize: '10px',
  },
  chipColorTransparent: {
    backgroundColor: 'transparent',
  },
}));

function ArtistCategorySearch(props) {
  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];
  const classes = useStyles();
  const { sector } = props;
  if (sector) {
    return (
      <div className={[classes.chipGroup, ...extraClasses].join(' ')}>
        <Chip
          label={sector}
          size='small'
          className={classnames(classes.chip, classes.chipColorTransparent)}
        />
      </div>
    );
  } else {
    return <></>;
  }
}

export default ArtistCategorySearch;
