import moment from 'moment';

export const getParticipantFilters = (params) => {
  let filters = params.userName
    ? 'username_filter=' + encodeURIComponent(params.userName.trim())
    : '';

  if (params.active !== '') {
    filters += params.active ? '&active_filter=true' : '&active_filter=false';
  }

  if (params.isPortfolioPublished) {
    filters +=
      params.isPortfolioPublished && params.isPortfolioPublished !== ''
        ? '&has_portfolio_published=' + params.isPortfolioPublished
        : '';
  }

  if (params.isPortfolioDraft) {
    filters +=
      params.isPortfolioDraft && params.isPortfolioDraft !== ''
        ? '&has_portfolio_draft=' + params.isPortfolioDraft
        : '';
  }

  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_event ? '&id_event=' + params.id_event : '';

  if (params.interest_rounds && params.interest_rounds.length !== 0) {
    params.interest_rounds.map(
      (item) => (filters += '&rdn_form_interested=' + item)
    );
  }

  if (params.primary_sector && params.primary_sector.length !== 0) {
    params.primary_sector.map(
      (item) => (filters += '&rdn_form_main_sector=' + item)
    );
  }

  if (params.secondary_sector && params.secondary_sector.length !== 0) {
    params.secondary_sector.map(
      (item) => (filters += '&rdn_form_secondary_sector=' + item)
    );
  }

  filters += params.rounds_participated
    ? '&rdn_form_round_participation=' + params.rounds_participated
    : '';

  filters += params.international_participation
    ? '&rdn_form_fair_market_participation=' +
      params.international_participation
    : '';

  if (
    params.mica_previous_participations &&
    params.mica_previous_participations.length !== 0
  ) {
    params.mica_previous_participations.map(
      (item) => (filters += '&rdn_form_MICA_participation=' + item)
    );
  }

  if (params.exported_experience && params.exported_experience.length !== 0) {
    params.exported_experience.map(
      (item) => (filters += '&rdn_form_export_level=' + item)
    );
  }

  filters += params.consolidated_exportable_offer
    ? '&rdn_form_export_offert=' + params.consolidated_exportable_offer
    : '';

  if (
    params.portfolio_business_profile &&
    params.portfolio_business_profile.length !== 0
  ) {
    params.portfolio_business_profile.map(
      (item) => (filters += '&rdn_form_has_portfolio_profile=' + item)
    );
  }
  return filters;
};

export const getUserFilters = (params) => {
  let filters = params.userName
    ? 'username_filter=' + encodeURIComponent(params.userName.trim())
    : '';
  filters += params.exactUsername
    ? '&username=' + encodeURIComponent(params.exactUsername)
    : '';
  filters += params.id_user ? '&id_user=' + params.id_user : '';
  filters += params.name ? '&name=' + params.name : '';
  filters += params.sector ? '&sector_filter=' + params.sector : '';
  if (params.active !== '') {
    filters += params.active ? '&active_filter=true' : '&active_filter=false';
  }
  if (params.roles && params.roles.length !== 0) {
    params.roles.map((role) => (filters += '&role_filter=' + role));
  }
  if (params.isPortfolioPublished) {
    filters +=
      params.isPortfolioPublished && params.isPortfolioPublished !== ''
        ? '&has_portfolio_published=' + params.isPortfolioPublished
        : '';
  }

  if (params.isPortfolioDraft) {
    filters +=
      params.isPortfolioDraft && params.isPortfolioDraft !== ''
        ? '&has_portfolio_draft=' + params.isPortfolioDraft
        : '';
  }

  filters += params.events_interested
    ? '&events_interested=' + params.events_interested
    : '';

  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';

  filters += params.id_event ? '&id_event=' + params.id_event : '';
  filters += params.extra_sectors
    ? '&extra_sectors=' + params.extra_sectors
    : '';
  filters += params.created_from_backoffice
    ? '&created_from_backoffice=' + params.created_from_backoffice
    : '';
  filters += params.rounds_type_interested
    ? '&rounds_type_interested=' + params.rounds_type_interested
    : '';
  filters += params.rounds_participated
    ? '&rounds_participated=' + params.rounds_participated
    : '';
  filters += params.fairs_markets_participated
    ? '&fairs_markets_participated=' + params.fairs_markets_participated
    : '';
  if (params.exported_articles && params.exported_articles.length !== 0) {
    params.exported_articles.map(
      (item) => (filters += '&exported_articles=' + item)
    );
  }
  filters += params.export_offer_consolidated
    ? '&export_offer_consolidated=' + params.export_offer_consolidated
    : '';

  filters += params.mica_participated
    ? '&mica_participated=' + params.mica_participated
    : '';

  filters += params.mica_participated_detail
    ? '&mica_participated_detail=' + params.mica_participated_detail
    : '';

  filters += params.sales_through_mica
    ? '&sales_through_mica=' + params.sales_through_mica
    : '';

  filters += params.sales_through_int_rounds
    ? '&sales_through_int_rounds=' + params.sales_through_int_rounds
    : '';

  if (params.portfolioFilters && params.portfolioFilters.length !== 0) {
    params.portfolioFilters.map(
      (item) => (filters += '&portfolio_filter=' + item)
    );
  }
  return filters;
};

export const getRegistersStatsFilters = (params) => {
  let filters = '';
  filters += params.category ? 'category=' + params.category : '';
  filters += params.group_filter ? '&group_filter=' + params.group_filter : '';

  return filters;
};
export const getNodeStatsFilters = (params) => {
  let filters = '';
  filters += params.date_start_filter
    ? 'date_start_filter=' + params.date_start_filter
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' + params.date_end_filter
    : '';

  return filters;
};
export const getRegistersDetailFilters = (params) => {
  let filters = '';
  filters += params.country_filter
    ? 'country_filter=' + params.country_filter + '&'
    : '';
  filters += params.state_filter
    ? 'state_filter=' + params.state_filter + '&'
    : '';
  filters += params.category ? 'category=' + params.category + '&' : '';
  filters += params.sector ? 'sectors_filter=' + params.sector : '';

  return filters;
};

export const getPlotFilters = (params) => {
  let filters = '?';
  filters += params.date_group ? '&date_group=' + params.date_group : '';
  filters += params.window ? '&window=' + params.window : '';
  filters += params.country_filter
    ? '&country_filter=' + params.country_filter
    : '';
  filters += params.state_filter ? '&state_filter=' + params.state_filter : '';

  filters += params?.sector ? '&sectors_filter=' + params?.sector : '';

  if (params.device_filter !== '') {
    filters += '&device_filter=' + params.device_filter;
  }

  filters += params.date_start_filter
    ? '&date_start_filter=' + encodeURIComponent(params.date_start_filter)
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' + encodeURIComponent(params.date_end_filter)
    : '';
  filters += params.page_size ? '&page_size=' + params.page_size : '';
  filters += params.page_num ? '&page_num=' + params.page_num : '';
  if (params.cycle && params.cycle.length !== 0) {
    params.cycle.map((cycle) => (filters += '&cycle_filter=' + cycle));
  }
  return filters;
};

export const getBasicPlotFilters = (params) => {
  let filters = '?';
  filters += params.country_filter
    ? '&country_filter=' + params.country_filter
    : '';
  filters += params.state_filter ? '&state_filter=' + params.state_filter : '';
  if (params.device_filter !== '') {
    filters += '&device_filter=' + params.device_filter;
  }
  filters += params.date_start_filter
    ? '&date_start_filter=' + encodeURIComponent(params.date_start_filter)
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' + encodeURIComponent(params.date_end_filter)
    : '';
  if (params.cycle && params.cycle.length !== 0) {
    params.cycle.map((cycle) => (filters += '&cycle_filter=' + cycle));
  }
  return filters;
};

export const getPlotFiltersRegister = (params) => {
  let filters = '?';
  filters += params.category ? 'category=' + params.category + '&' : '';
  filters += params.group_filter ? 'group_filter=' + params.group_filter : '';
  return filters;
};

export const getBasicDomainFilters = (params) => {
  let filters = '?';
  filters += params?.landing_filter
    ? '&landing_filter=' + params?.landing_filter
    : '';
  filters += params.country_filter
    ? '&country_filter=' + params.country_filter
    : '';
  filters += params.state_filter ? '&state_filter=' + params.state_filter : '';
  if (params.device_filter !== '') {
    filters += '&device_filter=' + params.device_filter;
  }
  filters += params.date_start_filter
    ? '&date_start_filter=' + encodeURIComponent(params.date_start_filter)
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' + encodeURIComponent(params.date_end_filter)
    : '';
  filters += params.date_group ? '&date_group=' + params.date_group : '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  return filters;
};
export const getBasicDomainExportFilters = (params) => {
  let filters = '?';
  filters += params.date_start_filter
    ? '&date_start_filter=' + encodeURIComponent(params.date_start_filter)
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' + encodeURIComponent(params.date_end_filter)
    : '';
  filters += params.country_filter
    ? '&country_filter=' + params.country_filter
    : '';
  filters += params.state_filter ? '&state_filter=' + params.state_filter : '';
  if (params.device_filter !== '') {
    filters += '&device_filter=' + params.device_filter;
  }
  return filters;
};

export const getBasicDomainExportFiltersWithID = (params) => {
  let filters = params.id_domain + '?';
  filters += params.date_start_filter
    ? 'date_start_filter=' +
      encodeURIComponent(moment(params.date_start_filter).format())
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' +
      encodeURIComponent(moment(params.date_end_filter).format())
    : '';
  filters += params.node_name ? `&node_name=${params.node_name}` : '';
  filters += params.date_group ? '&date_group=' + params.date_group : '';
  return filters;
};

export const getBasicDomainNodesFilters = (params) => {
  let filters = params.id_domain + '?';
  filters += params.date_start_filter
    ? 'date_start_filter=' +
      encodeURIComponent(moment(params.date_start_filter).format())
    : '';
  filters += params.date_end_filter
    ? '&date_end_filter=' +
      encodeURIComponent(moment(params.date_end_filter).format())
    : '';
  filters += params.country_filter
    ? '&country_filter=' + params.country_filter
    : '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  return filters;
};
