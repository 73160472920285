import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#D01E2A',
    },
    color: '#FAFAFA',
    borderColor: theme.palette.secondaryAccent.light,
    padding: theme.spacing(0.5, 2),
    '& span': {
      fontSize: '12px',
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionGroup = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <ButtonGroup variant='contained' disableElevation aria-label='button group'>
      <Tooltip title={!props.disabled && props?.title ? props?.title : ''}>
        <Button
          startIcon={props.icon}
          ref={props.forwardedRef}
          className={[classes.button, extraClasses].join(' ')}
          onClick={(e) => props.onClickHandler?.(e)}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
        >
          <Typography variant='button'>{props.label}</Typography>
        </Button>
      </Tooltip>
      {props.secondButtonOn && (
        <Tooltip
          title={
            !props.disabled && props?.secondTitle ? props?.secondTitle : ''
          }
        >
          <Button
            startIcon={props.secondIcon}
            ref={props.secondForwardedRef}
            className={[classes.button, extraClasses].join(' ')}
            onClick={(e) => props.secondOnClickHandler?.(e)}
            disabled={props.secondDisabled}
            fullWidth={props.secondFullWidth}
          >
            <Typography variant='button' style={{ fontSize: props.fontSize2 }}>
              {props.secondLabel}
            </Typography>
            {props.secondEndIcon}
          </Button>
        </Tooltip>
      )}
    </ButtonGroup>
  );
};

export default CallToActionGroup;
