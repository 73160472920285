import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';
import { SECTOR_PAGES } from '../../../utils/Constants';

const DropDownSectors = ({ isMobile, menuSectorsOpen, closeMenu }) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();
  return (
    isMobile &&
    menuSectorsOpen &&
    Object.keys(SECTOR_PAGES).map((e) => (
      <MenuItem
        onClick={closeMenu}
        className={classes.mainSubMenuMobileItemWrapper}
        component={Link}
        to={`/sector/${e}`}
      >
        <Typography className={classes.mainSubMenuMobileItem}>
          {t(`backoffice.sectors.options.${e}`)}
        </Typography>
      </MenuItem>
    ))
  );
};

export default DropDownSectors;
