import { Button, MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import DropDownSectors from './DropDownSectors';
import SubLateralSectorsMenuNavbar from '../../SubLateralSectorsMenuNavbar.js';

const ButtonSectorMenu = ({
  isMobile,
  menuSectorsOpen,
  setMenuSectorsOpen,
  menuSectorsAnchorRef,
  isLargeWindow,
  setMenuCyclesOpen,
  setMenuDomainsOpen,
  closeMenu,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();

  const labelMenu = () => {
    return isLargeWindow
      ? t('accessibility.navbar.subMenu.sectors') +
          t('accessibility.navbar.subMenu.button') +
          t('accessibility.navbar.lateralMenu.tab')
      : t('accessibility.navbar.subMenu.sectors') +
          t('accessibility.navbar.subMenu.button');
  };
  return (
    <>
      <MenuItem
        onClick={() => {
          setMenuSectorsOpen(!menuSectorsOpen);
          setMenuCyclesOpen(false);
          setMenuDomainsOpen(false);
        }}
        className={classes.mainMenuItemWrapper}
        ref={menuSectorsAnchorRef}
        aria-expanded={menuSectorsOpen}
      >
        <Button className={classes.boxSector}>
          <Typography className={classes.mainMenuItem}>
            {t('navbar.links.main-menu.sectors.title')}
          </Typography>
          <span
            aria-label={labelMenu()}
            size='large'
            className={classes.mainMenuItemIcon}
          >
            {!isMobile ? (
              <KeyboardArrowRightIcon />
            ) : menuSectorsOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </span>
        </Button>
      </MenuItem>
      <SubLateralSectorsMenuNavbar
        menuSectorsOpen={menuSectorsOpen}
        isMobile={isMobile}
        menuSectorsAnchorRef={menuSectorsAnchorRef}
        setMenuSectorsOpen={setMenuSectorsOpen}
        closeMenu={closeMenu}
      />
      <DropDownSectors
        isMobile={isMobile}
        menuSectorsOpen={menuSectorsOpen}
        closeMenu={closeMenu}
      />
    </>
  );
};

export default ButtonSectorMenu;
