import { forwardRef } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondaryAccent.main,
    color: theme.palette.secondaryAccent.contrastText,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondaryAccent.light,
    },
    fontWeight: 'light',
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0.5),
    // minWidth: '200px',
    borderRadius: theme.artistActionButtons.borderRadius,
    textTransform: 'initial',
    fontSize: '1rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
    '&[disabled]': {
      backgroundColor: theme.palette.secondaryAccent.extraLight,
      color: `${theme.palette.secondaryAccent.contrastText} !important`,
    },
  },
}));

export const CallToActionSecondary = forwardRef((props, ref) => {
  const classes = useStyles();
  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={props.forwardedRef}
      className={classnames(classes.button, extraClasses)}
      onClick={(e) => props.onClickHandler?.(e)}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      onBlur={(e) => props.onBlur?.(e)}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
});
