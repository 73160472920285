import makeStyles from '@mui/styles/makeStyles';

export const useSlickCarouselStyles = makeStyles((theme) => ({
  container: {
    width: '100% !important',
    '& ul': { width: 'inherit' },
  },
  focusCarrousel: {
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
  },
  itemClass: {
    width: 'inherit!important',
    minWidth: 'fit-content',
    maxWidth: 'fit-content',
  },
  customArrowRight: {
    right: '-60px !important',
  },
  customArrowLeft: {
    left: '-60px !important',
  },
  arrowMargin: {
    top: '131px !important',
    height: '50px !important',
    width: '50px !important',
  },
  link: {
    textDecoration: 'none',
    height: '100%',
  },
  arrowMarginHome: {
    height: '50px !important',
    width: '50px !important',
  },
  arrowBoxLeftHome: {
    zIndex: '10',
    backgroundImage: 'linear-gradient(to right, #F2F2F2 40%, #0000)',
    width: '50px',
    position: 'absolute',
    top: '-2px',
    bottom: '-2px',
  },
  arrowBoxRightHome: {
    zIndex: '10',
    backgroundImage: 'linear-gradient(to left, #F2F2F2 40%, #0000)',
    width: '100px',
    position: 'absolute',
    right: 0,
    top: '-2px',
    bottom: '-2px',
  },
  arrowBoxLeft: {
    zIndex: '10',
    width: '50px',
    position: 'absolute',
    top: '-2px',
    bottom: '-2px',
    left: -50,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  arrowBoxRight: {
    zIndex: '10',
    width: '50px',
    position: 'absolute',
    right: -50,
    top: '-2px',
    bottom: '-2px',
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  customArrowRightHome: {
    // right: '-17px !important',
    right: '0 !important',
    width: '32px !important',
    height: '32px !important',
    backgroundColor: '#f2f2f2',
    [theme.breakpoints.down('lg')]: {
      right: '0 !important',
    },
  },
  customArrowLeftHome: {
    // left: '-17px !important',
    left: '0 !important',
    width: '32px !important',
    height: '32px !important',
    backgroundColor: '#f2f2f2',
    [theme.breakpoints.down('lg')]: {
      left: '0 !important',
    },
  },
}));
