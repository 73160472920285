import moment from 'moment/min/moment-with-locales';
import {
  RoundsParticipantStatus,
  RoundsStatus,
  SHOW_EXTERNAL_LINK,
  SHOW_STATUS,
  TYPE_DATE_TO_SHOW,
  EVENT_TYPE_MEETING,
  RoundsLocation,
  DATE_FORMAT,
} from '../../../utils/Constants';
import RedSpan from '../../Elements/RedSpan';
import { OpenPublishedButton } from '../Buttons/BOCommonButtons';
import {
  capitalizeText,
  formatDate,
  formatReadableDate,
} from '../../../utils/formHelper';
import { convertDateToLocalTime } from '../../../utils/formHelper';

export const hasntEnded = (date) => {
  const localDate = convertDateToLocalTime(date);
  return moment(localDate).isSameOrAfter(moment(), 'day');
};

export const isEventActive = (row) => {
  return row.status === RoundsStatus.ACTIVE;
};

export const isBusinessRound = (row) => {
  return row.type_meeting === EVENT_TYPE_MEETING.BUSINESS_ROUND;
};

export const isEventInNotFinalState = (row) => {
  return (
    row.status === RoundsStatus.CANCELLED || row.status === RoundsStatus.ACTIVE
  );
};

export const isEventCancelled = (row) => {
  return row.status === RoundsStatus.CANCELLED;
};

export const showActionMenu = (row) => {
  return isEventInNotFinalState(row);
};

export const shouldDisableDelete = (row) => {
  return row.status === RoundsStatus.ACTIVE && hasInvitedParticipants(row);
};

export const shouldShowEditAction = (row) => {
  return isEventActive(row);
};

export const shouldShowFinishAction = (row) => {
  return row.status === RoundsStatus.ACTIVE && !hasntEnded(row.date_end);
};

export const shouldShowCancelAction = (row) => {
  return !isEventCancelled(row) && hasntEnded(row.date_end);
};

export const shouldShowDeleteAction = (row) => {
  return (
    row.status !== RoundsStatus.DELETED && row.status !== RoundsStatus.DONE
  );
};

export const shouldShowPublicEventLinkAction = (row) => {
  return row.publish === true && row?.communication === SHOW_EXTERNAL_LINK.HAS;
};

export const hasInvitedParticipants = (row) => {
  const participantsFound = row.participants.filter(
    (participant) => participant.status !== RoundsParticipantStatus.NOT_INVITED
  );
  return participantsFound.length > 0;
};

export const showStatus = (status, key) => {
  return status ? `${key}table.status.options.${status}` : '';
};

export const showsStatus = (status, key, t, isRed) => {
  if (status) {
    const statusText = t(`${key}status.options.${status}`);
    return isRed ? <RedSpan>{statusText}</RedSpan> : statusText;
  }
};

export const isShowStatusRelevant = (status) => {
  return status !== SHOW_STATUS.PAST && status !== SHOW_STATUS.NOT_AVAILABLE;
};

export const isShowStatusAvailable = (status) => {
  return status !== SHOW_STATUS.NOT_AVAILABLE;
};

export const showVisibility = (row, key, t, goToShow) => {
  return row?.visible ? (
    <RedSpan>
      {t(`${key}visibility.options.visible`)}
      {/* se manda el id_show al detalle y este se encarga de hacer la llamada */}
      {isShowStatusAvailable(row.status) && (
        <OpenPublishedButton onClickHandler={() => goToShow(row)} />
      )}
    </RedSpan>
  ) : (
    <b>{t(`${key}visibility.options.hidden`)}</b>
  );
};

export const getNoveltyDateShowText = (date, type, lng, t, showEmpty) => {
  moment.locale(lng);
  switch (type) {
    case TYPE_DATE_TO_SHOW.MONTH:
      return capitalizeText(
        formatDate(date, DATE_FORMAT.READABLE_MONTH_WITH_YEAR, lng)
      );
    case TYPE_DATE_TO_SHOW.DATE:
      return formatReadableDate(date, lng);
    case TYPE_DATE_TO_SHOW.EMPTY:
    default:
      return showEmpty ? t('backoffice.novelties.table.no-date') : '';
  }
};

export const getNoveltyDateShowTextCard = (
  activity,
  type,
  lng,
  t,
  showEmpty
) => {
  if (type === TYPE_DATE_TO_SHOW.EMPTY) {
    return t(`agenda.activities.footerCardDate.available`);
  }
  return getNoveltyDateShowText(
    activity?.date_displayed,
    type,
    lng,
    t,
    showEmpty
  );
};

export const buildTurnActionParams = (action, turns) => {
  return {
    turn_schedule: turns,
    turn_action: action,
  };
};

export const deleteScheduleProps = (array) => {
  array.map((elem) => {
    delete elem?.schedule_end;
    delete elem?.schedule_start;
    return null;
  });
};

export const isEventLocationVirtual = (event) => {
  return event?.location === RoundsLocation.VIRTUAL;
};

export const getChatUserName = (user) => {
  return user.user_data
    ? user.user_data.first_name + ' ' + user.user_data.last_name
    : '';
};

export const shouldSurveyDeleteAction = (row) => {
  return row.deleted !== false;
};
