import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { buildInitials, onKeyDownEscape } from '../utils/formHelper';
import { Feature } from '../context/FeaturesContext';
import { LogOut } from './NavbarLogout';
import { ProfileActionsMyPortfolio } from '../components/ProfileActions/ProfileActionsMyPortfolio';
import { useLoginContext } from '../context/LoginContext';
import AuthService from '../services/AuthService';
import MessageIcon from '../components/Navbar/MessageIcon';
import NotificationIcon from '../components/Navbar/NotificationIcon';
import { NavbarSearchIcon } from './NavbarSearchIcon';

export const NavbarGreeting = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { loginData, setLoginData } = useLoginContext();
  const anchorRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    isLoggedIn,
    classes,
    isLargeWindow,
    publishedPortfolio,
    setMenuOpen,
    hasPublishedPortfolio,
    userData,
    setSearchOpen,
    isResultPageUrl,
    searchOpen,
    unread,
    checkUnread,
  } = props;

  const onLogIn = () => {
    setLoginData({ ...loginData, isOpen: true });
  };

  const logInCloseMenu = () => {
    setMenuOpen(false);
    onLogIn();
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const { first_name, last_name } = AuthService.getUserInfo();

  const avatarStyles = makeStyles((theme) => ({
    orange: {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  const classesAvatar = avatarStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const onClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const profilePicture =
    publishedPortfolio && publishedPortfolio.image
      ? publishedPortfolio.image?.versions[0].url
      : '';

  const profileAltText =
    publishedPortfolio && publishedPortfolio.image
      ? publishedPortfolio.image?.alt_text
      : '';

  if (isLoggedIn) {
    return (
      <Box
        onKeyDown={(e) => onKeyDownEscape(e, setOpen)}
        className={classes.loggedInWrapper}
      >
        {!isMobile && !hasPublishedPortfolio && (
          <MenuItem className={classes.menuLogIn} disableRipple>
            <Button
              className={classes.addProfileButton}
              component={Link}
              disableElevation
              to={'/tu-mica'}
              variant='contained'
            >
              {t('navbar.links.portfolio.new')}
            </Button>
          </MenuItem>
        )}
        <MenuItem className={classes.searchIconWrapper}>
          <NavbarSearchIcon
            setSearchOpen={setSearchOpen}
            searchOpen={searchOpen}
            isResultPage={isResultPageUrl}
          />
        </MenuItem>
        <MenuItem role='none' className={classes.menuItems}>
          <MessageIcon
            onClick={handleClose}
            isLargeWindow={isLargeWindow}
            unread={unread}
            checkUnread={checkUnread}
          />
        </MenuItem>
        <MenuItem role='none' className={classes.menuItems}>
          <NotificationIcon
            onClick={handleClose}
            isLargeWindow={isLargeWindow}
            unread={unread}
            checkUnread={checkUnread}
          />
        </MenuItem>
        <>
          {!hasPublishedPortfolio && (
            <MenuItem
              ref={anchorRef}
              onClick={() => handleToggle()}
              className={props.classes.avatar}
              role='none'
            >
              <Button>
                {profilePicture ? (
                  <Avatar alt={profileAltText} src={profilePicture} />
                ) : (
                  <Avatar className={classesAvatar.orange}>
                    {buildInitials(first_name, last_name)}
                  </Avatar>
                )}
              </Button>
            </MenuItem>
          )}

          {hasPublishedPortfolio && (
            <MenuItem
              disableRipple
              ref={anchorRef}
              onClick={handleToggle}
              role='none'
              style={{ margin: 0, padding: '0px 0px 0px 5px' }}
            >
              <ProfileActionsMyPortfolio
                publishedPortfolio={publishedPortfolio}
                hasPublishedPortfolio={hasPublishedPortfolio}
                isMobile={isMobile}
              />
            </MenuItem>
          )}
          <Popper
            id='userMenuTooltip'
            open={open}
            anchorEl={anchorRef.current}
            disablePortal
          >
            {({ TransitionProps }) => (
              <Collapse in={open} {...TransitionProps}>
                <ClickAwayListener onClickAway={onClickAway}>
                  <Paper elevation={0}>
                    <MenuList
                      // autoFocusItem={open}
                      className={classes.expandableYourMicaMenu}
                    >
                      <MenuItem
                        disableRipple
                        className={classes.yourMicaMenuTopItemWrapper}
                        role='none'
                        tabIndex={-1}
                      >
                        <Box
                          className={classes.yourMicaMenuTopInnerItemWrapper}
                        >
                          <Box>
                            <ProfileActionsMyPortfolio
                              publishedPortfolio={publishedPortfolio}
                              hasPublishedPortfolio={false}
                              isMobile={isMobile}
                              showYourMicaLink={false}
                            />
                          </Box>
                          <Box>
                            <Typography
                              className={classes.yourMicaMenuMainItem}
                            >
                              {userData.first_name + ' ' + userData.last_name}
                            </Typography>
                            <Typography className={classes.yourMicaUsername}>
                              {userData.username}
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>

                      {isMobile && !hasPublishedPortfolio && (
                        <Link
                          onClick={() => setOpen(false)}
                          style={{ textDecoration: 'none' }}
                          to={'/tu-mica'}
                        >
                          <Box className={classes.addProfileLink}>
                            {t('navbar.links.portfolio.newMobile')}
                          </Box>
                        </Link>
                      )}
                      <Divider className={classes.yourMicaMenuDivider} />

                      {hasPublishedPortfolio && (
                        <MenuItem className={classes.yourMicaMenuItemWrapper}>
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={'/tu-mica'}
                            onClick={handleClose}
                          >
                            <Typography
                              className={classes.yourMicaMenuBoldItem}
                            >
                              {t('navbar.links.your-mica-menu.your-mica')}
                            </Typography>
                          </Link>
                        </MenuItem>
                      )}
                      <MenuItem className={classes.yourMicaMenuItemWrapper}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={'/tu-mica/rondas/agenda'}
                          onClick={handleClose}
                        >
                          <Typography className={classes.yourMicaMenuBoldItem}>
                            {t('navbar.links.your-mica-menu.agenda')}
                          </Typography>
                        </Link>
                      </MenuItem>

                      <Feature name='BOOKMARKS'>
                        <MenuItem className={classes.yourMicaMenuItemWrapper}>
                          <Link
                            to={'/favoritos'}
                            onClick={handleClose}
                            style={{ textDecoration: 'none' }}
                          >
                            <Typography
                              className={classes.yourMicaMenuBoldItem}
                            >
                              {t('navbar.links.your-mica-menu.favorites')}
                            </Typography>
                          </Link>
                        </MenuItem>
                      </Feature>

                      {hasPublishedPortfolio && (
                        <MenuItem className={classes.yourMicaMenuItemWrapper}>
                          <Link
                            to={'/tu-mica/rondas/perfil/negocio'}
                            onClick={handleClose}
                            style={{ textDecoration: 'none' }}
                          >
                            <Typography
                              className={classes.yourMicaMenuBoldItem}
                            >
                              {t(
                                'navbar.links.your-mica-menu.business-profile'
                              )}
                            </Typography>
                          </Link>
                        </MenuItem>
                      )}

                      <MenuItem className={classes.yourMicaMenuItemWrapper}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to={'/mi-cuenta'}
                          onClick={handleClose}
                        >
                          <Typography className={classes.yourMicaMenuBoldItem}>
                            {t('navbar.links.account')}
                          </Typography>
                        </Link>
                      </MenuItem>

                      <Divider className={classes.yourMicaMenuDivider} />
                      <LogOut classes={classes} />
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Collapse>
            )}
          </Popper>
        </>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box className={classes.loginWrapper}>
          <MenuItem className={classes.searchIconWrapper}>
            <NavbarSearchIcon
              setSearchOpen={setSearchOpen}
              searchOpen={searchOpen}
              isResultPage={isResultPageUrl}
            />
          </MenuItem>

          <MenuItem className={classes.menuLogIn}>
            <Button
              className={classnames(classes.menuButton, classes.login)}
              disableElevation
              onClick={logInCloseMenu}
              variant='contained'
            >
              {t('navbar.links.login')}
            </Button>
          </MenuItem>
          {!isMobile && (
            <MenuItem className={classes.menuLogIn}>
              <Button
                className={classnames(classes.menuButton, classes.register)}
                component={Link}
                disableElevation
                to={'/registro'}
                variant='contained'
              >
                {t('navbar.links.register')}
              </Button>
            </MenuItem>
          )}
        </Box>
      </Box>
    );
  }
};
