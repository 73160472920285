import makeStyles from '@mui/styles/makeStyles';

export const useFooterStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.secondaryAccent.main,
    display: 'flex',
    height: 350,
    [theme.breakpoints.up('sm')]: {
      height: 172,
    },
  },
  footerWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: theme.spacing(0, 'auto', 2.25),
    padding: theme.spacing(0, 12.5, 0, 10.5),
    width: 1480,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 6.25, 0, 4.25),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(-4, 'auto', 2.25),
      padding: theme.spacing(0),
    },
  },
  textContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: 200,
      margin: 'auto',
    },
  },
  textContainerInside: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    height: 70,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  micaLogo: {
    display: 'flex',
    gap: 8,
    padding: theme.spacing(2),
    '& img': {
      marginBottom: theme.spacing(0.25),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '45px',
    },
  },
  micaLogoNoPadding: {
    display: 'flex',
    gap: 8,
    padding: theme.spacing(2),
    '& img': {
      marginBottom: theme.spacing(0.25),
    },
  },
  micaTextWrapper: {
    width: 101,
  },
  micaText: {
    color: '#FFF',
    fontSize: 9,
    lineHeight: '12px',
    textTransform: 'none',
  },
  socialMediaContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1.125),
  },
  menuIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.125),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.375),
    },
  },
  menuIcon: {
    color: 'white',
    padding: theme.spacing(2),
  },
  footerLinkContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1.25),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  footerLinks: {
    color: theme.palette.primary.white,
    padding: 0,
    transition: [theme.transitions.create('color')],
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:focus-visible': {
      outlineOffset: '1px',
    },
    '& span': {
      fontSize: 11,
      lineHeight: '13px',
    },
  },
  footerLinkItem: {
    cursor: 'pointer',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  logoMinisterio: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
}));
