import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ScrollTo } from '../utils/Constants';
import { getRouteHash } from '../utils/utils';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen((currentHistory) => {
      const isSearch = sessionStorage.getItem(ScrollTo.IS_SEARCH) === 'true';
      const hash = getRouteHash();
      const isTab = currentHistory?.pathname?.includes('/tab/');
      if (!isSearch && !hash && !isTab) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);
