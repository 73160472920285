import { useEffect } from 'react';
import AuthService from '../../services/AuthService';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/UserService';

export default function Logout() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    UserService.cacheUserDelete()
      .then(({ data }) => {
        if (data?.detail !== 'ok') return false;
        AuthService.logout();
        AuthService.getAndSaveAnonymousToken().then(() => {
          dispatch(setSnackbar(true, 'success', t('logout.messages.success')));
          history.push('/');
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch(setSnackbar(true, 'error', t('logout.messages.error')));
      });
  };

  return null;
}
