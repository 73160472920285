import { forwardRef } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.light,
    },
    fontWeight: 'light',
    padding: theme.spacing(0.5, 3),
    borderRadius: theme.artistActionButtons.borderRadius20,
    fontSize: '1rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
    '&[disabled]': {
      backgroundColor: theme.palette.secondary.light,
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionPrimaryBO = forwardRef((props, ref) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={ref}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
      disabled={props.disabled}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
});
