import { alpha, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import tuMICABackgroundRed from './images/tu-mica-background-red.png'; // Tell webpack this JS file uses this image
import { BRAND_ACCENT } from './theme';
import { Z_INDEX } from './utils/Constants';

export const GreenSwitch = withStyles({
  switchBase: {
    color: '#6a6a6a',
    '&$checked': {
      color: '#84B903',
    },
    '&$checked + $track': {
      backgroundColor: '#84B903',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const TextFieldsCommonPropStyles = (
  classes,
  { root, disabled, containerRoot, labelRoot } = {
    root: [],
    disabled: [],
    containerRoot: [],
  }
) => {
  return {
    classes: {
      root: [classes.textField, ...(containerRoot ?? [])].join(' '),
    },
    InputLabelProps: {
      shrink: true,
      classes: {
        root: [classes.labelField, ...(labelRoot ?? [])].join(' '),
        focused: classes.labelFieldFocused,
        error: classes.labelFieldErrored,
      },
    },
    InputProps: {
      classes: {
        root: [classes.formControl, ...(root ?? [])].join(' '),
        focused: classes.formControlFocused,
        error: classes.formControlErrored,
        disabled: [classes.formControlDisabled, ...(disabled ?? [])].join(' '),
      },
      disableUnderline: true,
    },
  };
};

export const StaticCommonStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(6, 0),
  },
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(6),
  },
  sectionNameIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    maxWidth: '15px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  downloadIcon: {
    marginRight: theme.spacing(1),
    color: '#000',
    maxWidth: '15px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  question: {
    padding: theme.spacing(2, 4),
    '& h6': {
      padding: theme.spacing(2, 0),
      fontWeight: 'bold',
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  socialMediaContainer: {
    padding: theme.spacing(2, 0),
    '& img': {
      marginRight: theme.spacing(2),
    },
  },
  downloadButton: {
    padding: theme.spacing(1, 2),
    border: `1px solid black`,
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: 'lightgray',
      border: `1px solid black`,
    },
  },
  downloadButtonsContainer: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  pageTitle: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('lg')]: {
      padding: '0 24px',
    },
  },
  accordionRoot: {
    backgroundColor: 'transparent',
    '&:before': {
      content: 'none',
    },
  },
  accordionSummaryRoot: {
    flexDirection: 'row-reverse',
    padding: 0,
  },
  accordionSummaryRootExpanded: {
    '& p': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  formActions: {
    margin: theme.spacing(4, 'auto'),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& button': {
      marginTop: theme.spacing(1),
    },
  },
  sector: {
    fontWeight: '600',
    fontSize: '1.1rem',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5),
    },
  },
  sectorLink: { color: 'black', textDecoration: 'none' },
  iconArrow: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  },
  contentColumns: {
    padding: theme.spacing(0, 1, 6, 0),
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      padding: theme.spacing(0, 1, 6, 10),
    },
  },
  contentSectorItems: {
    columns: '4 auto',

    [theme.breakpoints.down('sm')]: {
      columns: '2 auto',
    },
  },
  titleAboutMica: {
    fontWeight: 'bold',
    padding: theme.spacing(5, 4, 3, 0),
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      padding: theme.spacing(5, 4, 0, 0),
    },
  },
  aboutMicaText: {
    padding: theme.spacing(2, 4, 2, 2),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(0),
    },
  },

  linkAboutMica: { color: BRAND_ACCENT.main },

  titleContentSectors: {
    color: BRAND_ACCENT.main,
    fontWeight: 'bold',
    padding: theme.spacing(5, 4, 3, 0),
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      padding: theme.spacing(5, 4, 0, 0),
    },
  },
  footerContent: {
    maxWidth: theme.breakpoints.values['xl'],
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0),
    },
  },
  footer: {
    padding: theme.spacing(0),

    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(7, 2),
    },
  },
  aboutMicaCointainer: {
    maxWidth: '100%',
  },

  sectorContainer: {
    maxWidth: '100%',
    padding: theme.spacing(0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(7, 0),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

export const PortfolioFormsCommonStyles = makeStyles((theme) => ({
  header: {
    minWidth: '100%',
    backgroundImage: `url(${tuMICABackgroundRed})`,
    backgroundColor: theme.palette.secondary.light,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(6, 0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 0),
    },
  },
  headerContainer: {
    marginLeft: '0',
  },
  title: {
    padding: theme.spacing(1, 2),
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    borderRadius: '10px',
    marginRight: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    '& svg': {
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  questionsContainer: {
    margin: theme.spacing(8, 'auto'),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(4, 'auto'),
    },
  },
  questionHelpContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1, 0, 1, 2),
  },
  questionHelp: {
    fontSize: '.7rem',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.8,
  },
  checkboxLimitedSize: {
    maxHeight: '200px',
    overflow: 'auto',
    padding: theme.spacing(1),
    border: theme.shape.border,
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
  },
  privateData: {
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(2),
    '& .MuiInput-formControl': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  formActions: {
    margin: theme.spacing(4, 'auto'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& button + button': {
      marginTop: theme.spacing(4),
    },
  },
  warningContainer: {
    margin: theme.spacing(6, 'auto'),
    minWidth: 'fit-content',
  },
  sectionContent: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
  },
  sectionContentTitle: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  sectionContentSubtitle: {
    fontSize: 14,
    color: theme.palette.secondaryAccent.light,
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  sectionContentContents: {
    paddingTop: theme.spacing(4),
  },
  sectionContentAggregator: {
    paddingTop: theme.spacing(6),
  },
  sectionContentAddButton: {
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    backgroundColor: 'black',
    '& span': {
      fontSize: '.75rem',
    },
  },
  sectionContentAggregatorContainer: {
    marginTop: theme.spacing(4),
  },
  inputPrice: {
    width: 'auto',
  },
  stepIcon: {
    height: '3rem',
    width: '3rem',
    boxSizing: 'border-box',
    //border: `2px solid ${theme.palette.notification.border}`,
    borderRadius: '50%',
    color: theme.palette.notification.border,
    '& text': {
      fill: theme.palette.notification.date,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
      },
    },
    '& circle': {
      color: theme.palette.notification.border,
    },
  },
  stepLabel: {
    '& span.MuiStepLabel-label': {
      color: theme.palette.notification.date,
      fontWeight: 'bold',
    },
    '& span.MuiStepLabel-label.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      padding: '0 !important',
    },
  },
  stepIconActive: {
    border: `2px solid ${theme.palette.primary.main}`,
    '& text': {
      fill: theme.palette.primary.main,
    },
    '& circle': {
      color: 'transparent',
    },
  },
  stepIconCompleted: {
    backgroundColor: theme.palette.primary.white,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  stepperConnector: {
    top: 'calc(50% - 20px)',
    left: 'calc(-50% + 24px)',
    right: 'calc(50% + 22px)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  stepperConnectorLine: {
    border: `2px solid ${theme.palette.notification.border}`,
    backgroundColor: theme.palette.notification.border,
  },
  stepperConnectorLineRed: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  },
  stepperContainer: {
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  stepperPaper: {
    marginBottom: '3px',
  },
  stepperButton: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0, 1),
      '& span': {
        fontSize: '0.6rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '0.5rem',
      },
    },
  },
  cancelButton: {
    alignItems: 'flex-start',
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 'auto', 0, 'auto'),
  },
}));

export const MaterializeCommonStyles = makeStyles((theme) => ({
  formActions: {
    margin: theme.spacing(4, 'auto'),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& button': {
      marginTop: theme.spacing(1),
    },
  },

  colorsTitle: {
    fontSize: 14,
    fontWeight: '700',
    marginBottom: '4px',
    paddingRight: '4px',
  },
  containerStepper: {
    display: 'flex',
    borderBottom: '1px solid #DBDBDB',
    paddingBottom: '10px',
  },
  iconWarning: { color: theme.palette.warning.dark, paddingRight: '8px' },
  hiddenPortfolioMenuBox: {
    height: '60px',
    backgroundColor: theme.palette.success.menuPortfolio,
    display: 'flex',
    alignItems: 'center',
  },
  hiddenFormMenuBox: {
    height: '60px',
    backgroundColor: theme.palette.success.menuPortfolio,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.75),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  notRegistration: { fontWeight: 600 },
  containerBar: {
    width: '610px',
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
  },
  fontStyle: {
    fontSize: '16px',
    lineHeight: '19.5px',
  },
  sectors: {
    maxWidth: '220px',
    minWidth: '150px',
    fontSize: '16px',
    lineHeight: '19.5px',
  },
  roundsWithTurns: {
    fontWeight: 700,
    fontSize: '14px',
  },
  gridIcon: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  linkHiddenPortfolio: {
    color: theme.palette.dark.main,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
  listUserAffected: {
    width: '100%',
    minWidth: 330,
    height: 150,
    position: 'relative',
    overflow: 'auto',
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
  },
  textField: {
    borderBottomColor: 'rgba(0, 0, 0, 0.87)',
    '& label': {
      color: 'rgba(0, 0, 0, 0.42)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInput-underline:not(.Mui-error)::after': {
      // borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiSelect-underline:after': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  CheckboxSlim: {
    padding: theme.spacing(0.5),
  },
  iconDark: {
    backgroundColor: 'black !important',
    width: 'fit-content',
    height: 'fit-content',
    color: 'white !important',
    '&:hover': {
      color: 'white',
    },
  },
  buttonBase: {
    textAlign: 'start',
    fontSize: '1rem',
    textDecoration: 'underline',
    fontWeight: '550',
    fontFamily: 'Montserrat,Roboto,sans-serif',
    whiteSpace: 'nowrap',
  },
  gridButtonBase: { alignSelf: 'center' },
  gridDescription: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '3vw',
      alignSelf: 'center',
    },
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },

  formText: {
    fontSize: 14,
    color: theme.palette.secondaryAccent.light,
  },
  formTextResend: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.secondaryAccent.light,
  },

  resendLink: {
    textDecoration: 'none',
    color: '#1C6BFB',
    fontWeight: '600',
  },
  warning4: { paddingTop: '20px' },
  noDecorationLink: {
    display: 'block',
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  stepIcon: {
    height: '3rem',
    width: '3rem',
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    color: theme.palette.primary.main,
    '& text': {
      fill: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    '& circle': {
      color: 'transparent',
    },
  },
  stepIconActive: {
    backgroundColor: theme.palette.primary.main,
    '& text': {
      fill: theme.palette.primary.white,
    },
    '& circle': {
      color: theme.palette.primary.main,
    },
  },
  stepIconCompleted: {
    backgroundColor: theme.palette.primary.white,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  formContainer: {
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.primary.white,
  },
  messageContainer: {
    marginTop: '5%',
    marginBottom: '5%',
    width: '75%',
    backgroundColor: theme.palette.primary.white,
  },
  boFormContainer: {
    margin: theme.spacing(2, 0),
    width: '660px',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 0),
    },
  },
  boFormContainerFluid: {
    margin: theme.spacing(2, 0),
    width: '748px',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 0),
    },
  },
  form: {
    width: '100%',
    zIndex: Z_INDEX.MINIMUM,
    backgroundColor: 'white',
    paddingTop: theme.spacing(2),
    '& button[aria-disabled=true]': {
      opacity: '.5',
      pointerEvents: 'none',
    },
  },

  formControl: {
    width: '100%',
    border: theme.shape.border,
    fontSize: 14,
    padding: `${theme.spacing(1, 2)} !important`,
    borderRadius: theme.shape.borderRadius,
    '&::before': {
      border: 'none',
    },
    transition: theme.transitions.create(['border-color']),
    backgroundColor: theme.palette.primary.white,
  },
  formControlFocused: {
    borderColor: '#53ACCC',
  },
  formControlErrored: {
    borderColor: theme.palette.danger.main,
  },
  formControlDisabled: {
    backgroundColor: theme.palette.primary.white,
  },
  formControlSelect: {
    backgroundColor: theme.palette.info.light,
  },
  formControlSelectTransparent: {
    backgroundColor: 'transparent',
  },
  formControlSelectDisabled: {
    backgroundColor: '#E2E2E2',
  },
  labelField: {
    color: `${theme.palette.secondaryAccent.light} !important`,
    transition: theme.transitions.create(['color']),
  },
  labelFieldFocused: {
    color: `${theme.palette.secondaryAccent.light} !important`,
  },
  labelFieldErrored: {
    color: `${theme.palette.danger.main} !important`,
  },
  formAdditionalLinks: {
    color: '#414EDB',
    fontSize: '.75rem',
    transition: theme.transitions.create(['color']),
    '&:hover': {
      color: '#414EDB',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      textAlign: 'center',
      display: 'inline-block',
    },
  },
  formLinkLabel: {
    fontSize: '.75rem',
    color: theme.palette.secondaryAccent.dark,
  },
  label: {
    fontSize: 14,
    color: alpha(theme.palette.secondaryAccent.light, 0.8),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  submit: {
    textTransform: 'capitalize',
    display: 'block',
    margin: theme.spacing(2, 'auto'),
    border: '1px solid currentColor',
  },
  secondarySubmit: {
    backgroundColor: theme.palette.secondaryAccent.main,
    color: theme.palette.secondaryAccent.contrastText,
    textTransform: 'capitalize',
    display: 'block',
    margin: theme.spacing(2, 'auto'),
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    overflow: 'auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalContainer: {
    width: '50%',
    height: '50%',
  },
  modalButtons: {
    display: 'flex',
    // justifyContent: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '10px',
    '& button': {
      minWidth: '90px',
      margin: '5px 20px 15px 15px',
    },
  },
  modalButtonsLoading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  dialogTitle: {
    color: '#262626',
    fontWeight: '600',
    justifyContent: 'space-between',
    whiteSpace: 'pre-line',
    '& h2': {
      fontWeight: '600',
      fontSize: '20px',
      margin: 0,
    },
  },
  rootContentContainer: {
    backgroundColor: '#FAFAFA',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rootContent: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  answerHeader: {
    fontSize: 14,
  },
  questionHeader: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  questionHeaderSubtitle: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(4),
  },
  subtitleNovely: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(0.5),
    float: 'left',
    width: '100%',
  },
  detailSwitch: {
    fontSize: '12px',
    padding: theme.spacing(0, 2, 0.5, 2),
  },
  contentQuestion: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: '20px',
  },
  containerHighlighted: {
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(2, 4, 2),
  },
  containerNoteSchedule: {
    padding: theme.spacing(0.5, 0, 0, 0),
  },
  checkboxItems: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxHeaderLight: {
    color: 'white',
    padding: 0,
  },
  checkboxLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  },
  stepper: {
    width: '100%',
    '& .MuiStepConnector-alternativeLabel': {
      top: '50%',
    },
    '& .MuiStepConnector-lineHorizontal': {
      margin: '0 15px',
      borderColor: theme.palette.primary.main,
    },
  },
  qaContainer: {
    margin: 0,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  boContainer: {
    margin: '15px auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    '& .MuiTableCell-head': {
      fontWeight: '600',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      verticalAlign: 'top',
    },
    '& .MuiTableRow-footer': {
      backgroundColor: theme.palette.primary.dark,
      justifyContent: 'center',
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiTablePagination-toolbar': {
        justifyContent: 'center',
        padding: 0,
        '& .MuiTablePagination-caption': {
          paddingLeft: '12px',
        },
      },
    },
  },
  titleContainer: {
    marginLeft: 0,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statisticsTitleContainer: {
    marginLeft: 0,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  useSiteTitle: {
    fontSize: '36px',
    fontWeight: 600,
    paddingTop: theme.spacing(6),
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
    paddingBottom: theme.spacing(1),
    width: '800px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  dateFilter: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(7),
    width: '100%',
    marginLeft: '0',
    [theme.breakpoints.down('lg')]: {
      paddingTop: theme.spacing(0),
    },
  },
  statisticsDateFilter: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: theme.spacing(7),
    width: '100%',
    minWidth: 340,
    marginLeft: '0',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  },

  accountsTitleContainer: {
    marginLeft: 0,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
  },
  viewsTitleContainer: {
    marginLeft: 0,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
  },
  accountsContainer: {
    marginLeft: 0,
    marginRight: '25px',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },

  containerNovelty: {
    padding: theme.spacing(0, 4),
    margin: '15px auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0),
    },
  },
  boBoxWhite: {
    padding: theme.spacing(1, 3.5, 1, 1),
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.25)',
    '& label': {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  boSliderBoxWhite: {
    padding: theme.spacing(3, 4),
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: 'white',
    borderRadius: theme.spacing(0.75),
  },
  boBreadcrumbs: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  boBoldEntity: {
    fontWeight: '700',
  },
  qaGroup: {
    marginTop: theme.spacing(2),
    '&:nth-of-type(1)': {
      marginTop: 0,
    },
    '& .MuiGrid-item': {
      alignContent: 'baseline',
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.text,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    '& svg': {
      marginRight: theme.spacing(2),
    },
    '& p': {
      fontSize: 14,
    },
  },

  loginWarning: {
    backgroundColor: theme.palette.warning.login,
    color: theme.palette.warning.text,
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    '& svg': {
      marginRight: theme.spacing(1),
      marginLeft: '-10px',
    },
    '& p': {
      fontSize: 14,
    },
  },
  info: {
    backgroundColor: theme.palette.info.light,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    '& svg': {
      fill: theme.palette.info.dark,
      marginRight: theme.spacing(2),
    },
    '& p': {
      fontSize: 14,
    },
  },
  expandableMenu: {
    backgroundColor: theme.palette.secondaryAccent.light,
    borderRadius: 0,
    padding: 0,
    '& li': {
      borderBottom: `1px solid ${theme.palette.secondaryAccent.dark}`,
      padding: 0,
      '&:last-child': {
        borderBottom: 'none',
      },
      '& a, & p': {
        color: theme.palette.primary.white,
        padding: theme.spacing(1),
        fontSize: 14,
        textAlign: 'center',
        display: 'block',
        width: '100%',
        transition: theme.transitions.create('background-color'),
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: theme.palette.secondaryAccent.main,
        },
      },
    },
  },
  contentFormContainer: {
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
  contentFormHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  contentFormRoot: {
    borderRadius: 0,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0.5),
    },
  },
  dialogBody: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  dialogAction: {
    padding: theme.spacing(0, 0, 4, 0),
  },
  dialogDisclaimer: {
    padding: theme.spacing(0, 4, 4, 2),
  },
  contentFormTitle: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    flexGrow: 1,
  },
  contentFormActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentFormAction: {
    width: '40%',
    justifyContent: 'center',
    // '&:nth-of-type(1)': {
    //   marginBottom: theme.spacing(2),
    // },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  questionAlert: {
    color: theme.palette.forms.blue,
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  contentFormFileAction: {
    height: '100%',
    width: '100%',
  },
  stepperConnector: {
    border: `1px solid ${theme.palette.primary.main}`,
    width: '80px',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: '-40px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  requiredDisclaimer: {
    padding: theme.spacing(1),
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
    fontSize: '.75rem',
    display: 'inline-block',
  },
  fileInputHidden: {
    display: 'none !important',
  },
  catalogNewAction: {
    display: 'block',
    margin: '8px auto !important',
  },
  timeDialog: {
    backgroundColor: '#80d8ff',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  playerCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1,
  },
  marginTopCustom: {
    marginTop: '10px',
  },
  buttonTop: {
    paddingTop: theme.spacing(2),
  },
  boTitle: {
    padding: '25px 0 30px 0',
    fontWeight: '700',
    display: 'flex',
  },
  boRowContainer: {
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(4),
    },
  },
  estadoCustomStyles: {
    border: 'none',
  },
  boIconButtons: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
  },
  btnValidation: {
    margin: 'auto !important',
  },
  textValidate: {
    margin: '0',
  },
  historyContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  iconHistory: {
    marginRight: theme.spacing(0.5),
  },
  warningList: {
    width: 'calc(100% - 40px)',
    textAlign: 'left',
  },
  goBack: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondaryAccent.light,
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    textDecoration: 'none',
    transition: theme.transitions.create(['color'], { duration: '.3s' }),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.dark,
      textDecoration: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  rolesCheckboxForm: {
    marginBottom: '5px',
    alignSelf: 'flex-start',
    width: '100%',
    '& label': {
      display: 'block',
    },
  },
  rolesCheckboxContainer: {
    columns: '3 auto',
    '&:nth-child(1) > *:nth-child(7)': {
      paddingBottom: 0.05,
    },
    [theme.breakpoints.down('lg')]: {
      columns: '2 auto',
      '&:nth-child(1) > *:nth-child(7)': {
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      columns: '1 auto',
    },
    '& span': {
      fontSize: '14px',
      padding: '0px 1px 2px 5px',
    },
  },
  maxContent: {
    width: 'max-content',
  },
  flex: {
    display: 'flex',
  },
  flexSpaceBetWeen: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  goBackAbsolute: {
    position: 'absolute',
    // paddingLeft: '30px',
    left: 0,
  },
  virtualTableContainer: {
    //height: '700px',
    background: 'white',
    marginBottom: theme.spacing(2),
    //overflowX: 'scroll',
  },
  virtualTableHeaderBlack: {
    '& div': { color: 'white' },
    backgroundColor: 'black',
  },
  dividerStyle: { backgroundColor: 'black' },
  divider: {
    border: '1px solid #262626',
    marginBottom: theme.spacing(1.5),
  },
  textWithBreak: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  boSubtitle: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: '65px',
    // paddingBottom: '2px',
    borderBottom: '1px solid black',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    '&> span': {
      alignSelf: 'center',
    },
    '& .MuiSwitch-root': {
      marginLeft: 'auto',
    },
  },
  boSubtitleAlternative: {
    display: 'flex',
    alignSelf: 'center',
    marginTop: '44px',
    // paddingBottom: '2px',
    borderBottom: '1px solid black',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    '&> span': {
      alignSelf: 'center',
    },
    '& .MuiSwitch-root': {
      marginLeft: 'auto',
    },
  },
  sliderHeaderButton: {
    marginLeft: `${theme.spacing(3)} !important`,
  },
  boBoxPointed: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed',
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(2.5),
  },
  reminderContainer: {
    display: 'flex',
    marginBottom: '15px',
  },
  iconContainer: {
    width: '24px',
    marginRight: theme.spacing(1),
    color: theme.palette.warning.dark,
  },
  circularSending: {
    color: theme.palette.info.dark,
  },
  formHelperText: {
    height: '24px',
  },
  iconButtonEdit: {
    position: 'absolute',
    right: theme.spacing(3),
    width: '56px',
    height: '56px',
  },
  titleWithIcon: {
    float: 'left',
    fontWeight: 700,
  },
  buttonEditIcon: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    color: 'white',
    padding: '6px',
    borderRadius: '6px',
    marginLeft: theme.spacing(2),
  },
  boxPaddingBottom: {
    padding: '0 0 30px 0',
  },
  stickyElement: {
    zIndex: Z_INDEX.LOWER,
    //para ie y safari
    position: '-webkit-sticky',
    // eslint-disable-next-line
    position: 'sticky',
    top: 0,
  },
  ItemList: {
    paddingLeft: theme.spacing(1),
  },
  tooltipHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconHiddenPortfolioByAdmin: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    color: theme.palette.primary.hiddenByAdmin.main + ' !important',
    width: 'fit-content',
    height: 'fit-content',
    padding: theme.spacing(1.125),
    '&:hover': {
      color: theme.palette.primary.hiddenByAdmin.main + ' !important',
    },
  },
  boWorkflowStatusDescription: {
    marginRight: '6px',
    float: 'left',
    lineHeight: '3',
  },
  boWorkflowStatusBold: {
    color: theme.palette.primary.hiddenByAdmin.main,
    fontWeight: 600,
  },
  portfolioTextContainer: {
    color: 'rgba(28, 107, 251, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  portfolioText: {
    fontWeight: 600,
    fontSize: '14px',
    paddingRight: '5px',
  },
  fullWidth: {
    width: '100%',
  },
  table: {
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.dark.main,
      color: theme.palette.common.white,
      fontFamily: theme.typography.table.fontFamily,
      fontWeight: 600,
      padding: theme.spacing(2, 2),
      letterSpacing: 0.4,
    },
    '& .MuiTableCell-body': {
      fontFamily: theme.typography.table.fontFamily,
      wordBreak: 'break-word',
    },
  },
  tablePortfolio: {
    '& .MuiTableCell-head': {
      '&:nth-of-type(6)': {
        minWidth: 160,
      },
    },
    '& .MuiTableCell-body': {
      padding: theme.spacing(0.125, 2),
      '&:nth-of-type(1)': {
        padding: theme.spacing(1.875, 1),
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(2)': {
        fontWeight: 700,
      },
      '&:nth-of-type(3)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(4) p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '11ch',
      },
      '&:nth-of-type(5)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(6)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(7)': {
        fontWeight: 600,
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(8)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(9)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(9) > p': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: 13,
        lineHeight: theme.spacing(2.5),
        textDecoration: 'underline',
      },
    },
  },
  tableSections: {
    '& .MuiTableCell-head': {
      '&:nth-of-type(6)': {
        minWidth: 160,
      },
    },
    '& .MuiTableCell-body': {
      padding: theme.spacing(0.125, 1),
      '&:nth-of-type(1)': {
        minWidth: 50,
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(2)': {
        fontWeight: 700,
      },
      '&:nth-of-type(3)': {
        minWidth: 100,
      },
      '&:nth-of-type(4)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(5) p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '22ch',
      },
      '&:nth-of-type(6)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(7)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(8)': {
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(9)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(9) > p': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: 13,
        lineHeight: theme.spacing(2.5),
        textDecoration: 'underline',
      },
    },
  },
  tableArticles: {
    '& .MuiTableCell-head': {
      '&:nth-of-type(7)': {
        minWidth: 160,
      },
      '&:nth-of-type(10)': {
        minWidth: 60,
      },
    },
    '& .MuiTableCell-body': {
      padding: theme.spacing(0.125, 1),
      '&:nth-of-type(1)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(2)': {
        fontWeight: 700,
      },
      '&:nth-of-type(3)': {
        minWidth: 100,
      },
      '&:nth-of-type(4)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(5)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(6) p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '22ch',
      },
      '&:nth-of-type(7)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(8)': {
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(9)': {
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      '&:nth-of-type(10) > p': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: 13,
        lineHeight: theme.spacing(2.5),
        textDecoration: 'underline',
      },
    },
  },
  programsDetailTitle: {
    color: '#262626',
    fontSize: 30,
    fontWeight: 700,
    marginTop: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(12),
    },
  },
  helpingText: {
    fontSize: 11,
    fontWeight: 300,
    marginTop: 20,
  },
  excelButton: {
    border: '1px solid #262626',
    borderRadius: 30,
    color: '#262626',
    cursor: 'pointer',
    margin: 0,
    padding: theme.spacing(0.808, 2.5),
    '& p': {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '15.85px',
      margin: 0,
    },
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#262626',
      opacity: 1,
      border: '1px solid #262626',
      '& p': {
        color: '#ffffff',
      },
    },
  },
}));
