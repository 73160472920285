import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';
import { RoundsNotifyTypes } from '../utils/Constants';
import decode from 'jwt-decode';
import AuthService from './AuthService';

const EVENT_API_URL = '/event/';

class EventService {
  getFormData() {
    return axiosInstanceWithInterceptors.get(EVENT_API_URL + 'form/');
  }
  getSectorOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'form/options/sectors'
    );
  }
  saveRoundForm(data) {
    return axiosInstanceWithInterceptors.put(EVENT_API_URL + 'form/', data);
  }
  addParticipants = async (data, eventId) => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}participants/${eventId}`,
      data
    );
  };

  deleteParticipants = async (data, eventId) => {
    return await axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}participants/${eventId}`,
      data
    );
  };

  changeStatusParticipants = async (data, eventId, newStatus) => {
    return await axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}participants/status/${eventId}?new_status=${newStatus}`,
      data
    );
  };

  changePrioritiesParticipants = async (
    selectedUsers,
    newPriority,
    eventId
  ) => {
    return axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}participants/priority/${eventId}?new_priority=${newPriority}`,
      selectedUsers
    );
  };

  addGuest = async (eventId, data) => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}add/guest/${eventId}`,
      data
    );
  };

  inviteParticipants = async (data, eventId) => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}participants/invite/${eventId}`,
      data
    );
  };

  sendMessageToParticipants = async (data, eventId) => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}conversation/list/${eventId}`,
      data
    );
  };

  getEvents(params) {
    let filters = '?';
    filters += params.id_event ? '&id_event=' + params.id_event : '';
    filters += params.sector ? '&sector_filter=' + params.sector : '';
    filters += params.status ? '&status_filter=' + params.status : '';
    filters += params.name ? '&name=' + encodeURIComponent(params.name) : '';
    if (params.workflow && params.workflow?.length !== 0) {
      params.workflow.map((item) => (filters += '&workflow_filter=' + item));
    }
    if (params.sectors && params.sectors?.length !== 0) {
      params.sectors.map((item) => (filters += '&sectors_filter=' + item));
    }
    if (params.typeMeeting && params.typeMeeting?.length !== 0) {
      params.typeMeeting.map((item) => (filters += '&type_meeting=' + item));
    }
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    filters += '&sort_field=date_start';
    filters += params.type_class
      ? '&type_class=' + (params.id_domain ? 'domain' : params.type_class)
      : '';
    filters += params.dateStart ? '&date_start=' + params.dateStart : '';
    filters += params.dateEnd ? '&date_end=' + params.dateEnd : '';
    filters += params.communication
      ? '&communication=' + params.communication
      : '';
    filters += params.typeEvent ? '&type_event=' + params.typeEvent : '';
    filters += params.id_domain ? '&id_domain=' + params.id_domain : '';
    filters += params.id_node ? '&id_node=' + params.id_node : '';
    return axiosInstanceWithInterceptors.get(EVENT_API_URL + filters);
  }

  getEventParticipants(params) {
    let filters = '?';
    filters += params.role ? '&role_filter=' + params.role : '';
    filters += params.status ? '&status_filter=' + params.status : '';
    filters += params.priority ? '&priority_filter=' + params.priority : '';
    filters += params.name
      ? '&name_filter=' + encodeURIComponent(params.name)
      : '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'participants/' + params.id + filters
    );
  }

  getTurnsBO(params) {
    let filters = '?';
    filters += params.participantStatus
      ? '&user_status=' + params.participantStatus
      : '';
    filters += params.turnStatus ? '&turn_status=' + params.turnStatus : '';
    filters += params.name ? '&q=' + encodeURIComponent(params.name) : '';
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'turn/' + params.id + filters
    );
  }

  getTurnsAgenda(params) {
    let filters = '?';
    filters += params.participantStatus
      ? '&user_status=' + params.participantStatus
      : '';
    filters += params.turnStatus ? '&turn_status=' + params.turnStatus : '';
    filters += params.name ? '&q=' + encodeURIComponent(params.name) : '';
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'calendar/turn/' + params.id + '?' + filters
    );
  }

  getTurnsInfo(params) {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + `turn/abstract?id_event=${params.id}`
    );
  }

  getAutocompleteParticipants(params) {
    let filters = '?';
    filters += params.name
      ? '&name_filter=' + encodeURIComponent(params.name)
      : '';
    filters += params.turn_filter ? '&turn_filter=' + params.turn_filter : '';
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'autocomplete/participants/' + params.id + filters
    );
  }

  runAutomaticAssigner(id) {
    return axiosInstanceWithInterceptors.patch(
      EVENT_API_URL + 'automatic/assigner/turn?id_event=' + id,
      null
    );
  }

  getEventById(id) {
    return axiosInstanceWithInterceptors.get(EVENT_API_URL + '?id_event=' + id);
  }

  getTypeOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'options/event_type'
    );
  }

  getLocationOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'options/event_location'
    );
  }

  getStatusOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'options/event_status'
    );
  }

  getParticipantStatusOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'options/participant_status'
    );
  }

  getWhoOptions() {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'options/event_status'
    );
  }

  getRoundChatByUser(id_event, id_user) {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'conversation/chat/' + id_event + '/' + id_user
    );
  }

  markChatsAsSeen(idConversation, idsChat) {
    return axiosInstanceWithInterceptors.patch(
      EVENT_API_URL + 'conversation/chat/seen/' + idConversation,
      idsChat
    );
  }

  initEvent() {
    return axiosInstanceWithInterceptors.post(EVENT_API_URL, null);
  }

  saveEvent(data, id) {
    return axiosInstanceWithInterceptors.patch(EVENT_API_URL + id, data);
  }

  changeEventStatus(id, status, text = '') {
    return axiosInstanceWithInterceptors.patch(
      EVENT_API_URL + id + '/' + status + '/status',
      JSON.stringify(text)
    );
  }

  executeTurnAction = async (data, id) => {
    return await axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}manual/handler/turn?id_event=${id}`,
      data
    );
  };

  duplicateEvent(id) {
    const url = `${EVENT_API_URL}duplicate/${id}`;
    return axiosInstanceWithInterceptors.post(url, null);
  }

  checkIfUserIsParticipant(id_event, type) {
    return axiosInstanceWithInterceptors.get(
      EVENT_API_URL + 'guest/' + id_event + '?type=' + type
    );
  }
  notifyChange(data, id) {
    const status = data.who?.map((who) => 'status_invitation=' + who).join('&');
    const roles = data.roles?.map((role) => 'role_event=' + role).join('&');
    const query =
      '?' +
      (status
        ? 'role_event=' + RoundsNotifyTypes.SELLER + '&' + status
        : status) +
      (roles ? '&' + roles : '');
    return axiosInstanceWithInterceptors.post(
      EVENT_API_URL + 'notification/' + id + query,
      { description: data.notify_text }
    );
  }

  uploadCoverImage = async (file, fileData) => {
    const url = `${EVENT_API_URL}cover/${fileData.id_event}`;
    const body = file;
    body.append('alt_text', fileData.alt_text);
    body.append('description', fileData.description);
    return await axiosInstanceWithInterceptors.post(url, body);
  };

  getCalendarActive(filters) {
    const url = `${EVENT_API_URL}calendar/active/`;
    return axiosInstanceWithInterceptors.get(url, {
      params: filters,
    });
  }

  getCalendarHistory(filters) {
    const url = `${EVENT_API_URL}calendar/history/`;
    return axiosInstanceWithInterceptors.get(url, {
      params: filters,
    });
  }

  getCurrentEvent(id_event) {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}current/${id_event}`
    );
  }

  getAdditionalLink(id_event, id_user) {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}meet/public/${id_event}/${id_user}`
    );
  }

  getMainLink(id_event, id_user) {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}meet/private/${id_event}/${id_user}`
    );
  }

  getAdminLink(id_event) {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}meet/backoffice/${id_event}`
    );
  }

  getUrlForPrivateMeet = async (token) => {
    return await axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}meet/${token}`
    );
  };

  getIdEventFromToken = (token) => {
    try {
      const { id_event } = decode(token);
      return id_event;
    } catch (error) {
      console.error('Formato de token inváido: subject', error);
      return false;
    }
  };

  getExcelRounds(params) {
    let filters = '?';
    if (params.sectors && params.sectors?.length !== 0) {
      params.sectors.map((item) => (filters += '&sectors_filter=' + item));
    }
    filters += params.communication
      ? '&communication=' + params.communication
      : '';
    filters += params.status ? '&status_filter=' + params.status : '';
    filters += params.name ? '&name=' + params.name : '';
    filters += params.typeEvent ? '&type_event=' + params.typeEvent : '';
    if (params.typeMeeting && params.typeMeeting?.length !== 0) {
      params.typeMeeting.map((item) => (filters += '&type_meeting=' + item));
    }
    filters += params.dateStart ? '&date_start=' + params.dateStart : '';
    filters += params.dateEnd ? '&date_end=' + params.dateEnd : '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/round` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getConsolidatedRounds() {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/consolidated/rounds/1004`,
      AuthService.getAuthHeaderBlob()
    );
  }
  getConsolidatedRoundsFromId(id_cycle) {
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/consolidated/rounds/${id_cycle}`,
      AuthService.getAuthHeaderBlob()
    );
  }

  getExcelParticipantsInRounds(params) {
    let filters = '';
    filters += params.id ? params.id + '?' : '';
    filters += params.role ? '&role_filter=' + params.role : '';
    filters += params.status ? '&status_filter=' + params.status : '';
    filters += params.priority ? '&priority_filter=' + params.priority : '';
    filters += params.name
      ? '&name_filter=' + encodeURIComponent(params.name)
      : '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/round/participants/` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getExcelMeeting(params) {
    let filters = '?';
    filters += params.name ? '&name=' + params.name : '';
    filters += params.typeEvent ? '&type_event=' + params.typeEvent : '';
    if (params.sectors && params.sectors?.length !== 0) {
      params.sectors.map((item) => (filters += '&sectors_filter=' + item));
    }
    if (params.typeMeeting && params.typeMeeting?.length !== 0) {
      params.typeMeeting.map((item) => (filters += '&type_meeting=' + item));
    }
    filters += params.communication
      ? '&communication=' + params.communication
      : '';
    filters += params.status ? '&status_filter=' + params.status : '';
    filters += params.dateStart ? '&date_start=' + params.dateStart : '';
    filters += params.dateEnd ? '&date_end=' + params.dateEnd : '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/meeting` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getExcelShows(params) {
    let filters = '?';
    filters += params.id_show ? '&id_show=' + params.id_show : '';
    filters += params.visible ? '&visible=' + params.visible : '';
    filters += params.title ? '&title=' + params.title : '';
    if (params.sectors && params.sectors?.length !== 0) {
      params.sectors.map((item) => (filters += '&sectors_filter=' + item));
    }
    if (params.status && params.status?.length !== 0) {
      params.status.map((item) => (filters += '&status=' + item));
    }
    filters += params.externalLink
      ? '&has_external_url=' + params.externalLink
      : '';
    filters += params.dateStart ? '&date_from=' + params.dateStart : '';
    filters += params.dateEnd ? '&date_to=' + params.dateEnd : '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/shows` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getExcelSchedule(params) {
    let filters = '?';
    filters += params.id ? 'id_event=' + params.id : '';
    filters += params.participantStatus
      ? '&user_status=' + params.participantStatus
      : '';
    filters += params.turnStatus ? '&turn_status=' + params.turnStatus : '';
    filters += params.name ? '&q=' + params.name : '';
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}export/excel/turns` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  //Shows
  getShows(showParams) {
    let params = new URLSearchParams();
    if (showParams.pageNumber) params.append('page_num', showParams.pageNumber);
    if (showParams.pageSize) params.append('page_size', showParams.pageSize);
    params.append('sort_field', 'date_start');
    if (showParams.id_show) params.append('id_show', showParams.id_show);
    if (showParams.title) params.append('title', showParams.title);
    if (showParams.visible) params.append('visible', showParams.visible);
    if (showParams.externalLink)
      params.append('has_external_url', showParams.externalLink);
    showParams.sectors?.map((sector) =>
      params.append('sectors_filter', sector)
    );
    showParams.status?.map((status) => params.append('status', status));
    if (showParams.dateStart) params.append('date_from', showParams.dateStart);
    if (showParams.dateEnd) params.append('date_to', showParams.dateEnd);
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}show/?${params.toString()}`
    );
  }
  initShow = async () => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}show/`,
      null
    );
  };
  saveShow(data, id) {
    return axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}show/${id}`,
      data
    );
  }
  uploadShowImage(file, fileData) {
    const url = `${EVENT_API_URL}show/image/${fileData.id}`;
    const body = file;
    body.append('alt_text', fileData.alt_text);
    body.append('description', fileData.description);
    return axiosInstanceWithInterceptors.post(url, body);
  }
  uploadShowCardImage(file, fileData) {
    const url = `${EVENT_API_URL}show/card_image/${fileData.id}`;
    const body = file;
    body.append('alt_text', fileData.alt_text);
    body.append('description', fileData.description);
    return axiosInstanceWithInterceptors.post(url, body);
  }
  deleteShow(id) {
    const url = `${EVENT_API_URL}show/${id}`;
    return axiosInstanceWithInterceptors.delete(url);
  }

  // Cycles
  initCycle = async () => {
    return await axiosInstanceWithInterceptors.post(
      `${EVENT_API_URL}cycles/`,
      null
    );
  };
  getCycles(params) {
    let filters = '?';
    if (params?.pageNumber)
      filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    if (params?.pageSize)
      filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += '&sort_field=date_created';
    if (params?.id_cycle)
      filters += params.id_cycle ? '&id_cycle=' + params.id_cycle : '';
    if (params?.title)
      filters += params.title
        ? '&title=' + encodeURIComponent(params.title)
        : '';
    if (params?.sectors && params?.sectors?.length !== 0) {
      params.sectors.forEach((item) => {
        filters += '&sectors_filter=' + item;
      });
    }
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}cycles/${filters}`
    );
  }
  getAutocompleteCycles(params) {
    let filters = '?';
    filters += params?.publish ? '&publish=' + params?.publish : '';
    filters += params?.sort_order ? '&sort_order=' + params?.sort_order : '';
    filters += params?.sort_field ? '&sort_field=' + params?.sort_field : '';
    filters += params?.id_cycle ? '&id_cycle=' + params?.id_cycle : '';
    filters += params?.title
      ? '&title=' + encodeURIComponent(params?.title)
      : '';
    if (filters === '?') filters = '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}cycles/autocomplete/${filters}`
    );
  }
  saveCycle(data, id) {
    return axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}cycles/${id}`,
      data
    );
  }
  deleteCycle(id) {
    const url = `${EVENT_API_URL}cycles/${id}`;
    return axiosInstanceWithInterceptors.delete(url);
  }

  getNodes(idDomain, params) {
    let filters = '?';
    filters += params?.name ? '&name=' + params?.name : '';
    return axiosInstanceWithInterceptors.get(
      `${EVENT_API_URL}events/nodes/${idDomain}${filters}`
    );
  }

  highlightEvent(idEvent, highlightState) {
    return axiosInstanceWithInterceptors.patch(
      `${EVENT_API_URL}events/highlight/${idEvent}/${highlightState}`,
      null
    );
  }
}

// eslint-disable-next-line
export default new EventService();
