import { createTextLinks } from '../utils/utils';
import { MaterializeCommonStyles } from '../MaterializeStylesCommons';
import classnames from 'classnames';
import DOMPurify from 'dompurify';

export const CreateTextLinks = ({
  children,
  className = '',
  colorText,
  sanitize = true,
}) => {
  let clean =
    children && sanitize
      ? DOMPurify.sanitize(createTextLinks(children))
      : createTextLinks(children);
  return (
    <p
      className={classnames(className, MaterializeCommonStyles().textWithBreak)}
      style={{ color: colorText }}
      dangerouslySetInnerHTML={{
        __html: clean,
      }}
    />
  );
};
