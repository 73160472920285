import { useRef, useState } from 'react';
import classnames from 'classnames';

import {
  Badge,
  Button,
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { IconWithMenuStyles } from './IconWithMenuStyles.css';
import { CustomIcon } from '../CustomIcons/CustomIcons';
import { NotificationDetailModal } from '../Messages/NotificationDetailModal';
import {
  formatDate,
  formatReadableDateShortYear,
  onKeyDownEscape,
} from '../../utils/formHelper';
import MessengerService from '../../services/MessengerService';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { TranslateErrorMessage } from '../../utils/utils';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';
import { DATE_FORMAT } from '../../utils/Constants';
import i18next from 'i18next';

function NotificationIcon({ onClick, isLargeWindow, unread, checkUnread }) {
  const classes = IconWithMenuStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const largeWindow = useMediaQuery(theme.breakpoints.up('lg'));
  const [modalContent, setModalContent] = useState(null);
  const [modalContentOpen, setModalContentOpen] = useState(false);
  const { updateUserData } = useCurrentUserData();
  const unreadCount = unread?.notifications?.total_count;
  const notifications = unread?.notifications?.notification_list;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleIconClick = () => {
    isMobile ? goToView() : toggleMenu();
    onClick?.();
  };

  const toggleMenu = () => {
    const newStatus = !open;
    setOpen(newStatus);
    if (newStatus) {
      checkUnread();
    }
  };

  const handleClose = () => setOpen(false);

  const onClose = () => {
    setModalContent(null);
    setModalContentOpen(false);
  };

  const goToView = () => history.push('/notificaciones');
  const snackBars = (type, text, error) => {
    dispatch(
      setSnackbar(
        true,
        type,
        type === 'error' ? (
          <TranslateErrorMessage
            translationErrorsList={text}
            errorKey={error}
          />
        ) : (
          t(text)
        )
      )
    );
  };

  const openNotificationModal = (id) => {
    if (!isLoading) {
      setIsLoading(true);
      MessengerService.getNotification(id).then(
        (res) => {
          const { data } = res;
          setModalContent(data);
          setModalContentOpen(true);
          setIsLoading(false);
          if (!data.opened) setNotificationOpened(data, true);
          handleClose();
        },
        (error) => {
          snackBars('error', `notifications.error`, error);
          setIsLoading(false);
        }
      );
    }
  };

  const setNotificationOpened = (notification, opened) => {
    const params = { ids: [notification?.id_notification], opened: opened };
    MessengerService.patchMultipleNotificationsOpened(params).then(
      () => {
        updateUserData();
      },
      (error) => {
        console.error('setNotificationOpened: ' + error);
      }
    );
  };

  const labelMenu = () => {
    return unreadCount
      ? isLargeWindow
        ? t('accessibility.navbar.notificationIcon.labelIconWhitMessages', {
            count: unreadCount,
          }) + t('accessibility.navbar.lateralMenu.tab')
        : t('accessibility.navbar.notificationIcon.labelIconWhitMessages', {
            count: unreadCount,
          })
      : t('accessibility.navbar.notificationIcon.labelIcon');
  };
  const onClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <div
      className={classnames(classes.root, {
        [classes.hasNotification]: unreadCount > 0,
      })}
      onKeyDown={(e) => onKeyDownEscape(e, setOpen)}
    >
      <Button
        className={classes.itemRoot}
        onClick={handleIconClick}
        disableRipple
        ref={anchorRef}
        aria-label={labelMenu()}
        role={'button'}
      >
        <Badge
          badgeContent={unreadCount}
          color='secondary'
          className={classes.badge}
          role='none'
        >
          <CustomIcon
            color='white'
            iconSrc={`${process.env.PUBLIC_URL}/icons/notification.svg`}
            size='40'
            iconAlt=''
          />
        </Badge>
      </Button>
      <Popper
        id='notificationsMenu'
        open={!isMobile && open}
        anchorEl={anchorRef.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: isMd ? [-100, 0] : [-50, 0],
            },
          },
        ]}
        style={{ paddingTop: largeWindow ? '' : '10px' }}
      >
        {({ TransitionProps }) => (
          <Collapse in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={onClickAway}>
              <Paper className={classes.messagePaper}>
                <MenuList id='Notification-inner-papper' disablePadding>
                  {unreadCount ? (
                    <MenuItem className={classes.notificationHeaderWrapper}>
                      <Typography className={classes.messageHeader}>
                        {t('navbar.notifications.not-empty')}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      className={classes.emptyNotificationHeaderWrapper}
                    >
                      <Typography className={classes.messageHeader}>
                        {t('navbar.notifications.empty')}
                      </Typography>
                    </MenuItem>
                  )}
                  {notifications?.map((notification, index) => (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        openNotificationModal(notification?.id_notification)
                      }
                      className={classes.notificationBody}
                    >
                      <Button className={classes.buttonNoti}>
                        <div className={classes.containerNoti}>
                          <div className={classes.notificationTitleWrapper}>
                            <Typography className={classes.notificationTitle}>
                              {notification.title_notification}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              aria-label={formatDate(
                                notification.date_created,
                                DATE_FORMAT.STANDARD,
                                i18next?.language
                              )}
                              className={classes.notificationDate}
                            >
                              {formatReadableDateShortYear(
                                notification.date_created
                              )}
                            </Typography>
                          </div>
                        </div>
                      </Button>
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={handleClose}
                    className={classes.messageFooterWrapper}
                  >
                    <Link
                      className={classes.allMenssage}
                      to={'/notificaciones'}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.messageFooter}>
                        {t('navbar.notifications.view-all')}
                      </Typography>
                    </Link>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Collapse>
        )}
      </Popper>

      <NotificationDetailModal
        isOpen={modalContentOpen}
        onClose={onClose}
        notification={modalContent}
      />
    </div>
  );
}

export default NotificationIcon;
