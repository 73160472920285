import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.main,
      borderColor: 'currentcolor',
      backgroundColor: theme.palette.primary.light,
    },
    minWidth: '100%',
    // width: 'fit-content',
    border: theme.shape.border,
    borderColor: '#000',
    color: theme.palette.secondaryAccent.light,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.artistActionButtons.borderRadius20,
    fontSize: '.85rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionAdd = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      startIcon={<AddIcon />}
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
      disabled={props?.disabled}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
};
