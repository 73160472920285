import makeStyles from '@mui/styles/makeStyles';

export const ExpandableTextStyles = makeStyles((theme) => ({
  expander: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1),
    border: theme.shape.border,
    color: theme.palette.secondaryAccent.light,
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      borderColor: 'currentcolor',
      color: theme.palette.secondaryAccent.main,
    },
    textTransform: 'capitalize',
    '& span': {
      fontSize: '.85rem',
    },
    '& svg': {
      width: '15px',
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& span': {
        fontSize: '1rem',
      },
    },
  },
  textEllipsis: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
