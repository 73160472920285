import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { CallToAction } from '../CallToAction/CallToAction';
import { GetPreviousLocation } from '../../context/LoginContext';
import { handleKeyUp, TranslateErrorMessage } from '../../utils/utils';
import { LOGIN_ERRORS } from '../../utils/Constants';
import {
  MaterializeCommonStyles,
  TextFieldsCommonPropStyles,
} from '../../MaterializeStylesCommons';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { updateAbility } from '../../context/ability/Ability';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';
import { useLoginStyles } from './LoginStyles';
import AuthService from '../../services/AuthService';
import LoginInactiveUser from '../../shared/LoginInactiveUser';
import LoginValidateMail from '../../shared/LoginValidateMail';
import SEO from '../../shared/SEO';
import { useHistory, Link } from 'react-router-dom';
import LoginUserNotExists from '../../shared/LoginUserNotExists';

export default function Login(props) {
  const theme = useTheme();
  const isLargeWindow = useMediaQuery(theme.breakpoints.up('md'));
  const classes = MaterializeCommonStyles();
  const classesCustom = useLoginStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { closeLogin } = props;
  const [showValidateMailWarning, setShowValidateMailWarning] = useState(false);
  const [showInactiveUserWarning, setShowInactiveUserWarning] = useState(false);
  const [showUserNotExists, setShowUserNotExists] = useState(false);
  const { updateUserData } = useCurrentUserData();
  const previousLocation = GetPreviousLocation();
  const initialValuesForm = {
    email: '',
    password: '',
  };

  const schemaForm = Yup.object().shape({
    email: Yup.string()
      .email(t('login.form.fields.email.invalid'))
      .required(t('login.form.fields.email.required')),
    password: Yup.string()
      .required(t('login.form.fields.password.required'))
      // .matches(/(?=.*[0-9])/, "Password must contain a number.")
      .min(4, t('login.form.fields.password.invalid')), //lo dejo en 4 para usuarios viejos, los nuevos van a ser de 8
  });
  const onSubmitForm = (values, actions) => {
    const valuesSendsToRequest = buildRequestForm(values);
    return AuthService.login(valuesSendsToRequest)
      .then(() => {
        actions.setSubmitting(false);
        updateUserData();
        updateAbility();
        if (closeLogin) {
          closeLogin();
        }
        const urlForRedirect = AuthService.canAccessToBackoffice()
          ? '/administracion'
          : previousLocation
          ? previousLocation
          : '/';
        history.push(urlForRedirect);
      })
      .catch((error) => {
        if (error === LOGIN_ERRORS.NOT_CONFIRMED) {
          setShowValidateMailWarning(true);
        }
        if (error === LOGIN_ERRORS.INACTIVE_USER) {
          setShowInactiveUserWarning(true);
        }
        if (error === LOGIN_ERRORS.LOGIN_USER_NOT_EXISTS) {
          setShowUserNotExists(true);
        }
        dispatch(
          setSnackbar(
            true,
            'error',
            <TranslateErrorMessage
              translationErrorsList={'login.form.submit.messages.error'}
              errorKey={error}
            />
          )
        );
      });
  };

  const buildRequestForm = (formValues) => {
    const requestValues = {
      username: formValues.email,
      password: formValues.password,
    };
    return requestValues;
  };

  const goToRegister = () => {
    history.push('/registro');
    if (closeLogin) {
      closeLogin();
    }
  };

  const onKeyUp = (e, handleSubmit) => {
    if (e.charCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Container
      component='main'
      maxWidth='xs'
      className={classnames(classes.formContainer, classesCustom.formContainer)}
    >
      <SEO title={t('login.title')} />
      <div className={classnames(classes.paper, classesCustom.paper)}>
        <Typography component='h1' variant='h5' className={classes.formTitle}>
          {t('login.title')}
        </Typography>

        <Formik
          initialValues={initialValuesForm}
          onSubmit={onSubmitForm}
          validationSchema={schemaForm}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
            } = props;
            return (
              <Form
                className={classnames(
                  classes.form,
                  classesCustom.formContainer
                )}
                noValidate
                onSubmit={handleSubmit}
                autoComplete='off'
                onKeyUp={(e) => handleKeyUp(e, handleSubmit)}
              >
                <Container maxWidth='sm'>
                  {showValidateMailWarning && (
                    <LoginValidateMail
                      email={values.email}
                      onClose={closeLogin}
                    />
                  )}
                  {showInactiveUserWarning && <LoginInactiveUser />}
                  {showUserNotExists && <LoginUserNotExists />}
                  <TextField
                    onKeyPress={(e) => {
                      onKeyUp(e, handleSubmit);
                    }}
                    className={classes.textField}
                    variant='standard'
                    margin='normal'
                    fullWidth
                    label={t('login.form.fields.email.label')}
                    placeholder={t('login.form.fields.email.placeholder')}
                    id='email'
                    name='email'
                    autoComplete='email'
                    type='email'
                    inputProps={{ autoCorrect: 'off', autoCapitalize: 'none' }}
                    autoFocus
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ''
                    }
                    {...TextFieldsCommonPropStyles(classes)}
                  />
                  <TextField
                    onKeyPress={(e) => {
                      onKeyUp(e, handleSubmit);
                    }}
                    className={classes.textField}
                    variant='standard'
                    margin='normal'
                    fullWidth
                    name='password'
                    label={t('login.form.fields.password.label')}
                    placeholder={t('login.form.fields.password.placeholder')}
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password ? errors.password : ''
                    }
                    {...TextFieldsCommonPropStyles(classes)}
                  />
                  <Grid item xs={12} md={6}>
                    <Link
                      to={'/blanquear-clave/' + values.email}
                      onClick={() => closeLogin()}
                      variant='body2'
                      className={classnames(
                        classes.formAdditionalLinks,
                        classesCustom.pass
                      )}
                      align='left'
                      underline='hover'
                    >
                      {t('login.form.links.reset-password')}
                    </Link>
                  </Grid>
                  <Grid className={classesCustom.submitBtn}>
                    <CallToAction
                      kind='primary'
                      disabled={!isValid || isSubmitting}
                      classes={classes.submit}
                      onClickHandler={handleSubmit}
                      label={
                        isSubmitting
                          ? t('login.form.submit.button.loading')
                          : t('login.form.submit.button.default')
                      }
                    />
                  </Grid>
                </Container>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Grid
        container
        maxWidth='sm'
        className={
          showValidateMailWarning
            ? classesCustom.greyedContainer
            : classesCustom.newUserContainer
        }
      >
        <Typography
          component='p'
          className={
            isLargeWindow ? classesCustom.newUser : classesCustom.newUserMobile
          }
        >
          {t(showValidateMailWarning ? 'login.need-user' : 'login.new-user')}
        </Typography>
        <Button className={classesCustom.register} onClick={goToRegister}>
          {t('login.sing-up')}
        </Button>
      </Grid>
    </Container>
  );
}
