import { Box } from '@mui/system';
import classNames from 'classnames';
import { useSlickCarouselStyles } from '../../../../components/SlickCarousel/useSlickCarouselStyles';
import { isTouchDevice } from '../../../../utils/utils';

export const HomeSectorsArrow = (props) => {
  const {
    className,
    onClick,
    src,
    isLeft,
    height,
    home = true,
    isArrowDisabled = false,
  } = props;
  const classes = useSlickCarouselStyles();
  let shouldShowArrow = !isTouchDevice();
  const handleClick = () => {
    if (!isArrowDisabled) {
      onClick();
    } else {
      return;
    }
  };
  return (
    shouldShowArrow && (
      <Box
        className={
          home
            ? isLeft
              ? classes.arrowBoxLeftHome
              : classes.arrowBoxRightHome
            : isLeft
            ? classes.arrowBoxLeft
            : classes.arrowBoxRight
        }
        sx={{
          opacity: isArrowDisabled ? 0.5 : 1,
          height: height,
        }}
      >
        <img
          src={src}
          onClick={handleClick}
          className={classNames(
            className,
            classes.arrowMarginHome,
            isLeft ? classes.customArrowLeftHome : classes.customArrowRightHome
          )}
          alt='sector-arrow'
        />
      </Box>
    )
  );
};
