export const Features = {
  CONVERSATION: true,
  NOTIFICATION: true,
  BOOKMARKS: false,
  FOLLOW: false,
  SHARE: true,
  LIKE: false,
  ACCOUNT_OPTIONS: true,
  ACCOUNT_ADVANCED_OPTIONS: true,
  ACCOUNT_DELETE: false,
  SITE_MAP: false,
  CONTACT: false,
  SEARCH: true,
  NAVBAR_SEARCH_BY_SECTOR: false,
  EVENTS: true,
  SOCIAL_NETWORKS: true,
  CALENDAR: false,
  USERFILE: true,
  BULK_NOTIFICATIONS_MAILS: true,
  BULK_NOTIFICATIONS_SCHEDULE: false,
  BULK_NOTIFICATIONS_PAUSE: true,
  BULK_NOTIFICATIONS_EXPORT: true,
  ROUND_TURNS: false,
  STATISTICS_USE_SITE: true,
  STATISTICS_ACCOUNTS_AND_CONTENT: true,
  PROGRAMS: true,
  PROGRAM: false,
};
