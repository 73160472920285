import { Dialog } from '@mui/material';
import { useLoginContext } from '../../context/LoginContext';
import { MaterializeCommonStyles } from '../../MaterializeStylesCommons';
import Login from './Login';

function LoginModal() {
  const classes = MaterializeCommonStyles();
  const { loginData, setLoginData } = useLoginContext();
  const isOpen = loginData.isOpen;
  const closeLogin = () => {
    setLoginData({ ...loginData, isOpen: false });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeLogin}
      classes={{ root: classes.rootDialog }}
      onBackdropClick={closeLogin}
    >
      <Login closeLogin={closeLogin} />
    </Dialog>
  );
}

export default LoginModal;
