import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { buildArticlePublishedPath } from '../../shared/BackOffice/Buttons/BOCommonButtons';
import { ImageLazy } from '../Imagelazy/ImageLazy';
import { IMAGE_LOADING, IMAGE_SIZE } from '../../utils/Constants';
import { isObjectEmpty } from '../../utils/formHelper';
import ProductActionsLike from './ProductActions/ProductActionsLike';
import ProductActionsFollow from './ProductActions/ProductActionsFollow';

const useStyles = makeStyles((theme) => ({
  root: () => ({
    backgroundColor: 'transparent',
    display: 'block',
    padding: theme.spacing(0),

    height: 400,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      padding: theme.spacing(1, 1, 0),
    },
  }),
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 1.5, 0.5, 0),
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
  },
  detailsContainer: () => ({
    display: 'block',
    flexGrow: 1,
    gridTemplateAreas: '"article article" "tags actions"',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '1fr min-content',
    paddingTop: 12,
    '& header': {
      display: 'flex',
      gridArea: 'actions',
      justifyContent: 'space-between',
      '& button + button': {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
      },
    },
    '& article': {
      display: 'block',
      flexDirection: 'column',
      gridArea: 'article',
      paddingBottom: 12,
      paddingTop: 12,
    },
    '& footer': {
      alignItems: 'center',
      display: 'block',
      gridArea: 'tags',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateAreas: '"article" "tags" "actions"',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    },
  }),
  name: () => ({
    alignItems: 'center',
    color: theme.palette.secondaryAccent.main,
    display: 'flex',
    flexGrow: 1,
    fontSize: 16,
    '&:hover a': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
    },
  }),
  borderRadius: () => ({
    borderRadius: 10,
  }),
  imageContainer: () => ({
    background:
      'linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(255,255,255,1) 100%)',
    display: 'flex',
    height: 262,
    justifyContent: 'center',
    minWidth: 100,
    width: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  image: () => ({
    height: 224,
    maxWidth: 500,
    objectFit: 'cover',
    objectPosition: 'center',
    width: 'inherit',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 300,
    },
  }),
  productName: {
    color: theme.palette.dark.main,
    display: '-webkit-box',
    lineHeight: 'auto',
    fontWeight: 600,
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    wordBreak: 'break-word',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  artistDetails: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(0.5),
    width: '100%',
    '& > div:nth-of-type(1)': {
      flexGrow: 1,
    },
    '& > div:last-child': {
      width: 'min-content',
    },
  },
  chip: () => ({
    background: 'transparent',
    border: theme.shape.border,
    borderColor: '#d1d1d1',
    color: theme.palette.secondaryAccent.main,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  }),
  productType: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  bookmark: {
    position: 'fixed',
    right: 15,
    top: 10,
  },
}));

const RelatedProductCard = (props) => {
  const classes = useStyles();
  const { product, isPreview, isSelf, borderRadius = false } = props;
  const { t } = useTranslation();

  if (!isObjectEmpty(product)) {
    return (
      <Grid item xs={12} className={classes.rootSpace}>
        <Paper elevation={0} className={classes.root}>
          <Link
            to={buildArticlePublishedPath(product.id_user, product.id_item)}
            className={classes.cardWrapper}
          >
            <div>
              <ImageLazy
                sizePreferer={IMAGE_SIZE.SMALL}
                srcVersions={product?.cover?.versions}
                height={224}
                alt={product.cover?.alt_text ?? ''}
                loading={IMAGE_LOADING.AUTO}
                classes={classNames(
                  classes.image,
                  borderRadius && classes.borderRadius
                )}
              />
              <div className={classes.bookmark}>
                <ProductActionsFollow
                  isSelf={isSelf}
                  productName={product.title}
                  productId={product.id_item}
                />
              </div>
            </div>
            <div className={classes.detailsContainer}>
              {!isPreview && (
                <header>
                  <ProductActionsLike
                    isSelf={isSelf}
                    productName={product.title}
                    productId={product.id_item}
                  />
                </header>
              )}
              <Container disableGutters>
                <Typography variant='body1' className={classes.productType}>
                  {t('navbar.search.entity-type.' + product?.data_type)}
                </Typography>
              </Container>
              <Container disableGutters className={classes.artistDetails}>
                <Container disableGutters>
                  <Typography variant='body1' className={classes.productName}>
                    {product.title}
                  </Typography>
                </Container>
              </Container>
            </div>
          </Link>
        </Paper>
      </Grid>
    );
  } else {
    return null;
  }
};

export default RelatedProductCard;
