import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../services/AuthService';
import StatisticsService from '../services/StatisticsService';
import { shouldSkipHits } from '../utils/utils';
import { useCurrentUserData } from './useCurrentUserData';

export function useTrackingUserSession() {
  const location = useLocation();
  const referrer = document.referrer;
  const { setIsLoadingTokenUser } = useCurrentUserData();

  useEffect(() => {
    referrer && AuthService.saveReferer(referrer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldGetAnonymousToken = () => {
    return !AuthService.getAuthToken();
  };

  useEffect(() => {
    if (location?.pathname !== '/') {
      tracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const tracking = async () => {
    if (shouldGetAnonymousToken()) {
      setIsLoadingTokenUser(true);
      await AuthService.getAndSaveAnonymousToken();
      setIsLoadingTokenUser(false);
      sendCurrentUrlToHits();
    } else {
      setIsLoadingTokenUser(false);
      if (!shouldSkipHits()) {
        sendCurrentUrlToHits();
      }
    }
  };

  const sendCurrentUrlToHits = () => {
    let urlToTrack = '';
    const urlCutted = location?.pathname.split('/');
    const urlsUserFrienly = ['formulario', 'notas'];

    if (urlsUserFrienly.includes(urlCutted[1])) {
      urlToTrack = location?.pathname.split('-')[0];
      StatisticsService.sendHit({ url: urlToTrack });
    } else {
      urlToTrack = location?.pathname + location?.search;
      StatisticsService.sendHit({ url: urlToTrack });
    }
  };

  return {
    sendCurrentUrlToHits,
  };
}
