import { Link } from 'react-router-dom';
import { MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';

const DropDownCycles = ({ isMobile, menuCyclesOpen, closeMenu, cycles }) => {
  const classes = NavbarStyles();

  return (
    isMobile &&
    menuCyclesOpen &&
    cycles.map((cycle) => (
      <MenuItem
        onClick={closeMenu}
        className={classes.mainSubMenuMobileItemWrapper}
        component={Link}
        to={`/actividades/ciclos/${cycle.id_cycle}`}
        key={cycle.id_cycle}
      >
        <Typography className={classes.mainSubMenuMobileItem}>
          {cycle.title}
        </Typography>
      </MenuItem>
    ))
  );
};

export default DropDownCycles;
