import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMassiveNotificationStyles } from './useMassiveNotificationStyles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import classnames from 'classnames';
import { CreateTextLinks } from '../../../shared/CreateTextLinks';
import AddToCalendarDetailEvent from '../../Event/Detail/AddToCalendarDetailEvent';

export default function NotificationAppointmentDetail({
  date_text,
  location_text,
  isSeller,
  cancelled_turn,
  event,
}) {
  const { t } = useTranslation();
  const classes = useMassiveNotificationStyles();

  const shouldShowNotification = () => {
    return date_text || location_text;
  };

  if (!shouldShowNotification()) {
    return null;
  }

  return (
    <Container className={classes.appointmentDetailsContainer}>
      <Container disableGutters className={classes.appoint}>
        {!isSeller && (
          <Container disableGutters>
            {cancelled_turn ? (
              <Container
                disableGutters
                className={classes.appointmentDetailsTitleContainer}
              >
                <EventBusyIcon className={classes.calendarIconRed} />
                <Typography
                  className={classnames(
                    classes.appointmentDetailsTitle,
                    classes.calendarIconRed
                  )}
                >
                  {t(
                    'round-notification-appointment-detail.titleTurnCancelled'
                  )}
                </Typography>
              </Container>
            ) : (
              <Container
                disableGutters
                className={classes.appointmentDetailsTitleContainer}
              >
                <EventAvailableIcon className={classes.calendarIconBlue} />
                <Typography
                  className={classnames(
                    classes.appointmentDetailsTitle,
                    classes.calendarIconBlue
                  )}
                >
                  {t('round-notification-appointment-detail.title')}
                </Typography>
              </Container>
            )}
          </Container>
        )}

        <Container disableGutters className={classes.appointmentDetails}>
          <div className={classes.datePadding}>
            <Typography
              variant='body1'
              className={classes.appointmentDetailsSubtitle}
            >
              {t('round-notification-appointment-detail.date')}
            </Typography>
            <Typography>
              <CreateTextLinks className={classes.text}>
                {date_text}
              </CreateTextLinks>
            </Typography>
          </div>
          <div>
            <Typography
              variant='body1'
              className={classes.appointmentDetailsSubtitle}
            >
              {t('round-notification-appointment-detail.location')}
            </Typography>
            <Typography>
              <CreateTextLinks className={classes.text}>
                {location_text}
              </CreateTextLinks>
            </Typography>
          </div>
        </Container>
        {event && <AddToCalendarDetailEvent event={event} classes={classes} />}
      </Container>
    </Container>
  );
}
