import { useRef, useState } from 'react';
import MultiFeaturedItem from './MultiFeaturedItem';
import { Carousel } from 'react-responsive-carousel';
import classNames from 'classnames';
import { useCustomCarouselWithTextStyles } from '../../../../../components/CustomCarousel/CustomCarouselWithTextStyles';
import { IntervalTimes } from '../../../../../utils/Constants';
import { useEffect } from 'react';
import SkipLink from '../../../../../shared/Elements/SkipLinks/SkipLink';
import { useTranslation } from 'react-i18next';
import ArrowIconCustomCarrousel from '../../../../../components/CustomCarousel/ArrowIconCustomCarrousel';

const MultiFeatured = ({ items, indicators, tabPressed, autoPlay }) => {
  const { t } = useTranslation();
  const classes = useCustomCarouselWithTextStyles();
  const [sliderSelected, setsliderSelected] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  let carousel = useRef(null); // use useRef to get the carousel instance
  const arrows = true;

  useEffect(() => {
    // some validation to set the slider to 0
    if (carousel && carousel?.state?.selectedItem > 0) {
      carousel.state.selectedItem = 0;
    }
  }, [items, tabPressed]);

  useEffect(() => {
    let timerId;
    if (isLastSlide) {
      timerId = setTimeout(() => {
        setsliderSelected(0);
        setIsLastSlide(false);
      }, IntervalTimes.SLIDER);
    }
    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLastSlide]);

  const updateCurrentSlide = (index) => {
    if (sliderSelected !== index) {
      setsliderSelected(index);
    }
    if (index === items.length - 1) {
      setIsLastSlide(true);
    } else if (index < sliderSelected) {
      setIsLastSlide(false);
    }
  };
  const isSliderSelected = (index) => {
    return sliderSelected === index;
  };
  const handleMouseOver = () => {
    setShowArrows(true);
  };

  const handleMouseLeave = () => {
    setShowArrows(false);
  };

  return items?.length > 0 ? (
    <section
      id='multifeatured'
      aria-label={t('home.multifeatured.label', {
        size: items?.length,
      })}
    >
      <SkipLink href={`#notes`}>
        {t('home.multifeatured.skip-to-notes')}
      </SkipLink>
      <div
        onFocus={handleMouseOver}
        onBlur={handleMouseLeave}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <Carousel
          ref={(el) => (carousel = el)} // useRef
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          showArrows={arrows}
          showThumbs={false}
          showIndicators={indicators}
          showStatus={false}
          renderArrowPrev={ArrowFactory('prev', classes, arrows, showArrows)}
          renderArrowNext={ArrowFactory('next', classes, arrows, showArrows)}
          className={classNames(classes.slider, classes.sliderMultifeatured)}
          autoPlay={autoPlay}
          infiniteLoop={false}
          stopOnHover={true}
          interval={IntervalTimes.SLIDER}
          useKeyboardArrows={true}
          labels={{
            leftArrow: t('accessibility.slider.arrows.prev'),
            rightArrow: t('accessibility.slider.arrows.next'),
          }}
          selectedItem={sliderSelected}
          onChange={updateCurrentSlide}
          autoFocus={false}
        >
          {items?.map((item, idx) => (
            <MultiFeaturedItem
              item={item}
              key={idx}
              tabIndexProp={isSliderSelected(idx) ? 0 : -1}
            />
          ))}
        </Carousel>
      </div>
    </section>
  ) : null;
};

function ArrowFactory(direction, classes, arrows, showArrows) {
  const icon =
    direction === 'prev' ? (
      <ArrowIconCustomCarrousel
        backgroundColor={'white'}
        colorArrow='black'
        display={showArrows}
        inHome={true}
        style={{ height: '10px !important', width: '10px !important' }}
      />
    ) : (
      <ArrowIconCustomCarrousel
        backgroundColor={'white'}
        colorArrow='black'
        right
        display={showArrows}
        inHome={true}
      />
    );

  return (clickHandler, hasNext, label) => {
    const handlerClick = () => {
      clickHandler();
    };
    return arrows === false ? null : (
      <button
        className={classNames(
          classes.arrowContainer,
          classes[`arrow-${direction}-multiFeatured`],
          {
            [classes.arrowDisabled]: !hasNext,
          }
        )}
        onClick={handlerClick}
        aria-label={label}
        aria-disabled={!hasNext}
      >
        {icon}
      </button>
    );
  };
}
export default MultiFeatured;
