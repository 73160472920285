import { Button, MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import SubLateralCyclesMenuNavbar from '../../SubLateralCyclesMenuNavbar';
import DropDownCycles from './DropDownCycles';

const ButtonCycleMenu = ({
  isMobile,
  menuCyclesOpen,
  setMenuCyclesOpen,
  menuCyclesAnchorRef,
  isLargeWindow,
  setMenuSectorsOpen,
  setMenuDomainsOpen,
  closeMenu,
  cycles,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();

  const labelMenu = () => {
    return isLargeWindow
      ? t('accessibility.navbar.subMenu.cycles') +
          t('accessibility.navbar.subMenu.button') +
          t('accessibility.navbar.lateralMenu.tab')
      : t('accessibility.navbar.subMenu.cycles') +
          t('accessibility.navbar.subMenu.button');
  };
  return (
    <>
      <MenuItem
        id='cycle-button'
        onClick={() => {
          setMenuCyclesOpen(!menuCyclesOpen);
          setMenuSectorsOpen(false);
          setMenuDomainsOpen(false);
        }}
        className={classes.mainMenuItemWrapper}
        ref={menuCyclesAnchorRef}
        aria-expanded={menuCyclesOpen}
      >
        <Button className={classes.boxSector}>
          <Typography className={classes.mainMenuItem}>
            {t('navbar.links.main-menu.cycles.title')}
          </Typography>
          <span
            aria-label={labelMenu()}
            size='large'
            className={classes.mainMenuItemIcon}
          >
            {!isMobile ? (
              <KeyboardArrowRightIcon />
            ) : menuCyclesOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </span>
        </Button>
      </MenuItem>
      <SubLateralCyclesMenuNavbar
        menuCyclesOpen={menuCyclesOpen}
        isMobile={isMobile}
        menuCyclesAnchorRef={menuCyclesAnchorRef}
        setMenuCyclesOpen={setMenuCyclesOpen}
        closeMenu={closeMenu}
        cycles={cycles}
      />
      <DropDownCycles
        isMobile={isMobile}
        menuCyclesOpen={menuCyclesOpen}
        closeMenu={closeMenu}
        cycles={cycles}
      />
    </>
  );
};

export default ButtonCycleMenu;
