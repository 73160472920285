import { createContext, useState } from 'react';

const SectorsContext = createContext({});

export function SectorsProvider({ children }) {
  const [sectors, setSectors] = useState([]);

  return (
    <SectorsContext.Provider value={{ sectors, setSectors }}>
      {children}
    </SectorsContext.Provider>
  );
}

export default SectorsContext;
