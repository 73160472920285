import { Link } from 'react-router-dom';
import {
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { NavbarStyles } from './NavbarStyles';
import { onKeyDownEscape } from '../utils/formHelper';
import { buildDomainPublishedPath } from '../shared/BackOffice/Buttons/BOCommonButtons';

const SubLateralProgramsMenuNavbar = ({
  menuDomainsOpen,
  isMobile,
  menuDomainsAnchorRef,
  setMenuDomainsOpen,
  closeMenu,
  domains,
}) => {
  const classes = NavbarStyles();

  const onClickAwayDomains = (event) => {
    if (
      menuDomainsAnchorRef.current &&
      menuDomainsAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setMenuDomainsOpen(false);
  };

  return (
    <Popper
      id='menuDomainsTooltip'
      open={menuDomainsOpen && !isMobile}
      anchorEl={menuDomainsAnchorRef.current}
      disablePortal
      placement='right-start'
      onKeyDown={(e) => onKeyDownEscape(e, setMenuDomainsOpen)}
    >
      {({ TransitionProps }) => (
        <Collapse in={menuDomainsOpen} {...TransitionProps}>
          <ClickAwayListener onClickAway={onClickAwayDomains}>
            <Paper elevation={0} className={classes.paperMenu}>
              <MenuList
                autoFocusItem={menuDomainsOpen}
                className={classes.expandableSubMainMenu}
                onClick={closeMenu}
              >
                {domains.map((domain) => (
                  <MenuItem
                    className={classes.mainSubMenuItemWrapper}
                    key={domain.id_domain}
                  >
                    <Link
                      to={buildDomainPublishedPath(
                        domain.id_domain,
                        domain.title
                      )}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainSubMenuItem}>
                        {domain.title}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Collapse>
      )}
    </Popper>
  );
};

export default SubLateralProgramsMenuNavbar;
