import { forwardRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      borderColor: 'currentcolor',
    },
    border: theme.shape.border,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondaryAccent.light,
    fontWeight: 'light',
    fontSize: '.85rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      textTransform: 'initial',
    },
  },
  buttonIcon: {},
}));

export const CallToActionIcon = forwardRef((props, ref) => {
  const classes = useStyles();
  // const active = !!props.active;

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Tooltip title={!props.disabled && props?.title ? props?.title : ''} arrow>
      <IconButton
        ref={ref}
        className={classnames(classes.button, ...extraClasses)}
        disableRipple
        onClick={(e) => props.onClickHandler?.(e)}
        disabled={props.disabled}
        size='large'
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
});
