import { BookmarkBorder } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Feature } from '../../../context/FeaturesContext';
import { useCurrentUserData } from '../../../hooks/useCurrentUserData';
import LoginRequiredDialog from '../../../shared/LoginRequiredDialog ';
import { CallToAction } from '../../CallToAction/CallToAction';
import { SelfTooltip } from '../../ToolTips/selfTooltip';

function ProductActionsFollow(props) {
  const { t } = useTranslation();
  const { isSelf } = props;
  const { isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const onFollow = () => {
    if (isLoggedIn) {
      //followProduct();
    } else {
      setIsLoginOpen(true);
    }
  };

  const bookmarkButton = (
    <CallToAction
      icon={<BookmarkBorder />}
      classes={props.classes}
      title={t('products.product-actions.follow', {
        productName: props.productName,
      })}
      kind='icon'
      onClickHandler={onFollow}
      disabled={isSelf}
    />
  );

  return (
    <Feature name='BOOKMARKS'>
      <SelfTooltip
        button={bookmarkButton}
        isSelf={isSelf}
        text={'artist.artist-actions.self'}
      />
      <LoginRequiredDialog
        location={window.location.pathname}
        open={isLoginOpen}
        onClose={() => setIsLoginOpen(false)}
      />
    </Feature>
  );
}

export default ProductActionsFollow;
