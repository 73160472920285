import makeStyles from '@mui/styles/makeStyles';
import { Z_INDEX } from '../../utils/Constants';
import { WIDTH_HOME_SAFEAREA } from '../../Navbar/NavbarStyles';

const leftMarginCarousel = 5 * 8 + 'px';

export const useCustomCarouselWithTextStyles = makeStyles((theme) => ({
  carouselContainer: {
    height: '100%',
    textDecoration: 'none',
    width: '100%',
  },
  sliderContainer: {
    margin: '0 auto',
    maxWidth: WIDTH_HOME_SAFEAREA,
    width: '100%',
  },
  slider: {
    alignItems: 'center',
    backgroundColor: '#333333',
    color: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: Z_INDEX.MINIMUM,
    '& .thumbs': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 0,
    },
    '& .slider-wrapper': {
      backgroundColor: 'transparent',
      minHeight: '100%',
    },
    '& .slider': {
      backgroundColor: 'transparent',
      minHeight: '100%',
    },
    '& .slide': {
      backgroundColor: 'transparent',
      minHeight: '100%',
      position: 'relative',
    },
    '& .slide.selected a': {
      float: 'left',
    },
    '& .slide img': {
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        height: 'auto',
        width: 'auto',
      },
    },
    '& .control-dots': {
      marginLeft: theme.spacing(2.5),
      '& li.dot': {
        backgroundColor: '#FFF',
        borderStyle: 'solid',
        borderWidth: '0.1px',
        height: 12,
        margin: theme.spacing(0, 1.5),
        opacity: '1',
        width: 12,
        [theme.breakpoints.down('md')]: {
          height: 8,
          margin: theme.spacing(0, 0.75),
          width: 8,
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0, 0.5),
        },
        '&:nth-of-type(1)': {
          marginLeft: leftMarginCarousel,
          [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
          },
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      '& li.dot.selected': {
        backgroundColor: '#262626',
      },
    },
  },
  sliderMultifeatured: { backgroundColor: 'white' },
  carouselImage: {
    color: '#FFF',
    height: '100%',
    position: 'relative',
    minHeight: 300,
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  arrowContainer: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.primary.white,
    display: 'flex',
    height: '100%',
    outline: 'none',
    padding: theme.spacing(1),
    position: 'absolute',
    top: '0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  'arrow-next': {
    right: theme.spacing(0),
    zIndex: Z_INDEX.MEDIUM,
  },
  'arrow-next-multiFeatured': {
    right: theme.spacing(0),
    zIndex: Z_INDEX.MEDIUM,
  },
  'arrow-prev-multiFeatured': {
    zIndex: Z_INDEX.MEDIUM,
  },
  'arrow-prev': {
    zIndex: Z_INDEX.MEDIUM,
  },
  arrowDisabled: {
    opacity: '0.5',
  },

  boxTexts: {
    width: '100%',
  },
  text: {
    alignItems: 'flex-start',
    color: '#ffffff',
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    left: leftMarginCarousel,
    padding: theme.spacing(0, 8.25, 0, 1.25),
    textAlign: 'left',
    top: 0,
    width: 'calc(50% - ' + leftMarginCarousel + ')',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
      left: 0,
      padding: theme.spacing(3, 6, 4, 6),
      position: 'relative',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 3, 4, 3),
    },
  },
  boxImage: {
    position: 'absolute',
    right: 0,
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      width: '100%',
    },
  },
  title: {
    color: 'black',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.15,
    maxWidth: '100%',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('xl')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
      whiteSpace: 'normal',
    },
  },
  description: {
    color: '#000',
    lineHeight: 1.3,
    fontSize: 16,
    fontWeight: 300,
    padding: theme.spacing(2.25, 0, 1),
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 400,
    },
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'pre-line',
    },
  },
  carouselLink: {
    border: '1px solid #262626',
    borderRadius: 15,
    display: 'inline-block',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.555, 2.5),
    textDecoration: 'none',
    '& p': {
      fontSize: 13,
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  linkText: {
    color: '#000',
  },
  owner: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '.65rem',
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },
}));
