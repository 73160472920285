import makeStyles from '@mui/styles/makeStyles';

export const useActivitiesStyles = makeStyles((theme) => ({
  containerActivities: {
    position: 'relative',
    backgroundColor: '#F4F4F4',
    maxWidth: 1360,
    padding: theme.spacing(4.5, 3, 5, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(5, 0, 5, 0),
      padding: theme.spacing(3.375, 6.25, 4.75, 6.25),
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#F2F2F2',
      margin: theme.spacing(5, 0, 5, 0),
      padding: theme.spacing(3.375, 6.25, 4.75, 6.25),
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(7, 0, 7, 0),
      padding: theme.spacing(3.375, 12.5, 4.75, 12.5),
    },
  },
  containerActivities2: {
    maxWidth: 1360,
    position: 'relative',
    backgroundColor: '#F4F4F4',
    margin: theme.spacing(0, 'auto', 0, 'auto'),
    padding: theme.spacing(4.5, 3, 5, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 'auto', 0, 'auto'),
      padding: theme.spacing(3.375, 6.25, 4.75, 6.25),
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#F2F2F2',
      margin: theme.spacing(0, 'auto', 0, 'auto'),
      padding: theme.spacing(3.375, 6.25, 4.75, 6.25),
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 'auto', 0, 'auto'),
      padding: theme.spacing(3.375, 7.875, 4.75, 7.875),
    },
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 18,
    fontWeight: 700,
    paddingBottom: theme.spacing(1.75),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      paddingBottom: theme.spacing(4),
    },
  },
  button: {
    alignItems: 'center',
    border: 'none',
    borderRadius: 20,
    color: theme.palette.dark.main,
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
    [theme.breakpoints.up('md')]: {
      border: '1px solid ',
      padding: theme.spacing(0, 2.75),
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'black',
        color: theme.palette.primary.white,
      },
    },
  },
  activityCardsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    gridGap: '20px 0px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      gridGap: '0px 20px',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '0px 32px',
    },
  },
  activityCardsWrapper2: {
    display: 'grid',
    maxWidth: 1280,
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    gridGap: '20px 0px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      gridGap: '0px 20px',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '0px 32px',
    },
  },
}));
