import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    textDecoration: 'underline',
    color: theme.palette.secondaryAccent.light,
    padding: theme.spacing(0, 2),
    fontSize: '.85rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
      fontWeight: '600',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionCancel = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
};
