import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useProfileActionsStyles } from './useProfileActionsStyles';
import AvatarProfile from './AvatarProfile';

export function ProfileActionsMyPortfolio(props) {
  const {
    hasPublishedPortfolio,
    publishedPortfolio,
    isMobile,
    showYourMicaLink = true,
  } = props;
  const classes = useProfileActionsStyles();
  const { t } = useTranslation();

  const profilePicture =
    publishedPortfolio && publishedPortfolio.image
      ? publishedPortfolio.image?.versions[0].url
      : '';

  const profileAltText =
    publishedPortfolio && publishedPortfolio.image
      ? publishedPortfolio.image?.alt_text
      : '';

  return (
    <>
      {hasPublishedPortfolio && !isMobile ? (
        <Button className={classes.buttonWithAvatar}>
          <Typography variant='body1'>
            {t('navbar.links.portfolio.seted')}
          </Typography>
          <AvatarProfile src={profilePicture} alt={profileAltText} />
        </Button>
      ) : showYourMicaLink ? (
        <Button className={classes.link}>
          <AvatarProfile src={profilePicture} alt={profileAltText} />
        </Button>
      ) : (
        <AvatarProfile src={profilePicture} alt={profileAltText} />
      )}
    </>
  );
}
