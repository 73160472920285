import { forwardRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
    color: theme.palette.secondaryAccent.light,
    fontWeight: 'light',
    fontSize: '.85rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      textTransform: 'initial',
    },
  },
  buttonIcon: {},
}));

export const CallToActionIconNotFill = forwardRef((props, ref) => {
  const classes = useStyles();
  // const active = !!props.active;

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Tooltip title={props?.title}>
      <IconButton
        ref={ref}
        className={classnames(classes.button, ...extraClasses)}
        disableRipple
        onClick={(e) => props.onClickHandler?.(e)}
        size='large'
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
});
