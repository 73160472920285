import { Backoffice, EntitiesType } from '../../../utils/Constants';
import { isObjectEmpty } from '../../../utils/formHelper';

export const buildPortfolioActionParams = (action, portoflio, comment) => {
  return {
    id_portfolio: portoflio.id_portfolio,
    action: action,
    bodyParam: comment,
  };
};
export const buildPortfolioSuspendParams = ({ history, notification }) => {
  return {
    change_log_description: {
      description: notification ? notification : '',
    },
    hidden_admin_reason: {
      description: history,
    },
  };
};
export const serviceSuspendParams = (
  action,
  published,
  comment,
  elementType
) => {
  if (EntitiesType.PORTFOLIO === elementType) {
    return {
      id_portfolio: published?.id_portfolio,
      action: action,
      bodyParam: comment,
    };
  }
  if (EntitiesType.SECTION === elementType) {
    return {
      id_section: published?.id_section,
      action: action,
      bodyParam: comment,
    };
  }
  if (EntitiesType.ARTICLE === elementType) {
    return {
      id_article: published?.id_article,
      action: action,
      bodyParam: comment,
    };
  }
};

export const buildArticleActionParams = (action, article, bodyParam) => {
  return {
    id_article: article.id_article,
    action: action,
    bodyParam: bodyParam,
  };
};

export const buildSectionActionParams = (action, section, bodyParam) => {
  return {
    id_section: section.id_section,
    action: action,
    bodyParam: bodyParam,
  };
};

export const bodyParamsSectionArticle = (comment) => {
  return {
    change_log_description: {
      description: comment ? comment : '',
    },
    hidden_admin_reason: {
      description: '',
    },
  };
};

export const buildVerifyData = (usersText) => {
  return { read_text: usersText.split('\n').join('; ') };
};

export const isPending = (status) => {
  return status === Backoffice.States.PENDING;
};

export const isPortfolioPending = (row) => {
  const portfolio = row?.portfolio;
  return isPending(portfolio?.draft?.workflow);
};

export const isPortfolioApproved = (row) => {
  const portfolio = row?.portfolio;
  return !isObjectEmpty(portfolio?.published);
};

export const shouldShowUnapprovedPortfolioButton = (row) => {
  return !isPortfolioApproved(row) && isPortfolioPending(row);
};
