import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Typography, useMediaQuery } from '@mui/material';
import { useNotesStyles } from '../../../../components/Notes/useNotesStyles';
import { theme } from '../../../../theme';
import HomeNote from '../../../../components/Notes/HomeNote';

const HomeNotes = ({ notes }) => {
  const { t } = useTranslation();
  const classes = useNotesStyles();
  const viewMore = '/notas';
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const notesToShow = isUpLg ? 3 : 2;

  return notes?.length > 0 ? (
    <section
      id='notes'
      aria-label={t('home.notes.label', {
        size: notesToShow,
      })}
    >
      <Container maxWidth={'lg'} className={classes.containerNotes}>
        <div>
          {notes.slice(0, notesToShow).map((note) => (
            <div key={note?.id_event}>
              <Divider className={classes.divider} />
              <HomeNote note={note} width='213' height='89' linkOn={true} />
            </div>
          ))}
        </div>

        <div className={classes.containerTitle}>
          <Typography
            variant='h5'
            component={'h5'}
            className={classes.titleNotes}
          >
            {t('home.notes.title')}
          </Typography>
          <Link
            className={classes.button}
            to={viewMore}
            aria-label={
              t('sectors.viewMore.more') + ' ' + t('home.notes.title')
            }
          >
            {t('sectors.viewMore.more')}
          </Link>
        </div>

        {isUpLg && (
          <img
            src={`${process.env.PUBLIC_URL}/icons/diagonal-arrow.svg`}
            alt=''
            width='16'
            height='16'
          />
        )}
      </Container>
    </section>
  ) : null;
};

export default HomeNotes;
