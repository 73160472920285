import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ImageLazy } from '../../Imagelazy/ImageLazy';
import { CallToAction } from '../../CallToAction/CallToAction';
import { addHttp } from '../../../utils/utils';
import classnames from 'classnames';
import { MaterializeCommonStyles } from '../../../MaterializeStylesCommons';
import { CreateTextLinks } from '../../../shared/CreateTextLinks';
import { Z_INDEX } from '../../../utils/Constants';
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
    },
  },
  titleNotification: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '1.12rem',
    lineHeight: '1.36rem',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.125rem',
    lineHeight: '1.56rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },
  bulkName: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  bulkBanner: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  bulkContent: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
    fontSize: '0.8rem',
    lineHeight: '1rem',
    '& p': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
  },
  dialogButton: {
    display: 'flex',
  },
  rootDialog: {
    zIndex: Z_INDEX.HIGH,
  },
}));

export const BulkNotificationContent = ({ notification, onClose }) => {
  const classes = useStyles();
  const classesCommon = MaterializeCommonStyles();
  const imgSrc = notification?.record_relation?.url_media;

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Typography
          className={classes.subtitle}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(notification?.title),
          }}
        />
        {notification?.title_notification && (
          <Typography variant='h2' className={classes.titleNotification}>
            {notification?.title_notification}
          </Typography>
        )}
        <Container
          maxWidth='md'
          disableGutters
          className={classes.roundContent}
        >
          <CreateTextLinks className={classes.roundContent}>
            {notification?.introduction}
          </CreateTextLinks>
        </Container>

        {imgSrc && (
          <ImageLazy
            src={imgSrc}
            alt={''}
            classes={classes.bulkBanner}
            width={'100%'}
          />
        )}

        {notification?.description && (
          <Container
            maxWidth='md'
            disableGutters
            className={classes.bulkContent}
          >
            <CreateTextLinks className={classes.bulkContent}>
              {notification?.description}
            </CreateTextLinks>
          </Container>
        )}
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex'
        marginTop='1rem'
      >
        {notification?.has_button && (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <a
              href={addHttp(notification?.button.link)}
              target={'_blank'}
              rel='noreferrer'
              className={classnames(
                classesCommon.noDecorationLink,
                classes.link
              )}
            >
              <CallToAction
                kind='primary'
                label={notification?.button?.text}
                classes={classes.button}
              />
            </a>
          </Box>
        )}
      </Box>
    </>
  );
};
