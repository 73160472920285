import { Box, Grid } from '@mui/material';
import { CustomIcon } from '../../../../../components/CustomIcons/CustomIcons';
import { ImageLazy } from '../../../../../components/Imagelazy/ImageLazy';
import { useSectors } from '../../../../../hooks/useSectors';
import { MULTI_FEATURED_COLOR } from '../../../../../utils/Constants';
import MultiFeaturedItemStyle from './MultiFeaturedItemStyle';
import { getSliderColorText } from '../../../../../utils/utils';

const MultiFeaturedItem = ({ item, tabIndexProp }) => {
  const classes = MultiFeaturedItemStyle();
  const { getSectorDescriptionByKey } = useSectors();
  const colorText = getSliderColorText(item?.color);
  const styleSVG = {
    stroke: colorText,
    strokeWidth: '1px',
    padding: '1px',
  };
  return (
    <a
      rel='noreferrer'
      href={item?.has_button ? item?.button?.button_link : null}
      className={classes.carouselContainer}
      target={item?.link?.target}
      tabIndex={tabIndexProp}
    >
      <Box
        className={classes.containerHighlight}
        sx={{
          backgroundColor: MULTI_FEATURED_COLOR[item?.color],
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.containerflyer}>
              <div className={classes.flyer}>
                <div className={classes.arrow}>
                  <CustomIcon
                    icon='arrow-right'
                    size={'15'}
                    color={colorText}
                    styleSVG={styleSVG}
                  />
                </div>
                <div style={{ color: colorText }} className={classes.flyerItem}>
                  {item?.flyer}
                </div>
              </div>
              {item?.sector && (
                <div
                  style={{ color: colorText, border: `1px solid ${colorText}` }}
                  className={classes.chipSector}
                >
                  {getSectorDescriptionByKey(item?.sector)}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={12}>
            {item?.image && (
              <ImageLazy
                srcVersions={item.image.versions}
                alt={item.image.alt_text ?? ''}
                height={'100%'}
                loading={tabIndexProp === 0 ? 'eager' : 'lazy'}
                float={'none'}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={12}
            className={classes.containerDescription}
          >
            <div>
              <div style={{ color: colorText }} className={classes.title}>
                {item?.title}
              </div>
              <div style={{ color: colorText }} className={classes.description}>
                {item?.description}
              </div>
            </div>
            {item?.has_button && (
              <div className={classes.containerButton}>
                <div tabIndex={-1} className={classes.buttonLink}>
                  {item?.button?.button_text}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </a>
  );
};

export default MultiFeaturedItem;
