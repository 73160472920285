import makeStyles from '@mui/styles/makeStyles';

export const useLoginStyles = makeStyles((theme) => ({
  register: {
    borderRadius: '12px',
    color: 'white',
    backgroundColor: 'transparent',
    border: '1px solid',
    textTransform: 'none',
    padding: '1px 16px',
  },
  newUserContainer: {
    backgroundColor: '#E3323D',
    display: 'flex',
    padding: '24px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  greyedContainer: {
    backgroundColor: theme.palette.notification.date,
    display: 'flex',
    padding: '24px',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  newUser: {
    color: 'white',
    fontWeight: '600',
  },
  newUserMobile: {
    color: 'white',
    fontWeight: '600',
    marginBottom: '15px',
  },
  pass: {
    textAlign: 'left',
  },
  formContainer: {
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
  },
  submitBtn: {
    margin: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  resendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    '& a': {
      color: 'black',
      marginTop: '2px',
    },
  },
}));
