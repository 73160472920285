import makeStyles from '@mui/styles/makeStyles';

export const RequiredDialogStyles = makeStyles((theme) => ({
  closeBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    width: 'fit-content',
  },
  mainContainer: {
    padding: theme.spacing(3, 7),
  },
  titleContainer: {
    textAlign: 'center',
    fontWeight: '600',
  },
  sigUpContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    padding: theme.spacing(4),
  },
  register: {
    color: 'red',
    fontWeight: '600',
    textTransform: 'capitalize',

    fontSize: '12px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  registerText: {
    fontSize: '12px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  loginBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
