import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { NavbarStyles } from './NavbarStyles';
import { CustomIcon } from '../components/CustomIcons/CustomIcons';
import classnames from 'classnames';

export const NavbarSearchIcon = ({
  setSearchOpen,
  searchOpen,
  isResultPage,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();

  const isSearchActive = () => {
    return searchOpen || isResultPage;
  };
  const iconSrc = `${process.env.PUBLIC_URL}/icons/${
    isSearchActive() ? 'LupitaBlack.svg' : 'LupaWhite.svg'
  }`;
  const size = isSearchActive() ? 33 : 22;
  return (
    <IconButton
      onClick={() =>
        isSearchActive() ? setSearchOpen(false) : setSearchOpen(true)
      }
      className={classnames(
        classes.searchIcon,
        isSearchActive() && classes.searchIconOnSearch
      )}
      aria-label={t('agenda.searchItems.search')}
      tabIndex={isSearchActive() ? -1 : undefined}
    >
      <CustomIcon iconSrc={iconSrc} size={size} />
    </IconButton>
  );
};
