import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ExpandableTextStyles } from './ExpandableTextStyles';
import { CreateTextLinks } from '../../shared/CreateTextLinks';

const MAX_INTRO_LENGTH = 400;

const ExpandableText = (props) => {
  const [expanded, setExpanded] = useState(false);
  const useExpand = props.useExpand ?? true;
  const needsExpand = props.content?.length > MAX_INTRO_LENGTH;
  const { t } = useTranslation();
  const classes = ExpandableTextStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  const getText = () => {
    return needsExpand && !expanded
      ? props.content?.slice(0, MAX_INTRO_LENGTH) + '...'
      : props.content;
  };

  return (
    <>
      <div className={extraClasses.join(' ')}>
        <CreateTextLinks className={classes.textEllipsis}>
          {getText()}
        </CreateTextLinks>
      </div>
      {needsExpand && useExpand ? (
        <Typography variant='button'>
          <Button
            onClick={() => setExpanded(!expanded)}
            className={classes.expander}
          >
            {expanded ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
            {t(
              expanded ? 'expandable-text.collapse' : 'expandable-text.expand'
            )}
          </Button>
        </Typography>
      ) : (
        ''
      )}
    </>
  );
};

export default ExpandableText;
