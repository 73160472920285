import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

//TODO: TRAER lang DEL BROWSER
function SEO({
  title,
  description,
  ogTitle = title,
  lang,
  meta,
  ogImage = '',
}) {
  const { t } = useTranslation();
  const ogDescription = description || t('description');
  const defaultTitle = process.env.REACT_APP_WEBSITE_NAME;
  const author = t('author');

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle} %s` : null}
      meta={[
        {
          name: `description`,
          content: ogDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:image:secure_url`,
          content: ogImage,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: ogTitle,
        },
        {
          name: `twitter:description`,
          content: ogDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `es-AR`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
