import { useState } from 'react';
import { Route } from 'react-router-dom';
import AppErrorBoundary from '../../components/AppErrorBoundary/AppErrorBoundary';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';
import LoginRequiredDialog from '../../shared/LoginRequiredDialog ';

export default function PrivateRoute({ children, ...rest }) {
  const { isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  const [isModalOpen, setIsModalOpen] = useState(!isLoggedIn);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          <AppErrorBoundary>{children}</AppErrorBoundary>
        ) : (
          <LoginRequiredDialog
            location={location}
            open={isModalOpen}
            onClose={toggleModal}
          />
        )
      }
    />
  );
}
