import { makeStyles } from '@mui/styles';

const MultiFeaturedItemStyle = makeStyles((theme) => ({
  containerHighlight: {
    padding: theme.spacing(6, 8, 2, 8),
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    height: 728,
    [theme.breakpoints.down('lg')]: {
      height: 400,
      padding: theme.spacing(3.5, 2.5, 6.25),
    },
    [theme.breakpoints.down('md')]: {
      height: 680,
    },
    [theme.breakpoints.down('sm')]: {
      height: 620,
      padding: theme.spacing(2.5, 3),
    },
  },
  carouselContainer: { textDecoration: 'none' },
  arrow: { alignItems: 'center', display: 'flex' },
  containerflyer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    paddingTop: 0,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(3.75),
    },
  },
  flyerItem: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 18,
    fontWeight: 700,
    paddingLeft: theme.spacing(1.125),
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  flyer: { display: 'flex' },
  chipSector: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      borderRadius: 14,
      color: theme.palette.secondaryAccent.main,
      display: 'flex',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: 600,
      height: 23,
      margin: theme.spacing(0, 0, 0, 1.25),
      minWidth: 110,
      padding: theme.spacing(0, 1.25),
      whiteSpace: 'nowrap',
    },
  },
  title: {
    paddingBottom: 16,
    color: theme.palette.secondaryAccent.main,
    display: 'flex',
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: 24,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 28,
      lineHeight: 1,
      paddingBottom: 24,
    },
  },
  buttonLink: {
    textDecoration: 'none',
    backgroundColor: '#FFF',
    borderRadius: 20,
    color: theme.palette.secondaryAccent.main,
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    padding: theme.spacing(0.625, 2.5),
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'left',
    paddingTop: 16,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 20,
    },
  },
  description: {
    color: theme.palette.secondaryAccent.main,
    display: 'flex',
    fontSize: 16,
    lineHeight: 1.25,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
  },
  containerDescription: {
    paddingTop: theme.spacing(2.5),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2.5),
      paddingTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2.5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

export default MultiFeaturedItemStyle;
