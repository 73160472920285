import makeStyles from '@mui/styles/makeStyles';
import '@fontsource/merriweather';

export const useNotesStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 860,
  },

  link: { position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 },
  noveltyCardMain: {
    position: 'relative',
    float: 'left',
    paddingBottom: '30px',
  },
  noveltyCardContent: {
    position: 'relative',
    padding: theme.spacing(0, 0, 0, 2),
  },
  notesContainer: {
    backgroundColor: '#FFF',
  },
  header: {
    display: 'flex',
    gap: theme.spacing(2.25),
    justifyContent: 'space-between',
    margin: theme.spacing(3, 2, 4, 4),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 8, 4, 12),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 8, 4, 12),
    },
  },
  noveltyImg: {
    borderRadius: '5',
  },
  textHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  buttonSelectOther: {
    border: '1px solid',
    padding: '5px 10px',
    borderRadius: 20,
  },
  titleNotesBO: { fontWeight: 700, paddingBottom: 20, paddingTop: 20 },
  containerNovelties: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
    width: 20,
  },
  volanta: {
    fontSize: 12,
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
  },
  date: {
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '44px',
    margin: theme.spacing(4, 8, 4, 12),
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      lineHeight: '27px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      margin: theme.spacing(4, 2, 4, 4),
    },
  },
  modify: {
    position: 'absolute',
    bottom: 16,
    backgroundColor: theme.palette.success.menuPortfolio,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
  },
  mainImg: {
    margin: theme.spacing(0),
    width: '100%',
  },
  description: {
    fontFamily: 'Merriweather',
    fontWeight: 400,
    fontSize: 18,
    margin: theme.spacing(5, 9, 4, 12),
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      margin: theme.spacing(5, 9, 5, 12),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 3, 6, 4),
    },
  },
  chip: {
    backgroundColor: 'rgba(224, 224, 224, 1)',
    borderRadius: 14,
    fontSize: 10,
    fontWeight: 600,
    marginTop: theme.spacing(1.25),
    padding: theme.spacing(0.1875, 1.25),
    pointerEvents: 'all',
    textAlign: 'center',
    width: 'fit-content',
  },
  text: {
    fontSize: 16,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0, 8),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 4),
      marginBottom: theme.spacing(4),
    },
  },
  dividerLine: {
    border: 'none',
    borderTop: '3px solid red ',
    float: 'left',
    margin: theme.spacing(0, 12),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    width: '5%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 4),
      marginBottom: theme.spacing(0),
    },
  },
  threeDividerLineContainer: {
    display: 'block',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bodyNote: {
    fontFamily: 'Merriweather',
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(8),
    overflowX: 'auto',
    ' & img': {
      height: 'auto',
      minWidth: '45%',
      maxWidth: '100%',
      paddingLeft: 'auto',
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(3.125),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    ' & h2': {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: 15,
        lineHeight: '22px',
      },
    },
    '& p': {
      fontFamily: 'Merriweather',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '24px',
      maxWidth: '100%',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        fontSize: 13,
        lineHeight: '18px',
      },
    },
    '& h6': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '15px',
      textAlign: 'center !important',
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        lineHeight: '12px',
      },
    },
    '& h3': {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
  },
  cardContainer: {
    alignItems: 'flex-start',
    borderTop: '1px solid #C4C4C4',
    display: 'flex',
    float: 'left',
    justifyContent: 'flex-start',
  },
  viewSector: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0),
    },
  },
  cardIntroTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cardIntroTitleMobile: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cardIntroArrowContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  arrowRight: {
    color: 'rgba(38, 38, 38, 1)',
    height: 30,
  },

  infoContainer: {
    padding: 0,
    width: '60%',
  },
  noveltyContainer: {
    paddingLeft: 0,
    paddingRight: theme.spacing(3),
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  cardIntroTitle: {
    color: 'rgba(227, 49, 61, 1)',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  cardVolanta: {
    fontSize: 12,
    lineHeight: '16.8px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.25),
  },
  cardImg: {
    borderRadius: 4,
    height: '50%',
    marginTop: theme.spacing(1.25),
    width: '100%',
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21.94px',
  },
  cardText: {
    fontFamily: 'Merriweather',
    fontSize: 13,
    fontWeight: 300,
    lineHeight: '18px',
    marginTop: theme.spacing(1),
  },
  containerNotes: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: theme.spacing(4.5, 3, 0, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 0, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7, 0, 0, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 12.5, 0, 2),
    },
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(0.75),
  },
  titleNotes: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 18,
    fontWeight: 700,
    paddingBottom: theme.spacing(1.75),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      paddingBottom: theme.spacing(3.75),
    },
  },
  button: {
    alignItems: 'center',
    border: 'none',
    borderRadius: 20,
    color: theme.palette.dark.main,
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
    [theme.breakpoints.up('md')]: {
      border: '1px solid ',
      padding: theme.spacing(0, 2.75),
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'black',
        color: theme.palette.primary.white,
      },
    },
  },
  buttonAlternative: {
    alignItems: 'center',
    backgroundColor: '#262626',
    border: '1px solid #262626',
    borderRadius: 30,
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    justifyContent: 'center',
    padding: theme.spacing(0, 2.75),
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
    '&:hover': {
      backgroundColor: 'black',
      color: theme.palette.primary.white,
    },
  },
  buttonChip: {
    textDecoration: 'none',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
  },
  divider: {
    border: '1px solid #C4C4C4',
    marginBottom: theme.spacing(1.5),
  },
  noveltyCard: {
    display: 'grid',
    gridTemplateColumns: '20px 213px 1fr',
    paddingBottom: theme.spacing(4.5),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(5.25),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },

  arrowSection: {
    alignItems: 'center',
    display: 'flex',
    height: 17,
  },
  noveltyType: {
    color: theme.palette.secondary.redSector,
    fontSize: 12,
    fontWeight: 600,
    paddingBottom: theme.spacing(1.25),
  },
  noveltyDate: {
    color: theme.palette.secondary.redSector,
    fontSize: 12,
    fontWeight: 600,
    paddingBottom: theme.spacing(1.25),
  },
  noveltyFlyer: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'uppercase',
    padding: theme.spacing(1.25, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0),
    },
  },
  noveltyTitle: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '1.2',
    paddingTop: theme.spacing(1),
    // [theme.breakpoints.up('sm')]: {
    //   padding: theme.spacing(1, 0),
    // },
  },
  noveltyTitleElipsis: {
    maxHeight: 53,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  noveltyDescription: {
    color: theme.palette.secondaryAccent.main,
    fontSize: 13,
    fontWeight: 300,
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1.5),
    },
  },
  noveltyDescriptionElipsis: {
    fontWeight: 400,
    maxHeight: 110,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical',
  },
  noveltyCardLink: {
    display: 'block',
    textDecoration: 'none',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
  },
  noveltyCardMobile: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrowSectionAndNoveltyType: {
    display: 'flex',
    gap: 8,
  },
  mobileChipWrapper: {
    paddingBottom: theme.spacing(4.5),
  },
  chipWrapper: {
    paddingBottom: theme.spacing(4.5),
  },
  noveltyImage: {
    borderRadius: 4,
    width: '100%',
  },
  notAvailableNoteWrapper: {
    width: '100%',
    maxWidth: 824,
    backgroundColor: '#fff',
    margin: theme.spacing(10, 'auto'),
    height: 190,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    padding: theme.spacing(0, 8.125, 0, 6),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  hiddenPortfolioMenuBox: {
    height: '60px',
    backgroundColor: theme.palette.success.menuPortfolio,
    display: 'flex',
    alignItems: 'center',
  },
  fancyLink: {
    textDecoration: 'none',
  },
}));
