import { Link } from 'react-router-dom';
import {
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { NavbarStyles } from './NavbarStyles';
import { onKeyDownEscape } from '../utils/formHelper';

const SubLateralCyclesMenuNavbar = ({
  menuCyclesOpen,
  isMobile,
  menuCyclesAnchorRef,
  setMenuCyclesOpen,
  closeMenu,
  cycles,
}) => {
  const classes = NavbarStyles();

  const onClickAwayCycles = (event) => {
    if (
      menuCyclesAnchorRef.current &&
      menuCyclesAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setMenuCyclesOpen(false);
  };

  return (
    <Popper
      id='menuCyclesTooltip'
      open={menuCyclesOpen && !isMobile}
      anchorEl={menuCyclesAnchorRef.current}
      disablePortal
      placement='right-start'
      onKeyDown={(e) => onKeyDownEscape(e, setMenuCyclesOpen)}
    >
      {({ TransitionProps }) => (
        <Collapse in={menuCyclesOpen} {...TransitionProps}>
          <ClickAwayListener onClickAway={onClickAwayCycles}>
            <Paper elevation={0} className={classes.paperMenu}>
              <MenuList
                autoFocusItem={menuCyclesOpen}
                className={classes.expandableSubMainMenu}
                onClick={closeMenu}
              >
                {cycles.map((cycle) => (
                  <MenuItem
                    className={classes.mainSubMenuItemWrapper}
                    key={cycle.id_cycle}
                  >
                    <Link
                      to={`/actividades/ciclos/${cycle.id_cycle}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainSubMenuItem}>
                        {cycle.title}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Collapse>
      )}
    </Popper>
  );
};

export default SubLateralCyclesMenuNavbar;
