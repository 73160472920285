import { Box } from '@mui/material';
import { atcb_action } from 'add-to-calendar-button';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT, RoundsLocation } from '../../../utils/Constants';
import { formatDate, formatDateToTime } from '../../../utils/formHelper';

const AddToCalendarDetailEvent = ({ event, classes, eventView }) => {
  const showLocation = (event, isOther) => {
    return isOther ? event?.location_desc : window?.location?.href;
  };
  const hasTurn = event?.has_a_turn;
  const titleInvite = event?.title_invite ?? event?.title;
  const isLocationOther = event?.location === RoundsLocation.OTHER;
  const location = showLocation(event, isLocationOther);
  const startTime = formatDateToTime(getDateStart(), i18next?.language);
  const endTime = formatDateToTime(getDateEnd(), i18next?.language);
  const { t } = useTranslation();
  const options = ['Google', 'Outlook.com', 'Apple'];

  const reformatDate = (date) => {
    return (
      date?.length > 0 &&
      formatDate(date, DATE_FORMAT.ADDTOCALENDAR, i18next?.language)
    );
  };
  const separator = (i) => {
    return (
      i !== options.length - 1 && (
        <div className={classes.separatorContainer}>
          <div className={classes.separator}></div>
        </div>
      )
    );
  };

  const getLabelItem = (item) => {
    let palabras = item.split('.com');
    return palabras[0];
  };

  function getDateStart() {
    return hasTurn
      ? eventView
        ? event?.participants?.[0]?.schedule_start
        : event?.schedule_start
      : event?.date_start;
  }
  function getDateEnd() {
    return hasTurn
      ? eventView
        ? event?.participants?.[0]?.schedule_end
        : event?.schedule_end
      : event?.date_end;
  }
  return (
    <div className={classes.containerAddToCalendar}>
      <hr className={classes.hr} />
      <Box className={classes.boxAddToCalendar}>
        <span>{t('rounds.addToCalendar')}</span>

        <div className={classes.atcbAction}>
          {options?.map((item, index) => (
            <div
              key={item}
              className={classes.itemCalendarContainer}
              onClick={() => {
                atcb_action({
                  name: titleInvite,
                  description: event?.short_description,
                  startDate: reformatDate(getDateStart()),
                  endDate: reformatDate(getDateEnd()),
                  startTime: startTime,
                  endTime: endTime,
                  options: [item],
                  location: location,
                  timeZone: 'America/Buenos_Aires',
                  iCalFileName: titleInvite,
                });
              }}
            >
              <div className={classes.itemCalendar}>{getLabelItem(item)}</div>
              {separator(index)}
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default AddToCalendarDetailEvent;
