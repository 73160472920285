import classNames from 'classnames';
import styles from './SkipLinks.module.css';
import SkipLink from './SkipLink';
import { useTranslation } from 'react-i18next';

export function SkipLinks({ skipLinks, className }) {
  if (!skipLinks.length) return null;

  return (
    <nav className={classNames(styles.skipLinks, className)}>
      <ul>
        {skipLinks.map(({ id, label }) => (
          <li key={id}>
            <SkipLink href={`#${id}`}>{label}</SkipLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export function MainSkipLinks({ className }) {
  const { t } = useTranslation();
  const skipLinks = [
    { id: 'main', label: t('skip-links.main') },
    {
      id: 'footer',
      label: t('skip-links.footer'),
    },
  ];

  return <SkipLinks skipLinks={skipLinks} className={className} />;
}
