import { useEffect, useRef } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classnames from 'classnames';
import ArrowLeft from '../../icons/ProductsArrowLeft.svg';
import ArrowRight from '../../icons/ProductsArrowRight.svg';
import { useSlickCarouselStyles } from './useSlickCarouselStyles';

// https://react-slick.neostack.com/docs/api
export default function SlickCarousel(props) {
  let carousel = useRef(null); // use useRef to get the carousel instance
  // const [updatedCardsData, setUpdatedCardsData] = setState(0);
  const {
    swipeable,
    draggable,
    showDots,
    autoplay,
    autoplaySpeed = 0,
    arrows,
    infinite,
    children,
    rightArrow = <CustomRightArrow />,
    leftArrow = <CustomLeftArrow />,
    centerMode = false,
    swipeToSlide = false,
    slidesToShow = 1,
    slidesToScroll = 1,
    variableWidth = true,
    rtl = false,
    className = 'slider variable-width',
    lazyLoad = false,
    handleAfterChange,
  } = props;

  const settings = {
    className: className,
    dots: showDots ?? false,
    infinite: infinite ?? true,
    draggable: draggable ?? false,
    swipe: swipeable ?? true,
    arrows: arrows ?? false,
    autoplay: autoplay ?? false,
    autoplaySpeed: autoplaySpeed,
    centerMode: centerMode,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    variableWidth: variableWidth,
    accessibility: true,
    nextArrow: rightArrow,
    prevArrow: leftArrow,
    swipeToSlide: swipeToSlide,
    rtl: rtl,
    lazyLoad: lazyLoad,
    afterChange: (index) =>
      handleAfterChange ? handleAfterChange(index) : () => {},
    // onEdge: (direction) => console.log(direction),
  };
  useEffect(() => {
    // some validation to set the slider to 0
    if (carousel && carousel?.state?.selectedItem > 0) {
      carousel.state.selectedItem = 0;
    }
  }, [children]);

  return (
    <Slider
      ref={(el) => (carousel = el)} // useRef
      {...settings}
    >
      {children}
    </Slider>
  );
}
const CustomLeftArrow = (props) => {
  const { className, onClick } = props;
  const classes = useSlickCarouselStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    !isMobile && (
      <img
        src={ArrowLeft}
        onClick={onClick}
        className={classnames(
          className,
          classes.arrowMargin,
          classes.customArrowLeft
        )}
        alt='left-arrow'
      />
    )
  );
};

const CustomRightArrow = (props) => {
  const { className, onClick } = props;
  const classes = useSlickCarouselStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    !isMobile && (
      <img
        src={ArrowRight}
        onClick={() => onClick()}
        className={classnames(
          className,
          classes.arrowMargin,
          classes.customArrowRight
        )}
        alt='right-arrow'
      />
    )
  );
};
