import { Route } from 'react-router-dom';
import AppErrorBoundary from '../../components/AppErrorBoundary/AppErrorBoundary';
import { useCurrentUserData } from '../../hooks/useCurrentUserData';

export default function PublicRoute({ children, isLoading, ...rest }) {
  const { isLoadingTokenUser } = useCurrentUserData();

  return (
    <Route
      {...rest}
      render={() =>
        !isLoadingTokenUser && <AppErrorBoundary>{children}</AppErrorBoundary>
      }
    />
  );
}
