import { FavoriteBorderTwoTone } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CallToAction } from '../../CallToAction/CallToAction';
import { Feature } from '../../../context/FeaturesContext';
import { SelfTooltip } from '../../ToolTips/selfTooltip';
import LoginRequiredDialog from '../../../shared/LoginRequiredDialog ';
import { useCurrentUserData } from '../../../hooks/useCurrentUserData';

function ProductActionsLike(props) {
  const { t } = useTranslation();
  const { classes, isSelf } = props;
  const { isUserLoggedIn } = useCurrentUserData();
  const isLoggedIn = isUserLoggedIn();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const extraClasses = Array.isArray(props) ? classes : [classes];

  const onLike = () => {
    if (isLoggedIn) {
      //likeProduct();
    } else {
      setIsLoginOpen(true);
    }
  };

  const bookmarkLike = (
    <CallToAction
      icon={<FavoriteBorderTwoTone />}
      title={t('products.product-actions.like', {
        productName: props.productName,
      })}
      classes={extraClasses}
      kind='icon'
      disabled={isSelf}
      onClickHandler={onLike}
    />
  );

  return (
    <Feature name='LIKE'>
      <SelfTooltip
        button={bookmarkLike}
        isSelf={isSelf}
        text={'artist.artist-actions.self'}
      />
      <LoginRequiredDialog
        location={window.location.pathname}
        open={isLoginOpen}
        onClose={() => setIsLoginOpen(false)}
      />
    </Feature>
  );
}

export default ProductActionsLike;
