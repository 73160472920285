import qs from 'qs';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';
import decode from 'jwt-decode';
import UserService from './UserService';
import { SpecialsRole } from '../utils/Constants';

const AUTH_API_URL = '/auth/';
// LocalStorage keys
const AUTH_TOKEN = 'authToken';
const AUTH_USER = 'userInfo';
const REFERER = 'referer';
// keys de permisos
const BACKOFFICE_ACCESS = 'backoffice:access';

export const storage =
  window && window?.localStorage && typeof window?.localStorage !== 'undefined'
    ? window?.localStorage
    : null;
class AuthService {
  //registro
  register(newUserData) {
    return axiosInstanceWithInterceptors.post(AUTH_API_URL, newUserData);
  }
  getSectorsOptions() {
    return axiosInstanceWithInterceptors.get(AUTH_API_URL + 'sectors');
  }
  //blanquear-clave
  resetPassword(username) {
    return axiosInstanceWithInterceptors.post(
      AUTH_API_URL + 'forgot_password',
      username
    );
  }
  getStatus() {
    return axiosInstanceWithInterceptors.get(AUTH_API_URL + 'status/all/');
  }
  //registro/validado/:token
  confirmEmail(token) {
    return axiosInstanceWithInterceptors.patch(
      AUTH_API_URL + 'confirm_email/' + token
    );
  }
  resendConfirmEmail(username) {
    return axiosInstanceWithInterceptors.post(
      AUTH_API_URL + 'resend_register_email',
      username
    );
  }

  //blanquear-clave/validado/:token
  newPassword(password, token) {
    return axiosInstanceWithInterceptors.patch(
      AUTH_API_URL + 'change_password/' + token,
      password
    );
  }

  getToken = (credentials) => {
    const formData = qs.stringify({
      username: credentials.username,
      password: credentials.password,
    });
    return axiosInstanceWithInterceptors.post(AUTH_API_URL + 'token', formData);
  };

  refreshToken() {
    const token = this.getAuthToken();
    if (token === null) {
      return Promise.reject('no hay token pa refrescar');
    }
    return axiosInstanceWithInterceptors.post(
      AUTH_API_URL + 'refresh_token',
      token
    );
  }

  getAnonymousToken = async () => {
    return await axiosInstanceWithInterceptors.get(
      `${AUTH_API_URL}anonymous/token`
    );
  };

  getOkForPublicMeet = async (token) => {
    return await axiosInstanceWithInterceptors.get(
      `${AUTH_API_URL}meet/${token}`
    );
  };

  postGuestNameForPublicMeet = async (token, bodyData) => {
    return await axiosInstanceWithInterceptors.post(
      `${AUTH_API_URL}meet/${token}`,
      bodyData
    );
  };

  postContactForm = async (body) => {
    const route = AUTH_API_URL + 'form/contact';
    return await axiosInstanceWithInterceptors.post(route, body);
  };

  //ingreso
  login = (credentials) => {
    return this.getTokenAndSaveInLocalStorage(credentials).then(() => {
      return this.getCurrentUserAndSaveInLocalStorage();
    });
  };

  getTokenAndSaveInLocalStorage = (credentials) => {
    return this.getToken(credentials).then((tokenResponse) => {
      const { data } = tokenResponse;
      const { access_token } = data;
      if (!this.isAuthenticatedToken(access_token)) {
        return false;
      }
      this.saveAuthToken(data);
    });
  };

  isAuthenticatedToken = (token) => {
    return (
      !!token && this.isTokenFormatValid(token) && !this.isTokenAnonymous(token)
    );
  };

  getCurrentUserAndSaveInLocalStorage = async () => {
    return await UserService.getCurrentUser().then((userInfoResponse) => {
      const { data } = userInfoResponse;
      const { permissions } = data;
      if (!permissions) {
        return false;
      }
      this.saveUserInfo(data);
      return userInfoResponse;
    });
  };

  saveUserInfo = (userInfo) => {
    try {
      storage.setItem(AUTH_USER, JSON.stringify(userInfo));
    } catch (e) {
      return false;
    }
  };

  saveReferer = (referer) => {
    try {
      sessionStorage.setItem(REFERER, referer);
    } catch (e) {
      return false;
    }
  };

  getReferer() {
    try {
      const referer = sessionStorage.getItem(REFERER);
      return referer ? referer : '';
    } catch (e) {
      return false;
    }
  }

  getUserInfo() {
    try {
      const authUser = JSON.parse(storage.getItem(AUTH_USER));
      return authUser ? authUser : '';
    } catch (e) {
      return false;
    }
  }

  getUsername() {
    const { username } = this.getUserInfo();
    return username ? username : '';
  }

  getUserId() {
    const { id_user } = this.getUserInfo();
    return id_user ? id_user : '';
  }

  canAccessToBackoffice() {
    return this.hasPermission(BACKOFFICE_ACCESS);
  }

  hasPermission(permission) {
    try {
      const { permissions } = this.getUserInfo();
      return permissions ? permissions.includes(permission) : false;
    } catch (e) {
      return false;
    }
  }

  hasRole(role) {
    const { roles } = this.getUserInfo();
    return roles ? roles.includes(role) : false;
  }

  getCurrentUserRoles() {
    const { roles } = this.getUserInfo();
    return roles ? roles : [];
  }

  getSectors() {
    const { sectors } = this.getUserInfo();
    return sectors ? sectors : [];
  }
  isAuthenticated = () => {
    const token = this.getAccessToken();
    return this.isAuthenticatedToken(token);
  };

  isTokenFormatValid = (token) => {
    return this.getUsernameFromToken(token) === false ? false : true;
  };

  isTokenAnonymous = (token) => {
    const { scopes } = this.decodeToken(token);
    const hasAnonymousRole = scopes.includes(SpecialsRole.ANONYMOUS);
    return hasAnonymousRole;
  };

  decodeToken = (token) => {
    try {
      return decode(token);
    } catch (error) {
      console.error('Formato de token inváido: subject', error);
      return false;
    }
  };

  getUsernameFromToken = (token) => {
    try {
      const { sub } = decode(token);
      return sub;
    } catch (error) {
      console.error('Formato de token inváido: subject', error);
      return false;
    }
  };

  saveAuthToken(authToken) {
    try {
      storage.setItem(AUTH_TOKEN, JSON.stringify(authToken));
    } catch (e) {
      return false;
    }
  }

  getAuthToken() {
    try {
      const authToken = JSON.parse(storage.getItem(AUTH_TOKEN));
      return authToken ? authToken : '';
    } catch (e) {
      return false;
    }
  }

  getAuthHeader() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    const referer = this.getReferer();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
          'mica-referer': referer,
        },
      };
    }
    return null;
  }

  getAuthHeaderBlob() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
      };
    }
    return null;
  }

  getAuthorizationHeader() {
    const response = this.getAuthHeader()?.headers['Authorization'];
    return response;
  }

  getAccessToken() {
    return this.getAuthToken() && this.getAuthToken().access_token
      ? this.getAuthToken().access_token
      : null;
  }
  getTypeToken() {
    return this.getAuthToken() && this.getAuthToken().token_type
      ? this.getAuthToken().token_type
      : null;
  }

  logout() {
    try {
      storage.removeItem(AUTH_TOKEN);
      storage.removeItem(AUTH_USER);
    } catch (e) {
      return false;
    }
  }

  getUserData() {
    const userInfo = this.getUserInfo();
    let userData = userInfo === '' ? {} : userInfo;
    userData.isLoggedIn = this.isAuthenticated();
    return userData;
  }

  isLoggedUser = (idUser) => {
    return idUser === String(this.getUserId());
  };

  getAndSaveAnonymousToken = async () => {
    try {
      const { data } = await this.getAnonymousToken();
      await this.saveAuthToken(data);
      return data;
    } catch (error) {
      console.error('getAnonymousToken response', error);
    }
  };
  
  getDevelopers = async() => {
    const route = AUTH_API_URL + 'developers';
    return await axiosInstanceWithInterceptors.get(route);
  }
}

// eslint-disable-next-line
export default new AuthService();
