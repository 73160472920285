import {
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SECTOR_PAGES } from '../utils/Constants';
import { onKeyDownEscape } from '../utils/formHelper';
import { NavbarStyles } from './NavbarStyles';

const SubLateralSectorsMenuNavbar = ({
  menuSectorsOpen,
  isMobile,
  menuSectorsAnchorRef,
  setMenuSectorsOpen,
  closeMenu,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();

  const onClickAwaySectors = (event) => {
    if (
      menuSectorsAnchorRef.current &&
      menuSectorsAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setMenuSectorsOpen(false);
  };

  return (
    <Popper
      id='menuSectorsTooltip'
      open={menuSectorsOpen && !isMobile}
      anchorEl={menuSectorsAnchorRef.current}
      disablePortal
      placement='right-start'
      onKeyDown={(e) => onKeyDownEscape(e, setMenuSectorsOpen)}
    >
      {({ TransitionProps }) => (
        <Collapse in={menuSectorsOpen} {...TransitionProps}>
          <ClickAwayListener onClickAway={onClickAwaySectors}>
            <Paper elevation={0} className={classes.paperMenu}>
              <MenuList
                autoFocusItem={menuSectorsOpen}
                className={classes.expandableSubMainMenu}
                onClick={closeMenu}
              >
                {Object.keys(SECTOR_PAGES).map((e) => (
                  <MenuItem className={classes.mainSubMenuItemWrapper} key={e}>
                    <Link
                      to={`/sector/${e}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainSubMenuItem}>
                        {t(`backoffice.sectors.options.${e}`)}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </ClickAwayListener>
        </Collapse>
      )}
    </Popper>
  );
};

export default SubLateralSectorsMenuNavbar;
