import { Box, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  ArticleType,
  Backoffice,
  EntitiesType,
} from '../../../utils/Constants';
import { CallToAction } from '../../CallToAction/CallToAction';
import { CreateTextLinks } from '../../../shared/CreateTextLinks';
import { useNotificationTabStyles } from '../useNotificationTabStyles';
import AuthService from '../../../services/AuthService';

export const TipsNotification = ({ notification, firstName, onClose }) => {
  const entityName = notification.record_relation?.name;
  const workflow = notification.record_relation?.workflow;
  const hiddenPortfolio = notification.record_relation?.hidden;

  const isHiddenPortfolio = () => {
    return hiddenPortfolio === true
      ? t('messages.notifications.dialogs.tip.congratsHidden')
      : t('messages.notifications.dialogs.tip.congrats');
  };
  const imgSrc = notification.record_relation?.url_media;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useNotificationTabStyles();
  const history = useHistory();
  const type = notification.record_relation?.element_type;
  const id = notification.record_relation?.id;
  const idUser = AuthService.getUserInfo()?.id_user;
  const comment = notification?.record_relation?.tip_type;
  const articleType = notification?.record_relation?.article_type;
  const articleTypeUrl =
    articleType === ArticleType.PRODUCT ? 'producto' : 'servicio';

  const goToApproved = () => {
    let url = '';
    switch (type) {
      case EntitiesType.PORTFOLIO:
      default:
        url = '/usuario/' + id;
        break;
      case EntitiesType.SECTION:
        url = '/tu-mica/portfolio/seccion/' + id + '/previsualizacion';
        break;
      case EntitiesType.ARTICLE:
        url = '/usuario/' + idUser + '/catalogo/articulo/' + id;
        break;
    }
    onClose();
    history.push(url);
  };
  const goToEdit = () => {
    let url = '';
    switch (type) {
      case EntitiesType.PORTFOLIO:
      default:
        url = '/tu-mica/portfolio/bio';
        break;
      case EntitiesType.SECTION:
        url = '/tu-mica/portfolio/seccion/' + id;
        break;
      case EntitiesType.ARTICLE:
        url = '/tu-mica/catalogo/' + articleTypeUrl + '/' + id;
        break;
    }
    onClose();
    history.push(url);
  };

  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Typography variant='body1'>
        {t('messages.notifications.dialogs.tip.welcome', {
          firstName: firstName,
        })}
        <br />
        {workflow === Backoffice.States.PUBLISHED
          ? isHiddenPortfolio()
          : t('messages.notifications.dialogs.tip.review')}
      </Typography>
      <Box
        display='flex'
        alignItems='center'
        style={{ margin: theme.spacing(2, 0) }}
      >
        <img
          src={imgSrc}
          alt={''}
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        />
        <Box
          display='flex'
          flexDirection='column'
          style={{ marginLeft: theme.spacing(3) }}
        >
          <Typography variant='body1'>{entityName}</Typography>
        </Box>
      </Box>
      <br />
      {comment && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          className={classes.commentBox}
        >
          <CreateTextLinks variant='body1' className={classes.commentText}>
            {comment}
          </CreateTextLinks>
        </Box>
      )}
      <Box
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
        className={classes.buttonBox}
      >
        {workflow === Backoffice.States.PUBLISHED ? (
          <CallToAction
            kind='primary'
            onClickHandler={goToApproved}
            classes={[classes.dialogButton]}
            label={t('messages.notifications.dialogs.tip.view-ok-version')}
          />
        ) : (
          <CallToAction
            kind='primary'
            onClickHandler={goToEdit}
            classes={[classes.dialogButton]}
            label={t('messages.notifications.dialogs.tip.edit')}
          />
        )}
      </Box>
    </Container>
  );
};
