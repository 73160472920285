import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  skipLink: {
    position: 'absolute',
    left: -100000,
    top: 0,
    background: 'white',
    color: 'black',
    width: '100%',
    textAlign: 'center',
    padding: '4px',
    border: '1px solid black',
    overflow: 'hidden',
    '&:focus': {
      left: 0,
      zIndex: 5000,
      overflow: 'visible',
    },
  },
}));

export default function SkipLink({ children, href }) {
  const classes = useStyles();

  return (
    <a href={href} className={classes.skipLink}>
      {children}
    </a>
  );
}
