import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { CallToAction } from '../components/CallToAction/CallToAction';
import { useLoginContext } from '../context/LoginContext';
import { RequiredDialogStyles } from '../components/Rounds/Styles/RequiredDialogStyles';

const LoginRequiredDialog = (props) => {
  const { open, onClose, location } = props;
  const history = useHistory();
  const classes = RequiredDialogStyles();
  const { t } = useTranslation();
  const { setLoginData } = useLoginContext();

  const handleClose = () => {
    onClose();
  };

  const logInCloseDialog = () => {
    onClose();
    setLoginData({ isOpen: true, previousLocation: location });
  };

  const goToRegister = () => {
    history.push('/registro');
  };

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='dialog-title'
      open={open}
      maxWidth='xs'
    >
      <div className={classes.closeBtnContainer}>
        <IconButton
          onClick={handleClose}
          className={classes.closeBtn}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent className={classes.mainContainer}>
        <Typography id='dialog-title' className={classes.titleContainer}>
          {t('artist.loginRequiredDialog.title')}
        </Typography>
      </DialogContent>
      <Container className={classes.loginBtn}>
        <CallToAction
          kind='primary'
          classes={classes.secondarySubmit}
          onClickHandler={logInCloseDialog}
          label={t('artist.loginRequiredDialog.loginBtn')}
        />
      </Container>
      <Container className={classes.sigUpContainer}>
        <Typography component='p' className={classes.registerText}>
          {t('artist.loginRequiredDialog.noAccount')}
        </Typography>
        <Button className={classes.register} onClick={goToRegister}>
          {t('artist.loginRequiredDialog.sigUp')}
        </Button>
      </Container>
    </Dialog>
  );
};
export default LoginRequiredDialog;
