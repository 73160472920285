import { CustomIcon } from '../CustomIcons/CustomIcons';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import ArtistCategorySearch from '../Artist/ArtisCategorySearch';
import { useTranslation } from 'react-i18next';

import { SearchEntitiesType } from '../../utils/Constants';

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    width: '100%',
    margin: theme.spacing(1, 0),
    textAlign: 'center',
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  itemContainerGrey: {
    backgroundColor: theme.palette.primary.light,
  },
  itemContainerTransparent: {
    backgroundColor: 'transparent',
  },
  header: {
    justifyContent: 'space-between !important',
    alignItems: 'center',
    padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    margin: '0',
  },
  dotHeader: {
    justifyContent: 'space-between !important',
    alignItems: 'center',
    padding: theme.spacing(2, 0, 2, 0),
    margin: '0',
  },
  itemType: {
    display: 'flex',
    alignItems: 'center',
  },
  itemTypeText: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgba(83, 83, 83, 1)',
    textTransform: 'capitalize',
  },
  itemTypeTextPadding: {
    marginLeft: '5px',
  },
  customIcon: {
    paddingTop: '0px',
  },
  dotIcon: {
    paddingRight: '5px',
    maxWidth: '24px',
  },
  marker: {
    width: '14px',
    height: '14px',
    borderRadius: '100%',
    minWidth: '6px',
  },
  markerRed: {
    backgroundColor: 'rgba(255, 36, 112, 1)',
  },
  markerMicaRed: {
    backgroundColor: 'rgba(227, 49, 61, 1)',
  },
  markerBlue: {
    backgroundColor: 'rgba(28, 107, 250, 1)',
  },
  markerOrange: {
    backgroundColor: 'rgba(252, 89, 69, 1)',
  },
  markerYellow: {
    backgroundColor: 'rgba(236, 164, 0, 1)',
  },
}));

export default function CardHeader(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sector, type, typeService, typeActivity } = props;

  const isCardStyleDot = (type) => {
    if (
      type === SearchEntitiesType.ACTIVITIES ||
      type === SearchEntitiesType.NOTES
    ) {
      return true;
    } else return false;
  };

  const iconToShow = (type) => {
    switch (type) {
      case SearchEntitiesType.PORTFOLIOS:
        return (
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/Profileicon.svg`}
            color='transparent'
            size={18}
          />
        );
      case SearchEntitiesType.SERVICES:
        return (
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/Serviceicon.svg`}
            color='transparent'
            size={18}
          />
        );

      case SearchEntitiesType.SECTIONS:
        return (
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/Sectionicon.svg`}
            color='transparent'
            size={18}
          />
        );
      case SearchEntitiesType.PRODUCTS:
        return (
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/Producticon.svg`}
            color='transparent'
            size={18}
          />
        );
      case SearchEntitiesType.ACTIVITIES:
        return (
          <div
            className={classnames(
              classes.marker,
              typeService === 'streaming'
                ? classes.markerBlue
                : classes.markerRed
            )}
          ></div>
        );
      case SearchEntitiesType.NOTES:
        return (
          <div
            className={classnames(classes.marker, classes.markerOrange)}
          ></div>
        );
      case 'streaming':
        return (
          <div className={classnames(classes.marker, classes.markerBlue)}></div>
        );
      case 'round':
        return (
          <div className={classnames(classes.marker, classes.markerRed)}></div>
        );
      case 'meeting':
        return (
          <div className={classnames(classes.marker, classes.markerRed)}></div>
        );
      case 'cycle':
        return (
          <div className={classnames(classes.marker, classes.markerBlue)}></div>
        );
      case 'node':
        return (
          <div
            className={classnames(classes.marker, classes.markerYellow)}
          ></div>
        );
      case 'page':
        return (
          <div
            className={classnames(classes.marker, classes.markerMicaRed)}
          ></div>
        );
      case 'domain':
        return (
          <div
            className={classnames(classes.marker, classes.markerYellow)}
          ></div>
        );
      case 'sector':
        return (
          <div
            className={classnames(classes.marker, classes.markerMicaRed)}
          ></div>
        );

      default:
        <div></div>;
    }
  };

  return (
    <>
      <div
        className={classnames(
          classes.itemContainer,
          classes.itemContainerTransparent,
          isCardStyleDot(type ? type : typeService) || typeActivity
            ? classes.dotHeader
            : classes.header
        )}
      >
        <div className={classes.itemType}>
          <span
            className={
              isCardStyleDot(type) ? classes.dotIcon : classes.customIcon
            }
          >
            {iconToShow(type, typeService)}
          </span>
          <span
            className={classnames(
              classes.itemTypeText,
              type !== 'activity' &&
                type !== 'novelty' &&
                classes.itemTypeTextPadding
            )}
          >
            {t(
              `navbar.search.entity-type.${
                type ===
                (SearchEntitiesType.ACTIVITIES ||
                  SearchEntitiesType.SECTOR ||
                  SearchEntitiesType.CYCLE ||
                  SearchEntitiesType.DOMAIN ||
                  SearchEntitiesType.NODE ||
                  SearchEntitiesType.PAGE ||
                  SearchEntitiesType.NODE ||
                  SearchEntitiesType.SECTOR ||
                  SearchEntitiesType.CYCLE ||
                  SearchEntitiesType.DOMAIN)
                  ? typeService
                  : typeService
                  ? type
                  : typeActivity
              }`
            )}
          </span>
        </div>
        <ArtistCategorySearch sector={sector} simple />
      </div>
    </>
  );
}
