import makeStyles from '@mui/styles/makeStyles';

export const useSectorStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: '#fff',
    paddingBottom: theme.spacing(5),
  },
  containerActivities: {
    backgroundColor: theme.palette.messages.background,
    padding: theme.spacing(2, 0),
  },
  viewButton: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 700,
  },
  viewDisabledButton: {
    color: theme.palette.notification.date,
    textDecoration: 'underline',
  },
  inputSearch: { width: '100%', paddingTop: theme.spacing(6) },
  imageContainer: {
    position: 'relative',
    height: '350px',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      height: '29vw',
    },
    [theme.breakpoints.down('sm')]: {
      height: '50vw',
    },
  },
  viewPortfolio: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  image: {
    height: '100%',
    width: '100%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'cover',
    },
  },
  titleContainer: {
    position: 'absolute',
    left: 0,
    width: '60%',
    bottom: '-15px',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('md')]: {
      bottom: '-1.5vw',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '-1.7vw',
      width: '100%',
      padding: theme.spacing(0, 3),
    },
  },
  title: {
    fontSize: '7vw',
    fontWeight: '900',
    color: '#FAFAFA',
    lineHeight: '1',
    [theme.breakpoints.up('xl')]: {
      fontSize: '100px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '10vw',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11vw',
    },
  },

  descriptionLarge: {
    paddingTop: theme.spacing(3),

    textAlign: 'left',
    fontSize: '18px',
    width: '100%',
    columns: '20px 2',
    columnGap: '20px',
  },
  description: {
    paddingTop: theme.spacing(3),
    textAlign: 'left',
    fontSize: '18px',
    marginTop: '15px',
  },
  subTitle: {
    paddingTop: theme.spacing(5),
    fontSize: '24px',
    fontWeight: '700',
    width: '100%',
  },
  carrouselActivities: {
    paddingTop: theme.spacing(2),
  },

  resultContentCards: {
    width: 'inherit',
    height: 'fit-content',
  },
  containerPage: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  cardsContainer: {
    marginBottom: theme.spacing(3),
  },
  resultContent: {
    padding: theme.spacing(4, 0, 0, 0),
    height: 'fit-content',
    width: '100%',
  },
  hiddeFooterBox: { marginTop: theme.spacing(6) },

  footerContainer: {
    height: 'auto',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    margin: 0,
  },
  footerContentContainer: {
    display: 'flex',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3vw',
    },
  },
  sumateContainer: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
    },
  },
  footerTitle: {
    fontSize: '38px',
    lineHeight: '46px',
    fontWeight: '600',
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
      lineHeight: '36.57px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '7vw',
    },
  },
  footerDescription: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19.5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3vw',
    },
  },
  footerArrowedList: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '36.3px',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5vw',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '20%',
    },
  },

  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'white',
    width: '200px',
    height: '45px',
    color: 'black',
    borderRadius: '26px',
    marginRight: theme.spacing(6),
    transition: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
    '& span': {
      fontSize: 13,
      fontWeight: 600,
    },
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontStyle: 'SemiBold',
    fontSize: ' 20px',
    lineHeight: ' 24px',
  },

  searchContainer: {
    width: '100%',
    height: '66px',
    backgroundColor: theme.palette.primary.darkGrey,
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 0, 4, 0),
  },
  search: {
    position: 'relative',
    backgroundColor: theme.palette.primary.darkGrey,
    width: '100%',
    paddingLeft: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '.85rem',
    padding: theme.spacing(0),
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '12px',
    },
  },
  searchIcon: {
    cursor: 'pointer',
    paddingRight: theme.spacing(1),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  inputRoot: {
    color: 'white',
    width: '100%',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  inputInput: {
    '&::placeholder': {
      color: 'white',
      opacity: '1',
      fontWeight: '600',
    },
    transition: theme.transitions.create('width'),
  },
  showMoreIcon: {
    marginTop: '-200px',
    backgroundColor: '#fff',
    width: '100%',
    boxShadow: '-2px -150px 150px 100px #fff',
    zIndex: 1,
  },
  iconNext: { paddingBottom: '50px' },
  list: {
    listStyleType: 'none',

    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  gridContainerListFooter: {
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  gridList: {
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%',
    },
  },
  listArrows: {
    color: 'black',
    width: '20px',
    height: '20px',
  },
}));
