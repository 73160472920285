import { Typography } from '@mui/material';
import { GoHome } from '../components/GoHome/GoHome';
import { useTranslation } from 'react-i18next';
import { useFooterStyles } from '../components/Footer/FooterStyles';

export const MICALogo = (props) => {
  const { hasDetail } = props;
  const { t } = useTranslation();
  const classes = useFooterStyles();
  return (
    <GoHome
      className={props.inNavbar ? classes.micaLogoNoPadding : classes.micaLogo}
    >
      {hasDetail && (
        <div style={{ width: 137 }} className={classes.micaTextWrapper}>
          <Typography className={classes.micaText}>
            {t('footer.mica')}
          </Typography>
        </div>
      )}
    </GoHome>
  );
};
