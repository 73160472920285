import { Button, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create(['color', 'border-color']),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.main,
      backgroundColor: theme.palette.primary.light,
    },
    color: theme.palette.secondaryAccent.light,
    borderColor: theme.palette.secondaryAccent.light,
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.artistActionButtons.borderRadius20,
    fontSize: '.85rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionWithIcon = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Tooltip title={!props.disabled && props?.title ? props?.title : ''}>
      <Button
        startIcon={props.icon}
        ref={props.forwardedRef}
        className={[classes.button, extraClasses].join(' ')}
        onClick={(e) => props.onClickHandler?.(e)}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      >
        <Typography variant='button'>{props.label}</Typography>
      </Button>
    </Tooltip>
  );
};
