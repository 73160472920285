import {
  getBasicDomainExportFilters,
  getBasicDomainExportFiltersWithID,
  getBasicDomainFilters,
  getBasicDomainNodesFilters,
  getPlotFiltersRegister,
  getRegistersDetailFilters,
  getRegistersStatsFilters,
} from '../utils/apiHelper';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';
import { getPlotFilters } from '../utils/apiHelper';
import AuthService from './AuthService';
import moment from 'moment';

const API_URL = '/statistics/';

class StatisticsService {
  sendHit(params) {
    return axiosInstanceWithInterceptors.post(API_URL + 'hits/', params);
  }

  fetchRegistersStatsByFilters(params) {
    let filters = getRegistersStatsFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'plots/registers?' + filters
    );
  }
  fetchRegistersStatsDetailByFilters(params) {
    let filters = getRegistersDetailFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'plots/counts?' + filters
    );
  }

  getSessionPlots = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/session' + filters
    );
  };

  getAccountsPlots = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/accounts' + filters
    );
  };

  getPagesMostVisited = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/pages/most-visited' + filters
    );
  };
  getShows = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/shows' + filters
    );
  };

  getReferer = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/origin' + filters
    );
  };
  getAccess = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/access' + filters
    );
  };
  getEgress = async (params) => {
    let filters = getPlotFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'plots/egress' + filters
    );
  };

  getExcelSession(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/session' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelAccounts(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/accounts' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelRegister(params) {
    let filters = getPlotFiltersRegister(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/registers' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelAccess(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/access' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelEgress(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/egress' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelPagesTable(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/pages/most-visited' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelReferer(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/origin' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelShows(params) {
    let filters = getPlotFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/shows' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelDomain(params) {
    let filters = getBasicDomainExportFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/domains' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
  getExcelNode(params) {
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/node/' + params.id,
      AuthService.getAuthHeaderBlob()
    );
  }

  getExcelNodeViews(params) {
    let filters = getBasicDomainExportFiltersWithID(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/nodes/count/' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getAllExcelNodeViews(params) {
    let filters = '?';
    filters += params?.id_domain ? `id_domain=${params.id_domain}` : '';
    filters += params.date_start_filter
      ? '&date_start_filter=' +
        encodeURIComponent(moment(params.date_start_filter).format())
      : '';
    filters += params.date_end_filter
      ? '&date_end_filter=' +
        encodeURIComponent(moment(params.date_end_filter).format())
      : '';
    filters += params.domain_filter
      ? '&domain_filter=' + params.domain_filter
      : '';
    filters += params.date_group ? '&date_group=' + params.date_group : '';
    return axiosInstanceWithInterceptors.get(
      API_URL + 'export/domains/count' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getFollowersCount = async (params) => {
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/counts/followers/' + params.id
    );
  };
  getFollowersCountNode = async (idDomain, idNode) => {
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/counts/followers/' + idDomain + '?id_node=' + idNode
    );
  };
  getNodesCount = async (params) => {
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/counts/' + params.id
    );
  };
  getDomainCount = async () => {
    return await axiosInstanceWithInterceptors.get(API_URL + 'domain/counts');
  };
  getDomainCountNodes = async () => {
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/counts/nodes'
    );
  };
  getDomainCountViews = async (params) => {
    let filters = getBasicDomainFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/count/views' + filters
    );
  };
  getDomainCountTotal = async (params) => {
    let filters = getBasicDomainFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL + 'domain/count/total/' + params?.id_domain + filters
    );
  };
  getDomainCountViewsWithId = async (params) => {
    let filters = getBasicDomainFilters(params);
    return await axiosInstanceWithInterceptors.get(
      API_URL +
        'domain/count/views' +
        (params?.id_domain ? `?domain_filter=${params?.id_domain}` : '?') +
        filters
    );
  };

  getDomainViews(params) {
    let filters = getBasicDomainFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'domain/views' + filters
    );
  }
  getDomainNode(params) {
    let filters = getBasicDomainNodesFilters(params);
    return axiosInstanceWithInterceptors.get(
      API_URL + 'domain/node/' + filters
    );
  }
}

// eslint-disable-next-line
export default new StatisticsService();
