import { getUserFilters, getParticipantFilters } from '../utils/apiHelper';
import AuthService from './AuthService';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';

const USER_API_URL = '/users/';

class UserService {
  //para el login
  getCurrentUser() {
    return axiosInstanceWithInterceptors.get(USER_API_URL + 'current');
  }

  cacheUserDelete() {
    return axiosInstanceWithInterceptors.delete(USER_API_URL + 'cache/delete');
  }

  fetchUserByFilters(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(USER_API_URL + '?' + filters);
  }

  fetchUsersByRole(role) {
    return axiosInstanceWithInterceptors.get(
      USER_API_URL + 'autocomplete_roles?role_filter=' + role
    );
  }

  fetchParticipantByFilters = async (params) => {
    let filters = getParticipantFilters(params);
    return await axiosInstanceWithInterceptors.get(
      USER_API_URL + '?' + filters
    );
  };

  editUser(user) {
    return axiosInstanceWithInterceptors.patch(
      USER_API_URL + user.id_user,
      user
    );
  }

  editRoles(idUser, formValues) {
    return axiosInstanceWithInterceptors.post(
      USER_API_URL + 'roles/' + idUser,
      formValues
    );
  }

  setUserActiveState(id_user, isActive) {
    return axiosInstanceWithInterceptors.patch(
      USER_API_URL + 'inactivate/' + id_user,
      {
        active: !isActive,
      }
    );
  }

  addUser(user) {
    return axiosInstanceWithInterceptors.post(USER_API_URL, user);
  }

  //(no se usa aún) usernames por lista
  listMassiveValidateUsernames(data) {
    return axiosInstanceWithInterceptors.post(
      USER_API_URL + 'validate/list/username',
      data
    );
  }

  //usernames por string
  massiveValidateUsernames(data) {
    return axiosInstanceWithInterceptors.put(
      USER_API_URL + 'validate/usernames',
      data
    );
  }
  getAvailablesRoles() {
    return axiosInstanceWithInterceptors.get(USER_API_URL + 'roles');
  }

  getRolesGuest() {
    return axiosInstanceWithInterceptors.get(USER_API_URL + 'roles/guest');
  }

  getExcel(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      `${USER_API_URL}export/excel?` + filters,
      AuthService.getAuthHeaderBlob()
    );
  }

  getDataSheet(idUser) {
    return axiosInstanceWithInterceptors.get(
      USER_API_URL + 'datasheet/' + idUser
    );
  }

  getRecipientsLists(params) {
    let urlParams = new URLSearchParams(params);
    return axiosInstanceWithInterceptors.get(
      `${USER_API_URL}recipients/list/?` + urlParams.toString()
    );
  }

  validateListTitle(title) {
    return axiosInstanceWithInterceptors.patch(
      USER_API_URL + 'recipients/list/validate',
      { title: title }
    );
  }

  initRecipientsList = async () => {
    return await axiosInstanceWithInterceptors.post(
      USER_API_URL + 'recipients/list/',
      null
    );
  };

  saveRecipientsList = async (id, data) => {
    return await axiosInstanceWithInterceptors.patch(
      USER_API_URL + 'recipients/list/' + id,
      data
    );
  };

  deleteRecipientsList = async (id) => {
    return await axiosInstanceWithInterceptors.delete(
      USER_API_URL + 'recipients/list/' + id
    );
  };

  deleteRecipientsFromList = async (id, idsToDelete) => {
    return await axiosInstanceWithInterceptors.delete(
      USER_API_URL + 'recipients/list/delete/recipients/' + id,
      {
        ...AuthService.getAuthHeader(),
        data: { to_delete: idsToDelete },
      }
    );
  };

  duplicateRecipientsList = async (id) => {
    return await axiosInstanceWithInterceptors.post(
      USER_API_URL + 'recipients/list/duplicate?id_list=' + id,
      null
    );
  };

  doesUserFollowsNode = async (idUser, idNode) => {
    return axiosInstanceWithInterceptors.get(
      `${USER_API_URL}follow/${idNode}/${idUser}`
    );
  };
}

// eslint-disable-next-line
export default new UserService();
