import { createContext, useState } from 'react';

const FormDataContext = createContext({});

export function FormDataProvider({ children }) {
  const [portfolioData, setPortfolioData] = useState({});
  const [isPortfolioDataValid, setIsPortfolioDataValid] = useState(false);
  const [sectionData, setSectionData] = useState({});
  const [isSectionDataValid, setIsSectionDataValid] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [isArticleDataValid, setIsArticleDataValid] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  return (
    <FormDataContext.Provider
      value={{
        portfolioData,
        setPortfolioData,
        setIsPortfolioDataValid,
        isPortfolioDataValid,
        sectionData,
        setSectionData,
        setIsSectionDataValid,
        isSectionDataValid,
        articleData,
        setArticleData,
        setIsArticleDataValid,
        isArticleDataValid,
        activeStep,
        setActiveStep,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
}

export default FormDataContext;
