import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function SelfTooltip({ button, isSelf, text, textTranslated = '' }) {
  const { t } = useTranslation();
  return (
    <Tooltip
      disableHoverListener={!isSelf}
      disableFocusListener={!isSelf}
      title={text ? t(text) : textTranslated}
    >
      <span>{button}</span>
    </Tooltip>
  );
}
