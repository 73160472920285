import { Box, Container, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoundsNotifyTypes, RoundsRoles } from '../../../utils/Constants';
import NotificationAppointmentDetail from '../../backoffice/NotificationsMassive/NotificationAppointmentDetail';
import { CallToAction } from '../../CallToAction/CallToAction';
import TurnInfo from '../../Event/Detail/TurnInfo';
import { useNotificationTabStyles } from '../useNotificationTabStyles';

export const NoticeEventNotification = ({ notification, onClose }) => {
  const { t } = useTranslation();
  const classes = useNotificationTabStyles();
  const history = useHistory();
  const date_text = notification?.date_text;
  const location_text = notification?.location_text;
  const cancelled_turn = notification?.cancel_turn;
  const event = notification?.record_relation?.event;

  const handlerEvent = () => {
    onClose();
    const idEvent = notification?.record_relation?.id || '';
    history.push(`/tu-mica/rondas/agenda/${idEvent}`);
  };

  const handlerAgenda = () => {
    onClose();
    history.push(`/tu-mica/rondas/agenda/`);
  };
  const shouldShowTurnInfo = () => {
    return (
      event?.role === RoundsRoles.SELLER &&
      event?.status_participant === RoundsNotifyTypes.CONFIRMED &&
      !event?.has_a_turn
    );
  };

  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(notification.introduction),
        }}
        className={classes.dialogText}
      />
      <NotificationAppointmentDetail
        date_text={date_text}
        location_text={location_text}
        cancelled_turn={cancelled_turn}
        event={notification?.record_relation?.event}
      />
      {shouldShowTurnInfo() && (
        <TurnInfo event={event} participantRol={event?.role} />
      )}
      <Box
        className={classes.buttonBox}
        display='flex'
        alignItems='center'
        justifyContent='flex-start'
      >
        <CallToAction
          kind='primary'
          onClickHandler={() => {
            handlerEvent();
          }}
          classes={[classes.dialogButton]}
          label={t('messages.notifications.dialogs.notice.go-to-event')}
        />
        <CallToAction
          kind='extra'
          onClickHandler={() => {
            handlerAgenda();
          }}
          classes={[classes.dialogButton]}
          label={t('messages.notifications.dialogs.notice.agenda')}
        />
      </Box>
    </Container>
  );
};
