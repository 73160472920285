import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    height: '100%',
    textAlign: 'center',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
    },
  },
}));

export const GoHome = (props) => {
  const { width = '71px', height = '18px', className, children } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      color='secondary'
      component={Link}
      to='/inicio'
      disableRipple
      role='link'
      aria-label={t('accessibility.navbar.lateralMenu.textLogoMica')}
      sx={{ padding: 0 }}
      className={className ? className : classes.title}
    >
      <img
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt='.'
        width={width}
        height={height}
      />
      {children}
    </Button>
  );
};
