import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  chipGroup: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#4F4F4F',
    padding: theme.spacing(0, 0.5),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  chipColorTransparent: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.secondaryAccent.extraLight}`,
  },
  chipColorWhite: {
    backgroundColor: 'white',
    fontSize: '10px',
    fontWeight: '600',
  },
  chipColorGrey: {
    backgroundColor: theme.palette.primary.dark,
    fontSize: '10px',
    fontWeight: '600',
  },
  chipColorWhiteBlackBorder: {
    backgroundColor: 'transparent',
    fontSize: '10px',
    fontWeight: '600',
    border: `1px solid black`,
  },
}));

function ArtistTags(props) {
  const classes = useStyles();
  const tags = props?.tags ?? [];
  const extraClasses = Array.isArray(props?.classes)
    ? props?.classes
    : [props?.classes];
  const { customTag } = props;

  return (
    <div className={[classes.chipGroup, ...extraClasses].join(' ')}>
      {tags?.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          size='small'
          className={classnames(
            classes.chip,
            customTag === 'chipColorWhite'
              ? classes.chipColorWhite
              : customTag === 'chipColorWhiteBlackBorder'
              ? classes.chipColorWhiteBlackBorder
              : customTag === 'chipColorGrey'
              ? classes.chipColorGrey
              : classes.chipColorTransparent
          )}
        />
      ))}
    </div>
  );
}

export default ArtistTags;
