import classNames from 'classnames';
import { formatReadableDate, isDatePassed } from '../../utils/formHelper';
import { getNoveltyDateShowTextCard } from '../../shared/BackOffice/helpers/eventHelper';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '../CustomIcons/CustomIcons';
import { IconButton } from '@mui/material';
import { ActivitiesStyles } from './ActivitiesStyles';
import { SearchEntitiesType } from '../../utils/Constants';

export default function ActivityFooter({
  activity,
  isHome,
  hasShadow,
  inActivities,
  toggleShareDialog,
}) {
  const { t, i18n } = useTranslation();
  const classes = ActivitiesStyles({
    hasShadow: hasShadow,
    inActivities: inActivities,
  });
  const isDateStartPassed = isDatePassed(activity?.date_start);
  const isDateEndPassed = isDatePassed(activity?.date_end);
  const isDateEndNext = isDateStartPassed && !isDateEndPassed;
  const isNovelty =
    activity?.data_type === SearchEntitiesType.NOTES ||
    activity?.type === SearchEntitiesType.NOTES;

  const addAvailableUntil = (date) => {
    return date
      ? t(`agenda.activities.footerCardDate.availableUntil`, {
          date: formatReadableDate(date, i18n.language),
        })
      : t(`agenda.activities.footerCardDate.available`);
  };

  const addNextFrom = (date) => {
    return t(`agenda.activities.footerCardDate.fromThe`, {
      date: formatReadableDate(date, i18n.language),
    });
  };
  const shouldAddClass = () => {
    return activity?.date_end !== null && isDateEndNext;
  };

  const getActivityDate = () => {
    const type = activity?.type_date_displayed;
    let dateText = formatReadableDate(activity?.date_start, i18n.language);

    if (isNovelty) {
      dateText = getNoveltyDateShowTextCard(activity, type, i18n.language, t);
    } else {
      if (isDateEndNext) dateText = addAvailableUntil(activity?.date_end);
      if (!isDateStartPassed) dateText = addNextFrom(activity?.date_start);
    }

    return dateText;
  };

  return (
    <div
      className={classNames(classes.eventDate, isHome && classes.eventDateHome)}
    >
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/icons/activities-calendar.svg`}
          width={24}
          height={24}
          alt='.'
        />
        <p
          className={
            shouldAddClass()
              ? classes.footerCardDateText
              : classes.footerCardDateTextDefault
          }
        >
          {getActivityDate()}
        </p>
      </div>

      {!isHome && (
        <div>
          <IconButton
            onClick={(e) => toggleShareDialog(e)}
            className={classes.shareIcon}
            size='large'
            aria-hidden='true'
            tabIndex={-1}
          >
            <CustomIcon
              iconSrc={`${process.env.PUBLIC_URL}/icons/shareIconCard.svg`}
            />
          </IconButton>
        </div>
      )}
    </div>
  );
}
