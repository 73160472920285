//ver roles en https://docs.google.com/spreadsheets/d/1c8lxGA1vjuw8tO0L9XhEcHfmNP7rjaD5FhOBEyK_grM/edit#gid=1880915808
export const Backoffice_Rules = {
  Actions: {
    ADMIN: 'admin',
    ACCESS: 'access',
    EXPORT: 'export',
    ROUNDS_WRITE: 'rounds_write',
    ROUNDS_READ: 'rounds_read',
    EVENT_WRITE: 'event_write',
    EVENT_READ: 'event_read',
    CYCLE_EXPORT: 'cycle_export',
    CYCLE_WRITE: 'cycle_write',
    CYCLE_READ: 'cycle_read',
    SECTOR_WRITE: 'sector_write',
    SECTOR_READ: 'sector_read',
    VALIDATION_WRITE: 'validation_write',
    VALIDATION_READ: 'validation_read',
    USER_WRITE: 'user_write',
    USER_READ: 'user_read',
    PUBLIC_CONTENT: 'public_content',
    HOME_READ: 'home_read',
    HOME_WRITE: 'home_write',
    COMMUNICATION_READ: 'communication_read',
    COMMUNICATION_WRITE: 'communication_write',
    FORMS_WRITE: 'forms_write',
    FORMS_READ: 'forms_read',
    FORMS_READ_EXCELS: 'forms_read_excels',
    PROGRAMS_READ: 'programs_read',
    PROGRAMS_READ_STATISTICS: 'programs_read_statistics',
    PROGRAMS_WRITE: 'programs_write',
    PROGRAMS_LIST_READ: 'programs_list',
    PROGRAMS_LIST_WRITE: 'programs_list',
    PROGRAM_READ: 'program_read',
    PROGRAM_READ_STATISTICS: 'program_read_statistics',
    PROGRAM_WRITE: 'program_write',
    PROGRAM_DELETE: 'program_delete',
    PROGRAM_LIST_READ: 'program_list',
    PROGRAM_LIST_WRITE: 'program_list',
    ACTION_POINT_READ: 'action_point_read',
    ACTION_POINT_WRITE: 'action_point_write',
    ACTION_POINT_DELETE: 'action_point_delete',
    ACTION_POINT_READ_EXCEL: 'action_point_read_excel',
    ROUNDS_MASS_EXPORT: 'rounds_mass_export',
    STATISTICS_READ: 'statistics_read',
    SLIDERS_READ: 'sliders_read',
    SLIDERS_WRITE: 'sliders_write',
    MEETING_PROGRAMS_READ: 'meeting_programs_read',
    MEETING_PROGRAMS_WRITE: 'meeting_programs_write',
    MEETING_PROGRAM_READ: 'meeting_program_read',
    MEETING_PROGRAM_WRITE: 'meeting_program_write',
    MEETING_PROGRAM_HIGHLIGHT: 'meeting_program_highlight',
    MEETING_ACTION_POINT_READ: 'meeting_action_point_read',
    MEETING_ACTION_POINT_WRITE: 'meeting_action_point_write',
    MEETING_ACTION_POINT_HIGHLIGHT: 'meeting_action_point_highlight',
    MEETING_VIRTUAL_ACCESS: 'meeting_virtual_access',
    PROGRAMS_ACCESS_TO_ALL: 'programs_access_to_all',
    MICA_TITLES_READ: 'mica_titles_read',
  },
  Subjects: {
    BACKOFFICE: 'backoffice',
  },
};
