import { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Trans } from 'react-i18next';
import { CallToAction } from '../CallToAction/CallToAction';
import { ButtonBase, Container, Grid } from '@mui/material';
import { theme } from '../../theme';

const isChunckLoadError = (error) => {
  return error.name === 'ChunkLoadError';
};
export default class AppErrorBoundary extends Component {
  state = {
    error: '',
    eventId: '',
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return (
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{
            height: 'calc(100% - 100px)',
          }}
        >
          <Container
            disableGutters
            maxWidth='md'
            style={{
              backgroundColor: 'white',
              margin: theme.spacing(8, 'auto'),
              padding: theme.spacing(8, 2),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Container maxWidth='sm'>
              <Grid container spacing={2}>
                <Grid
                  container
                  alignItems='center'
                  style={{
                    marginBottom: theme.spacing(4),
                  }}
                >
                  <Grid item xs={12} sm={7}>
                    <h3>
                      {isChunckLoadError(error) ? (
                        <Trans i18nKey='app-error-boundary.chunck-load-error' />
                      ) : (
                        <Trans i18nKey='app-error-boundary.title' />
                      )}
                    </h3>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={5}
                    justifyContent='flex-end'
                    alignItems='flex-end'
                  >
                    <CallToAction
                      kind='secondary'
                      label={<Trans i18nKey='app-error-boundary.reload-page' />}
                      onClickHandler={() => window.location.reload()}
                    />
                  </Grid>
                </Grid>

                {!isChunckLoadError(error) && (
                  <>
                    <Grid container item spacing={2}>
                      <p>
                        <Trans i18nKey='app-error-boundary.send-comments.description' />
                      </p>
                    </Grid>

                    <Grid container item spacing={2}>
                      <ButtonBase
                        style={{
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          fontSize: 18,
                        }}
                        onClick={() => {
                          Sentry.showReportDialog({
                            eventId: this.state.eventId,
                          });
                        }}
                        underline='hover'
                      >
                        <Trans i18nKey='app-error-boundary.send-comments.button' />
                      </ButtonBase>
                      <span>&nbsp;*</span>
                    </Grid>
                    <p style={{ fontSize: '12px' }}>
                      <Trans i18nKey='app-error-boundary.send-comments.adblocker-warning' />
                    </p>

                    <div className='card-body'>
                      <details className='error-details'>
                        <summary
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <Trans i18nKey='app-error-boundary.see-error-details' />
                        </summary>
                        {errorInfo && errorInfo.componentStack.toString()}
                      </details>
                    </div>
                  </>
                )}
              </Grid>
            </Container>
          </Container>
        </Grid>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
