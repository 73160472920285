import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  NotificationClassType,
  RoundsLocation,
  RoundsNotifyTypes,
} from '../../../utils/Constants';
import {
  formatReadableDate,
  getLocalHourFromDate,
} from '../../../utils/formHelper';
import { MeetingNotificationContent } from '../../Meeting/MeetingNotificationContent';
import { RoundNotificationContent } from '../../Rounds/RoundNotificationContent';

export const EventNotification = ({ notification, onClose }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const data = notification?.record_relation;
  const date_text = notification?.date_text;
  const location_text = notification?.location_text;
  const cancelled_turn = notification?.cancel_turn;
  const titleInvite = data?.name;
  const roundId = data?.id;
  const imgSrc = data?.url_media;
  const role = data?.event?.role;
  const isSeller = role === RoundsNotifyTypes.SELLER;
  const titleNotification = isSeller
    ? 'round-notification-dialog.first-line'
    : 'round-notification-dialog.first-line-' + role;
  const textInvite = data?.event?.text_invite;
  const dateStart = data?.event?.date_start;
  const dateEnd = data?.event?.date_end;
  const location = data?.event?.location;
  const locationDesc = data?.event?.location_desc;
  const timeStart = getLocalHourFromDate(dateStart);
  const timeEnd = getLocalHourFromDate(dateEnd);
  const dateText = t('messages.notifications.dialogs.event.date', {
    date: formatReadableDate(dateStart, i18n.language),
    from: timeStart,
    to: timeEnd,
  });
  const event = notification?.record_relation?.event;

  const goToEvent = () => {
    onClose();
    history.push('/tu-mica/rondas/agenda/' + roundId);
  };

  const goToAgenda = () => {
    onClose();
    history.push('/tu-mica/rondas/agenda');
  };

  const getLocation = () => {
    let locationText = '';
    if (location === RoundsLocation.OTHER) {
      locationText = locationDesc;
    } else {
      locationText = t(
        'backoffice.businessRound.form.location.options.' + location
      );
    }
    return locationText;
  };

  const locationText = getLocation();

  let content;
  switch (notification?.record_relation?.event?.type_class) {
    case NotificationClassType.ROUND:
    default:
      content = (
        <RoundNotificationContent
          title={titleNotification}
          roundName={titleInvite}
          roundDescription={textInvite}
          img={imgSrc}
          roundLocation={locationText}
          dateText={dateText}
          goToEvent={goToEvent}
          goToAgenda={goToAgenda}
          isSeller={isSeller}
          date_text={date_text}
          location_text={location_text}
          cancelled_turn={cancelled_turn}
        />
      );
      break;
    case NotificationClassType.MEETING:
      content = (
        <MeetingNotificationContent
          title={notification?.title}
          roundName={titleInvite}
          roundDescription={textInvite}
          img={imgSrc}
          roundLocation={locationText}
          dateText={dateText}
          goToEvent={goToEvent}
          goToAgenda={goToAgenda}
          isSeller={isSeller}
          cancelled_turn={cancelled_turn}
          event={event}
        />
      );
      break;
  }
  return content;
};
