import { Link } from 'react-router-dom';
import { MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';
import { buildDomainPublishedPath } from '../../../shared/BackOffice/Buttons/BOCommonButtons';

const DropDownPrograms = ({
  isMobile,
  menuDomainsOpen,
  closeMenu,
  domains,
}) => {
  const classes = NavbarStyles();

  return (
    isMobile &&
    menuDomainsOpen &&
    domains.map((domain) => (
      <MenuItem
        onClick={closeMenu}
        className={classes.mainSubMenuMobileItemWrapper}
        component={Link}
        to={buildDomainPublishedPath(domain.id_domain, domain.title)}
        key={domain.id_domain}
      >
        <Typography className={classes.mainSubMenuMobileItem}>
          {domain.title}
        </Typography>
      </MenuItem>
    ))
  );
};

export default DropDownPrograms;
