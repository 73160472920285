import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CallToAction } from '../../CallToAction/CallToAction';
import { CreateTextLinks } from '../../../shared/CreateTextLinks';
import { EntitiesType, Z_INDEX } from '../../../utils/Constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from '../../../services/AuthService';
import { isSuspended } from '../../../utils/formHelper';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
    },
  },
  titleNotification: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '20px',
    fontWeight: 600,
  },
  bulkName: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  bulkBanner: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  bulkContent: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
    fontSize: '0.8rem',
    lineHeight: '1rem',
    '& p': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
  },
  dialogButton: {
    display: 'flex',
  },
  rootDialog: {
    zIndex: Z_INDEX.HIGH,
  },
}));

export const HiddenTipContent = ({ notification, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const idUser = AuthService.getUserInfo()?.id_user;
  const isPortfolio =
    notification?.record_relation?.element_type === EntitiesType.PORTFOLIO;
  const isSection =
    notification?.record_relation?.element_type === EntitiesType.SECTION;

  const goToPortfolio = () => {
    onClose();
    history.push('/usuario/' + notification.record_relation?.id);
  };
  const goToSection = () => {
    onClose();
    history.push(
      `/usuario/${idUser}/portfolio/seccion/${notification.record_relation?.id}`
    );
  };
  const goToArticle = () => {
    onClose();
    history.push(
      `/usuario/${idUser}/catalogo/articulo/${notification.record_relation?.id}`
    );
  };

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Container
          maxWidth='md'
          disableGutters
          className={classes.roundContent}
        >
          <CreateTextLinks className={classes.roundContent}>
            {isSuspended(notification?.record_relation?.workflow)
              ? notification?.text_footer
              : notification?.introduction}
          </CreateTextLinks>
        </Container>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex'
        marginTop='1rem'
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-around'
          style={{ marginTop: '1rem' }}
        >
          {isPortfolio ? (
            <CallToAction
              kind='primary'
              onClickHandler={goToPortfolio}
              classes={[classes.dialogButton]}
              label={t('messages.notifications.dialogs.follow.view-portfolio')}
            />
          ) : (
            <CallToAction
              kind='primary'
              onClickHandler={isSection ? goToSection : goToArticle}
              classes={[classes.dialogButton]}
              label={t(
                'messages.notifications.dialogs.published_item.view-product'
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
