import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    transition: theme.transitions.create([
      'color',
      'border-color',
      'background-color',
    ]),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.dark,
      backgroundColor: theme.palette.primary.white,
      borderColor: theme.palette.primary.white,
    },
    border: theme.shape.border,
    borderColor: 'currentcolor',
    color: theme.palette.primary.white,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.artistActionButtons.borderRadius,
    fontSize: '.85rem',
    backgroundColor: 'transparent',
    '& span': {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionExtraWhite = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
      tabIndex={props?.tabIndex || 0}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
};
