import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CreateTextLinks } from '../../shared/CreateTextLinks';
import { CallToAction } from '../CallToAction/CallToAction';
import TurnInfo from '../Event/Detail/TurnInfo';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },
  roundName: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  roundBanner: {
    marginBottom: theme.spacing(2),
  },
  roundContent: {
    marginBottom: theme.spacing(3),
    '& p': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
  },
}));

export const MeetingNotificationContent = ({
  title,
  roundName,
  roundDescription,
  img,
  roundLocation,
  dateText,
  goToEvent,
  isSeller,
  event,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography
        className={classes.subtitle}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Box display='flex' flexDirection='column'>
        <Typography className={classes.roundName}>{roundName}</Typography>
        <img src={`${img}`} alt={roundName} className={classes.roundBanner} />
        <Container
          maxWidth='md'
          disableGutters
          className={classes.roundContent}
        >
          <CreateTextLinks>{roundDescription}</CreateTextLinks>
        </Container>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        marginTop='1rem'
      >
        <TurnInfo TurnInfo event={event} participantRol={event?.role} />
        <br />
        <br />
        {isSeller ? (
          <>
            <Typography className={classes.title}>
              {t('round-notification-dialog.confirm-title')}
            </Typography>
            <CallToAction
              kind='secondary'
              label={t('round-notification-dialog.confirm')}
              onClickHandler={goToEvent}
            />
          </>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='flex-start'>
            <CallToAction
              kind='primary'
              onClickHandler={goToEvent}
              classes={[classes.dialogButton]}
              label={t('round-notification-dialog.details')}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
