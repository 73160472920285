import { Link } from 'react-router-dom';
import { addHttp } from '../../utils/utils';

export default function ExternalLink({
  children,
  to,
  href = false,
  newWindow = false,
  linkOn = true,
  classes = '',
}) {
  return linkOn ? (
    href ? (
      <a
        target={newWindow ? '_blank' : '_self'}
        rel={newWindow ? 'noreferrer' : ''}
        href={addHttp(href)}
        style={{ textUnderline: 'none' }}
        className={classes}
      >
        {children}
      </a>
    ) : (
      <Link to={to || ''} className={classes}>
        {children}
      </Link>
    )
  ) : (
    <div className={classes}>{children}</div>
  );
}
