import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Typography } from '@mui/material';

export const LogOut = (props) => {
  const { t } = useTranslation();
  return (
    <MenuItem className={props.classes.yourMicaMenuItemWrapper}>
      <Link style={{ textDecoration: 'none' }} to={'/cerrar-sesion'}>
        <Typography className={props.classes.yourMicaMenuBoldItem}>
          {t('navbar.links.logout')}
        </Typography>
      </Link>
    </MenuItem>
  );
};
