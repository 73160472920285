import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isPortfolioApproved } from '../../shared/BackOffice/helpers/validationHelper';

export const ValidationTooltip = ({ children, row }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      disableHoverListener={isPortfolioApproved(row)}
      disableFocusListener={isPortfolioApproved(row)}
      title={t('backoffice.warnings.unsent-portfolio')}
    >
      {children}
    </Tooltip>
  );
};
