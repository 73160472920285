import { PlayCircleFilled } from '@mui/icons-material';
import { HighlightStyles } from '../../containers/views/public/home/HighlightStyles';
import { IconButton } from '@mui/material';

export const PlayIcon = ({ onClick, ariaLabel = 'player' }) => {
  const classes = HighlightStyles();
  return (
    <IconButton
      onClick={onClick}
      className={classes.playIcon}
      aria-label={ariaLabel}
      size='large'
    >
      <PlayCircleFilled fontSize={'inherit'} />
    </IconButton>
  );
};
