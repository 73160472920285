import makeStyles from '@mui/styles/makeStyles';

export const MessagePreviewStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '4px 0px',
    textDecoration: 'none',
    color: 'black',
    '&:focus-visible': {
      outlineColor: 'black !important',
      padding: 5,
    },
  },
  avatar: { width: 60, height: 60 },
  infoMessage: { paddingLeft: 10, width: 240 },
  nameUser: {
    fontWeight: 700,
    maxWidth: 250,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  message: {
    fontSize: '14px',
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: 5,
  },
  infoEnd: { textAlign: 'end', width: '62px' },
  badgeContainer: { display: 'flex', justifyContent: 'flex-end' },
  badge: { paddingRight: '10px' },
  containerDate: { fontSize: '14px', marginTop: 5 },
}));
