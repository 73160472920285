import makeStyles from '@mui/styles/makeStyles';

export const ProductBySectorStyles = makeStyles((theme) => ({
  containerProducts: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: theme.spacing(0, 0, 0, 3),
  },
  containerProductsInHome: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2, 0, 6),
    },
  },
  titlePortfolioBySector: {
    color: theme.palette.secondaryAccent.main,
    fontWeight: 600,
    paddingBottom: theme.spacing(0),
  },
  titlePortfolioInHome: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
    },
  },
  productsListHome: {
    padding: '0px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  containerPortfolioBySection: {
    padding: theme.spacing(3.75, 0),
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3, 0, 0),
    paddingBottom: '30px',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
      paddingBottom: '30px',
    },
  },
  button: {
    alignItems: 'center',
    border: 'none',
    borderRadius: 20,
    color: theme.palette.dark.main,
    display: 'flex',
    fontSize: 13,
    fontWeight: 600,
    height: 30,
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '4px dashed blue !important',
    },
    '&:hover': {
      backgroundColor: 'black',
      color: theme.palette.primary.white,
    },
    [theme.breakpoints.up('md')]: {
      border: '1px solid ',
      padding: theme.spacing(0, 2.75),
      textDecoration: 'none',
    },
  },
  containerButton: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 70,
    },
  },
}));
