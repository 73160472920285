import makeStyles from '@mui/styles/makeStyles';

export const useMassiveNotificationStyles = makeStyles((theme) => ({
  itemHeader: {
    display: 'flex !important',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.light,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  itemHeaderElement: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  itemCalendarContainer: { display: 'flex', alignItems: 'center' },
  separatorContainer: { padding: '5px 5px 0px 5px' },
  separator: {
    width: 2,
    height: 2,
    backgroundColor: 'black',
    borderRadius: 10,
  },
  containerAddToCalendar: { marginBottom: 10, fontSize: 14, fontWeight: 700 },
  hr: { border: '1px solid #D9D9D9', marginBottom: 10 },
  boxAddToCalendar: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  atcbAction: {
    display: 'flex',
    fontWeight: 500,
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  itemCalendar: { textDecoration: 'underline', cursor: 'pointer' },
  progressItemHeader: {
    justifyContent: 'space-between',
  },
  itemBodyElement: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '16px',
    width: 'calc(100%)',
    float: 'left',
  },
  fullwidth: {
    width: '100%',
  },
  status: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: '3px',
  },
  statusMargin: {
    marginRight: theme.spacing(2),
  },
  error: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    padding: '4px',
  },
  completedColor: {
    color: theme.palette.info.dark,
  },
  downloadIcon: {
    padding: 0,
    '&[disabled]': {
      color: 'gray',
    },
  },
  errorIcon: {
    color: theme.palette.secondary.main,
  },
  editIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '1',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: '0px',
  },
  content: {
    padding: theme.spacing(5.5, 0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 0),
    },
    height: '100%',
  },
  contentIcon: {
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  archivedButtonContainer: {
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  collapseHeaderMargin: {
    marginBottom: theme.spacing(3),
  },
  tableContainer: {
    marginBottom: theme.spacing(5),
  },
  flexContainer: {
    display: 'flex',
  },
  bodyContainer: {
    display: 'inline-flex',
    position: 'relative',
  },
  massiveNotificationContainer: {
    borderRadius: '5px',
  },
  containerShadow: {
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.25)',
  },
  ellipsis: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    marginBottom: theme.spacing(2),
  },
  infoTextSize: {
    fontSize: '14px',
  },
  infoTextBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  expandIcon: {
    margin: theme.spacing(0, 3, 0, 1),
  },
  linkPointer: {
    cursor: 'pointer',
  },
  author: {
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: theme.spacing(2),
  },
  softText: {
    fontSize: '13px',
    paddingBottom: theme.spacing(2),
  },
  detailInternalName: {
    fontSize: '30px',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  detailTitle: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  recipientTitle: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
  recipientText: {
    fontSize: '16px',
  },
  box: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: '5px',
    padding: theme.spacing(4, 3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  boxWithHeader: {
    padding: 'unset !important',
  },
  header: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.white,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: theme.spacing(2, 0, 0, 0),
  },
  boxButtonsSuspend: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0, 0, 0),
  },
  boxGray: {
    background: theme.palette.read,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 0, 0),
  },
  gridEditButton: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    ' & button': {
      padding: 0,
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  },
  underlineMain: {
    padding: 0,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  boxDate: {
    padding: theme.spacing(2, 0),
  },
  noDecorationLink: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  topSpace: {
    marginTop: theme.spacing(3),
  },
  internalNameTitle: {
    fontWeight: '600',
    fontSize: '20px',
    textAlign: 'right',
  },
  internalNameSubtitle: {
    fontSize: '16px',
    textAlign: 'right',
  },
  summaryRecipientLightBox: {
    padding: theme.spacing(3, 0),
  },
  tableBar: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  tableBarColor: {
    backgroundColor: theme.palette.primary.dark,
  },
  boxState: {
    textAlign: 'center',
    padding: theme.spacing(0, 4),
    justifyContent: 'center',
  },
  boxStateIcon: {
    '& svg': {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
    },
  },
  progressBarDetail: {
    margin: theme.spacing(3, 0, 4),
  },
  progressBarList: {
    margin: theme.spacing(0, 1, 0),
  },
  progressBarListContainer: {
    width: '250px',
    display: 'flex',
    alignItems: 'center',
  },
  circularSending: {
    color: theme.palette.info.dark,
  },
  circularPaused: {
    color: theme.palette.secondaryAccent.light,
  },
  Progressbar: {
    width: '100%',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'rgba(28,107,251)',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: 'rgba(28,107,251,0.24)',
    },
    '& .MuiTypography-colorPrimary': {
      color: 'rgba(28,107,251)',
    },
    '& .MuiLinearProgress-barColorSecondary': {
      backgroundColor: 'rgb(83,83,83)',
    },
    '& .MuiLinearProgress-colorSecondary': {
      backgroundColor: 'rgba(83,83,83,0.24)',
    },
    '& .MuiTypography-colorSecondary': {
      color: 'rgb(83,83,83)',
    },
  },
  recipientStatusFailed: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
  },
  recipientStatusSent: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.success.approved,
  },
  recipientStatusPaused: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.notification.date,
  },
  recipientsListTitle: {
    display: 'flex',
    alignSelf: 'flex-end',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
  },
  recipientsListBoxSearch: {
    alignItems: 'baseline',
  },
  addRecipientsContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,

    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  addRecipientsIconContainer: {
    height: '100px',
    backgroundColor: 'white',
  },
  addRecipientsIcon: {
    height: '60px',
    margin: 'auto',
  },
  addRecipientsLabel: {
    transition: theme.transitions.create('background-color'),
    height: '36px',
    fontWeight: 'light',
    padding: theme.spacing(1, 2),
    fontSize: '13px',
  },
  appointmentDetailsContainer: {
    height: 'auto',
    backgroundColor: '#F9F9F9',
    border: '1px solid #D9D9D9',
    borderRadius: '5px',
  },
  appointmentDetailsTitleContainer: {
    paddingTop: theme.spacing(3),

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  appointmentDetailsTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: '10px',
  },
  appointmentDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  appointmentDetailsSubtitle: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: '14px',
    marginTop: theme.spacing(0),
  },
  datePadding: {
    paddingRight: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  calendarIconRed: {
    color: '#E3313D',
  },
  calendarIconBlue: {
    color: '#1C6BFB',
  },
}));
