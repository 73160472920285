import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ArrowIconCustomCarrousel = ({
  backgroundColor,
  colorArrow,
  right,
  display,
  size = 'large',
}) => {
  const rotate = right ? '180' : '0';
  return (
    <div
      style={{
        display: display ? 'flex' : 'none',
        backgroundColor: backgroundColor,
        borderRadius: '50%',
      }}
    >
      <NavigateNextIcon
        fontSize={size}
        style={{
          color: colorArrow,
          transform: `rotate(${rotate}deg) scaleX(-1)`,
        }}
      />
    </div>
  );
};

export default ArrowIconCustomCarrousel;
