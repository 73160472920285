import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.dark,
    },
    border: theme.shape.border,
    color: 'white',
    fontWeight: 'light',
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0, 0.5),
    borderRadius: theme.artistActionButtons.borderRadius,
    fontSize: '13px',
    background: theme.palette.secondary.main,
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  iconAndTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: '13px',
  },
}));

export const CallToActionExtraPrimary = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
    >
      <Typography variant='button' className={classes.iconAndTextContainer}>
        <span>{props.icon && props.icon(classes.buttonIcon)}</span>
        <span className={classes.label}>{props.label}</span>
      </Typography>
    </Button>
  );
};
