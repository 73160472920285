import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography, useMediaQuery } from '@mui/material';
import { CustomIcon } from '../../../../components/CustomIcons/CustomIcons';
import { ImageLazy } from '../../../../components/Imagelazy/ImageLazy';
import { formatReadableDate } from '../../../../utils/formHelper';
import { useNotesStyles } from '../../../../components/Notes/useNotesStyles';
import { theme } from '../../../../theme';
import { IMAGE_SIZE } from '../../../../utils/Constants';
import classNames from 'classnames';

export const NoteCardLandscape = ({ note, width = '213', height = '89' }) => {
  const { t } = useTranslation();
  const classes = useNotesStyles();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const description =
    note?.description === '' ? note?.card_description : note?.description;
  return (
    <div>
      {isUpSm ? (
        <div className={classes.noveltyCard}>
          <div className={classes.arrowSection}>
            <CustomIcon
              icon='arrow-right'
              size={'14'}
              color={'black'}
              styleSVG={{
                stroke: 'black',
                strokeWidth: '1px',
                padding: '1px',
              }}
            />
          </div>
          <div className={classes.noveltyCardMain}>
            <Typography className={classes.noveltyType}>
              {t(
                `backoffice.noveltiesNew.form.fields.type.options.${note?.type}`
              )}
            </Typography>
            {note?.image && (
              <ImageLazy
                srcVersions={note.image.versions}
                alt={note.image.alt_text ?? ''}
                loading={'lazy'}
                float={'left'}
                sizePreferer={IMAGE_SIZE.SMALL}
                classes={classes.noveltyImg}
                width={width}
                height={height}
              />
            )}
            <div className={classes.chipWrapper}>
              {note?.cycle?.title && (
                <Link
                  to={`/actividades/ciclos/${note?.cycle?.id_cycle}`}
                  className={classes.buttonChip}
                >
                  <Chip
                    label={note?.cycle?.title}
                    sx={{
                      fontSize: 10,
                      fontWeight: 600,
                      color: '#535353',
                      backgroundColor: '#E0E0E0',
                      height: 20,
                      '& .MuiChip-label': {
                        pl: 1.25,
                        pr: 1.25,
                        borderRadius: 14,
                        cursor: 'pointer',
                      },
                    }}
                  />
                </Link>
              )}
            </div>
          </div>
          <div className={classes.noveltyCardContent}>
            <Typography className={classes.noveltyDate}>
              {formatReadableDate(note?.date_start)}
            </Typography>
            <Typography className={classes.noveltyFlyer}>
              {note?.flyer}
            </Typography>
            <Typography
              className={classNames(
                classes.noveltyTitle,
                classes.noveltyTitleElipsis
              )}
            >
              {note?.title}
            </Typography>
            <Typography
              className={classNames(
                classes.noveltyDescription,
                classes.noveltyDescriptionElipsis
              )}
            >
              {description}
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.noveltyCardMobile}>
            <div className={classes.arrowSectionAndNoveltyType}>
              <div className={classes.arrowSection}>
                <CustomIcon
                  icon='arrow-right'
                  size={'14'}
                  color={'black'}
                  styleSVG={{
                    stroke: 'black',
                    strokeWidth: '1px',
                    padding: '1px',
                  }}
                />
              </div>
              <Typography className={classes.noveltyType}>
                {t(
                  `backoffice.noveltiesNew.form.fields.type.options.${note?.type}`
                )}
              </Typography>
            </div>
            <div>
              <Typography className={classes.noveltyDate}>
                {formatReadableDate(note?.date_start)}
              </Typography>
            </div>
          </div>
          {note?.image && (
            <ImageLazy
              srcVersions={note.image.versions}
              alt={note.image.alt_text ?? ''}
              loading={'lazy'}
              float={'none'}
              classes={classes.noveltyImage}
              sizePreferer={IMAGE_SIZE.SMALL}
            />
          )}
          <Typography className={classes.noveltyFlyer}>
            {note?.flyer}
          </Typography>
          <Typography className={classes.noveltyTitle}>
            {note?.title}
          </Typography>
          <Typography className={classes.noveltyDescription}>
            {description}
          </Typography>
          <Box className={classes.mobileChipWrapper}>
            {note?.cycle?.title && (
              <Link
                to={`/actividades/ciclos/${note?.cycle?.id_cycle}`}
                className={classes.buttonChip}
              >
                <Chip
                  label={note?.cycle?.title}
                  sx={{
                    fontSize: 10,
                    fontWeight: 600,
                    color: '#535353',
                    backgroundColor: '#E0E0E0',
                    height: 20,
                    '& .MuiChip-label': {
                      pl: 1.25,
                      pr: 1.25,
                      borderRadius: 14,
                      cursor: 'pointer',
                    },
                  }}
                />
              </Link>
            )}
          </Box>
        </>
      )}
    </div>
  );
};
