import { createTheme } from '@mui/material';
import './App.css';
import 'typeface-montserrat';
import '@fontsource/open-sans';

export const BRAND_ACCENT = {
  main: '#E3313D', //rojo
  light: '#D3696A', //rojo con opacity
  dark: '#A63031', //rojo oscuro
  contrastText: '#FFFFFF', //blanco
  redSector: '#D01E2A',
};

export const BRAND_ACCENT_SECONDARY = {
  light: '#535353', //gris un poco oscuro
  extraLight: '#DBDBDB', //gris un poco claro
  almostWhite: '#f9f9f9',
  dark: '#141414', //casi negro
  main: '#262626', // gris oscuro
  secondaryMain: '#2f2f2f', // gris oscuro
  contrastText: '#FFFFFF', //blanco
};

const DANGER_PALETE = {
  main: '#FF0000', // rojo chillon
  rejected: '#E3313D',
};

const WARNING_PALETE = {
  light: '#FFF7E5', //amarillo blancusco
  main: '#f57c00', //amarillo casi huevo
  dark: '#ECA400', // amarillo huevo
  suggestions: '#EAD61E',
  contrastText: '#FFFFFF', //blanco
  text: '#4F4F4F', //gris
  login: '#FFEBBF',
};

const INFO_PALETE = {
  light: '#F0F5FF', //blanco azulado
  main: '#e5f6fd', //azulado
  dark: '#1C6BFB', // azul "francia"
};

const SUCCESS_PALETE = {
  main: '#84B903', //Verde lima
  dark: '#84B903', //verde lima un poco mas oscuro
  approved: '#317B22',
  menuPortfolio: '#BDFB28',
};

const DARK_PALETE = {
  main: '#262626', // casi negro
  light: '#323232', //gris oscuro
  extraLight: '#646464', //gris oscruo mas claro
};

const PARTICIPATION_STATUS_PALETE = {
  invitation: '#535353', // gris oscuro
  confirmed: '#317B22', // verde oscuro
  host: '#1C6BFB', // verde limón
};

const HIDDEN_PORTFOLIO_BY_ADMIN_PALETE = {
  main: '#1C6BFB', // azul "francia"
};

const FOCUS_VISIBLE_COLOR = '#1C6BFB';

export const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'sans-serif'].join(','),
    color: BRAND_ACCENT.contrastText,
    table: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
    },
  },
  page: {
    backgroundColor: '#FDFDFD', //Casi blanco
  },
  shape: {
    borderRadius: 5,
    border: '1px solid #EBEBEB', //gris muy claro
  },
  artistActionButtons: {
    borderRadius: '10px',
    borderRadius14: '14px',
    borderRadius20: '20px',
  },
  input: {
    border: {
      borderWidth: '1px',
      borderStyle: 'solid',
      inactive: {
        borderColor: '#EBEBEB', //gris muy claro
      },
      focus: {
        borderColor: BRAND_ACCENT_SECONDARY.light,
      },
    },
  },
  palette: {
    primary: {
      main: '#E3313D', //rojo MICA
      green: '#BDFA29', // Verde MICA
      light: '#F2F2F2', //gris claro rojizo
      dark: '#EBEBED', // gris claro azulado
      red: '#FD4E45', //rojo
      grey: '#FFFF66', // Amarillo verdoso
      darkGrey: '#333333',
      white: '#FFFFFF', //blanco
      hiddenByAdmin: HIDDEN_PORTFOLIO_BY_ADMIN_PALETE,
      focusVisible: FOCUS_VISIBLE_COLOR,
    },
    dark: DARK_PALETE,
    secondary: BRAND_ACCENT,
    secondaryAccent: BRAND_ACCENT_SECONDARY,
    danger: DANGER_PALETE,
    warning: WARNING_PALETE,
    info: INFO_PALETE,
    grayVisibilityProfile: '#F0F0F0',
    success: SUCCESS_PALETE,
    read: '#ECECEC', //gris muy claro
    neutral: {
      main: BRAND_ACCENT.contrastText,
    },
    forms: {
      blue: '#414EDB', //azul medio claro lilacio
      brightBlue: '#1C6BFB', // azul "francia"
    },
    notification: {
      unread: '#fff', //blanco
      read: '#ECECEC', //gris muy claro
      border: '#D9D9D9', //gris claro
      date: '#8b8b8b', //gris oscuro
      viewing: '#dedede',
      selected: '#e6e6e6',
      title: BRAND_ACCENT_SECONDARY.main,
      titleLight: '#fff',
      borderDark: BRAND_ACCENT_SECONDARY.main,
    },
    messages: {
      'self-background': '#EDF9EB', //blanco verdoso
      background: '#F4F4F4', //gris muy claro
    },
    background: {
      default: '#F2F2F2', //gris muy claro
    },
    activities: {
      meeting: '#FF2471',
      show: '#1C6BFB',
      music: '#1C6BFB',
      sector: '#E3313D',
    },
    table: {
      footer: BRAND_ACCENT_SECONDARY.extraLight,
    },
    agenda: {
      invitation: PARTICIPATION_STATUS_PALETE.invitation, // gris oscuro
      confirmed: PARTICIPATION_STATUS_PALETE.confirmed, // verde limón
      host: PARTICIPATION_STATUS_PALETE.host, // verde oscuro
    },
    switch: {
      green: '#84B903',
      darkgreen: '#317B22',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '& .MuiContainer-maxWidthXl': {
            maxWidth: '100%',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '&:focus-visible': {
            outline: '4px dashed !important',
            outlineColor: 'inherit',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
          },
          '&:focus-visible': {
            outline: '4px dashed',
            outlineColor: 'inherit',
            outlineOffset: '-8px',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
          },
          '&:focus-visible': {
            outline: '4px dashed',
            outlineColor: 'inherit',
            outlineOffset: '-8px',
          },
        },
      },
    },
    TableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: BRAND_ACCENT.contrastText,
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});
