import { createContext, useContext } from 'react';

export const LoginContext = createContext({
  loginData: {},
  setLoginData: () => {},
});

export const LoginProvider = LoginContext.Provider;

// Custom Hooks

export function useLoginContext() {
  return useContext(LoginContext);
}

export function IsLoginOpen() {
  const { loginData } = useLoginContext();
  return loginData?.isOpen;
}

export function GetPreviousLocation() {
  const { loginData } = useLoginContext();
  return loginData?.previousLocation;
}
