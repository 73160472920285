import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthService from './services/AuthService';
import ReduxSnackbars from './components/UI/ReduxSnackbar';
import PublicLayout from './containers/layouts/public';
import ScrollToTop from './shared/ScrollToTop';
import { SearchFiltersProvider } from './context/SearchFiltersContext';

const BackofficeLayout = React.lazy(() =>
  import('./containers/layouts/backoffice')
);
const StatusControl = React.lazy(() =>
  import('./containers/layouts/StatusControl')
);
const Developers = React.lazy(() => import('./containers/layouts/Developers'));

const SentryExamplePage = React.lazy(() =>
  import('./containers/layouts/SentryExamplePage')
);

export const Routes = () => {
  const isAuthendicated = AuthService.isAuthenticated();
  const initialUserInfoState = AuthService.getUserInfo();
  const [isLoggedIn, setisLoggedIn] = useState(isAuthendicated);
  const [userInfo] = useState(initialUserInfoState);

  return (
    <Router>
      <SearchFiltersProvider>
        <ScrollToTop />
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={`/developers`}>
              <Developers />
            </Route>
            <Route exact path={`/status-control`}>
              <StatusControl />
            </Route>
            <Route exact path={`/sentry-example-page`}>
              <SentryExamplePage />
            </Route>
            <Route
              path='/administracion'
              render={(props) => (
                <BackofficeLayout
                  {...props}
                  isLoggedIn={isLoggedIn}
                  userInfo={userInfo}
                  setisLoggedIn={setisLoggedIn}
                />
              )}
            />
            <Route
              path='/'
              render={(props) => (
                <PublicLayout
                  {...props}
                  isLoggedIn={isLoggedIn}
                  setisLoggedIn={setisLoggedIn}
                />
              )}
            />
          </Switch>
        </Suspense>
        <ReduxSnackbars />
      </SearchFiltersProvider>
    </Router>
  );
};

export default Routes;
