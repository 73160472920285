import { createContext, useState } from 'react';

const GeoDataContext = createContext({});

export function GeoDataProvider({ children }) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [departments, setDepartments] = useState([]);

  return (
    <GeoDataContext.Provider
      value={{
        countries,
        setCountries,
        states,
        setStates,
        departments,
        setDepartments,
      }}
    >
      {children}
    </GeoDataContext.Provider>
  );
}

export default GeoDataContext;
