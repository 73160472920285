import { Container, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useNotificationTabStyles } from '../useNotificationTabStyles';

export const DefaultNotification = ({ notification }) => {
  const classes = useNotificationTabStyles();

  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Typography
        variant='body1'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(notification.introduction),
        }}
        className={classes.dialogText}
      />
    </Container>
  );
};
