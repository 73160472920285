import makeStyles from '@mui/styles/makeStyles';

export const useBusinessRoundStyles = makeStyles((theme) => ({
  article: {
    padding: theme.spacing(0, 0, 4),
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 2, 4),
    },
  },
  roundContainer: {
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: '0px 0px 7px 7px',
  },
  roundContentTextInvite: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    margin: 0,
  },
  roundInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'white',
    height: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      display: 'inline',
    },
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  roundIconContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    padding: theme.spacing(3, 0, 4),
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
    alignItems: 'start',
  },
  roundIcon: {
    alignItems: 'end',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8rem',
      justifyContent: 'flex-start',
    },
    '& span': {
      marginLeft: theme.spacing(1),
    },
  },
  roundIconText: {
    marginBottom: '0px',
    fontWeight: '600',
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  timeIcon: {
    width: '25px',
    height: '25px',
  },
  roundBanner: {
    objectFit: 'fill',
    width: '100%',
    maxHeight: '600px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '500px',
    },
  },
  roundContent: {
    margin: theme.spacing(0, 'auto', 4),
    wordBreak: 'break-word',
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
  chatContent: {
    marginTop: theme.spacing(4),
    wordBreak: 'break-word',
    width: '100%',
    flexDirection: 'column',
  },
  bottomLine: {
    [theme.breakpoints.up('md')]: {
      borderBottom: '3px solid ' + theme.palette.secondary.main,
      margin: theme.spacing(0, 0, 3),
      width: '100%',
    },
  },
  roundLocation: {
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    margin: theme.spacing(1, 0, 1),
    '& svg': {
      color: 'red',
    },
  },
  roundLocationText: {
    marginLeft: theme.spacing(1),
  },
  roundDetails: {
    padding: theme.spacing(4, 8),
    backgroundColor: `#F0F0F0`,
    border: `1px solid #D0D0D0`,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2, 4),
    },
  },
  roundName: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  contentCallToAction: { padding: theme.spacing(0, 4) },
  contentRoundParticipateTitle: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  roundParticipateTitle: {
    fontWeight: '400',
    fontSize: '1.7rem',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  roundTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.5rem',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      padding: '0 15px',
    },
  },
  roundActions: {
    fontWeight: '600',
    fontSize: '1.5rem',
    margin: theme.spacing(2, 0, 1),
    wordBreak: 'break-word',
  },
  whenWhere: {
    padding: theme.spacing(2, 0),
    '& p': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
  },
  whenWhereValue: {
    color: theme.palette.secondaryAccent.light,
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },
  roundContentContainer: {
    marginTop: theme.spacing(1),
  },
  pastMessage: {
    marginBottom: theme.spacing(2),
  },
  faqLink: {
    color: theme.palette.secondaryAccent.main,
    fontWeight: 500,
    textDecoration: 'underline',
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '& + *': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem',
    },
  },

  additionalAccessLink: {
    transition: theme.transitions.create('color'),
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    '& p': {
      color: theme.palette.dark.main,
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'lowercase',
      textDecoration: 'underline',
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
      },
    },
  },

  defaultActions: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5, 6),
    borderRadius: theme.spacing(3),
  },
  buttonLink: {
    justifyContent: 'left',
    '& span': {
      color: theme.palette.dark.extraLight,
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down('lg')]: {
        fontSize: '.85rem',
      },
    },
    width: 'fit-content',
    padding: 0,
  },
  nonInterestedAction: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, 6),
  },
  interest: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'red',
    padding: theme.spacing(0, 2, 0, 0),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  interestQuestion: {
    color: theme.palette.dark.extraLight,
    fontWeight: '400',
    fontSize: '14px',
    paddingRight: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
  },
  contentFAQ: { marginLeft: theme.spacing(7) },
  faqLinkSpaced: {
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
  },
  confirmSpace: {
    marginBottom: theme.spacing(6),
    '& p': {
      flex: '50%',
      [theme.breakpoints.down('lg')]: {
        marginBottom: theme.spacing(2),
      },
    },
    '& div': {
      flex: '40%',
      '& button': {
        width: '100%',
      },
    },
  },
  confirmBox: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  actionContent: {
    marginBottom: theme.spacing(2),
  },
  additionalAccess: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
      alignItems: 'left',
      '& p': {
        textAlign: 'left',
      },
    },
  },
  roundMessages: {
    backgroundColor: theme.palette.primary.white,
    borderRadius: theme.shape.borderRadius,
  },
  roundMessagesInsideContainer: {
    width: '100%',
    margin: 'auto',
  },
  newMessageBadge: {
    margin: theme.spacing(2, 0),
    '& p': {
      margin: theme.spacing(0, 2),
    },
  },
  contentUpdateAnswerContent: {
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
  updateAnswerContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  threadContainer: {
    maxHeight: '600px',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '400px',
    },
  },
  replyMessageContainer: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'flex-end',
  },
  replyMessageButton: {
    borderRadius: '30px',
    padding: '3px 25px',
  },
  modalContainer: {
    padding: theme.spacing(4, 6),
    '& *:nth-of-type(1)': {
      marginBottom: 0,
    },
    '& > *:not(:nth-of-type(1))': {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  copyLinkButton: {
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  textLocation: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  textTime: {
    marginTop: '0px',
  },
  meetingDisclaimer: {
    marginLeft: theme.spacing(1),
    fontSize: '0.8rem',
    textAlign: 'end',
  },
  linkContainer: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  sectorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
  },
  sectorBox: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  meetingTextContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  sectorChip: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  userIcon: {
    minWidth: '20px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  contentDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  shareContainer: {
    margin: theme.spacing(1, 0, 0),
  },
  shareIcon: {
    float: 'right',
  },
  shareIconPosition: {
    position: 'relative',
    top: 0,
    right: 0,
  },
  customIconLocation: {
    paddingRight: theme.spacing(1),
    minWidth: '20px',
  },
  messagesTitle: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#8B8B8B',
  },
  turnInfo: {
    fontWeight: '400',
    fontSize: '14px',
  },
  arrowTitleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  arrow: {
    paddingTop: '20px',
    paddingRight: '17px',
  },
  roundParticipateTitleWithArrow: {
    fontWeight: '400',
    fontSize: '1.7rem',

    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  contentUpdateAnswerContentNoMargin: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));
