import makeStyles from '@mui/styles/makeStyles';
import { HIGHLIGHT_IMAGE } from '../../../../utils/Constants';

export const HighlightStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.dark.main,
  },
  containerBackground: {
    backgroundColor: theme.palette.dark.main,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      width: '50%',
    },
  },
  playerWrapper: {
    width: HIGHLIGHT_IMAGE.DEFAULT.WIDTH,
    [theme.breakpoints.up('sm')]: {
      height: HIGHLIGHT_IMAGE.UP_SM.HEIGHT,
      width: HIGHLIGHT_IMAGE.UP_SM.WIDTH,
    },
    [theme.breakpoints.up('md')]: {
      height: HIGHLIGHT_IMAGE.UP_MD.HEIGHT,
      width: HIGHLIGHT_IMAGE.UP_MD.WIDTH,
    },
    [theme.breakpoints.up('lg')]: {
      height: HIGHLIGHT_IMAGE.UP_LG.HEIGHT,
      width: HIGHLIGHT_IMAGE.UP_LG.WIDTH,
    },
  },
  player: {
    aspectRatio: '16/9',
    borderBottom: `1px solid ${theme.palette.dark.main}`,
    borderTop: `1px solid ${theme.palette.dark.main}`,
    height: 'auto !important',
    [theme.breakpoints.up('sm')]: {
      height: `${HIGHLIGHT_IMAGE.UP_SM.HEIGHT}px !important`,
      width: `${HIGHLIGHT_IMAGE.UP_SM.WIDTH}px !important`,
    },
    [theme.breakpoints.up('md')]: {
      height: `${HIGHLIGHT_IMAGE.UP_MD.HEIGHT}px !important`,
      width: `${HIGHLIGHT_IMAGE.UP_MD.WIDTH}px !important`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `${HIGHLIGHT_IMAGE.UP_LG.HEIGHT}px !important`,
      width: `${HIGHLIGHT_IMAGE.UP_LG.WIDTH}px !important`,
    },
  },
  playIcon: {
    fontSize: 74,
    [theme.breakpoints.up('sm')]: {
      fontSize: 68,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 118,
    },
    '& svg': {
      color: 'rgba(255, 255, 255, 0.64)',
    },
  },
  infoWrapper: {
    color: theme.palette.primary.white,
    height: '100%',
    width: '100%',
    padding: theme.spacing(4, 3, 5.25, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3.25, 0, 3.25, 3),
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4.875, 5.375, 7.25),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 17.875, 4.5, 7.25),
    },
  },
  titleWrapper: {},
  title: {
    color: theme.palette.primary.green,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '29px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
      paddingRight: theme.spacing(2.625),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
      lineHeight: '37px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(0),
    },
  },
  abstractTextWrapper: {
    color: theme.palette.primary.white,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    padding: theme.spacing(1, 0, 2, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      lineHeight: '17px',
      padding: theme.spacing(0.5, 2.625, 0.5, 0),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.white,
    border: 'none',
    borderRadius: 30,
    color: theme.palette.secondaryAccent.main,
    padding: theme.spacing(0.625, 2.5, 0.625, 2.5),
    width: 'max-content',
    '& span': { fontWeight: 600, fontSize: 13 },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 26,
      padding: theme.spacing(0.625, 3.5, 0.625, 3.5),
    },
    [theme.breakpoints.up('md')]: {
      '& span': { fontWeight: 600, fontSize: 16 },
    },
  },
  link: {
    marginBottom: theme.spacing(2),
    width: 10,
    '&:focus-visible': {
      outlineOffset: 4,
    },
  },
  falseButton: {
    backgroundColor: theme.palette.primary.white,
    border: 'none',
    borderRadius: 30,
    color: 'black',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: 600,
    padding: theme.spacing(0.625, 2.5, 0.625, 2.5),
    textDecoration: 'none',
    '&:focus-visible': {
      outlineOffset: 4,
    },
    '&:hover': {
      backgroundColor: theme.palette.notification.border,
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 26,
      padding: theme.spacing(0.625, 3.5, 0.625, 3.5),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));
