import makeStyles from '@mui/styles/makeStyles';

export const useNotificationTabStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    boxSizing: 'border-box',
    height: '100%',
  },
  rootHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr min-content',
      gridGap: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  rootHeaderControlLabel: {
    paddingLeft: theme.spacing(2),
    marginLeft: '0px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  search: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85rem',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      padding: theme.spacing(1, 1, 1, 2),
      minWidth: '300px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '400px',
    },
    transition: theme.transitions.create(['border-color', 'background-color']),
    color: theme.palette.dark.extraLight,
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInputRoot: {
    color: 'inherit',
    width: '100%',
    backgroundColor: theme.palette.primary.white,
    flexGrow: 1,
  },
  searchInputInput: {
    transition: theme.transitions.create('width'),
    backgroundColor: theme.palette.primary.white,
  },
  actionsContainerButton: {
    padding: '6px',
    '& span span': {
      display: 'flex',
      flexFlow: 'row-reverse',
    },
  },
  filtersButton: {
    padding: '6px',
    backgroundColor: theme.palette.primary.white,
    borderColor: theme.palette.primary.white,
    paddingLeft: '12px',
    '& span span': {
      display: 'flex',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.white,
      borderColor: theme.palette.primary.white,
    },
  },
  expandableMenu: {
    backgroundColor: theme.palette.secondaryAccent.light,
    borderRadius: 0,
    padding: 0,
    '& li': {
      borderBottom: `1px solid ${theme.palette.secondaryAccent.dark}`,
      padding: 0,
      '&:last-child': {
        borderBottom: 'none',
      },
      '& p': {
        padding: theme.spacing(1),
        fontSize: '.85rem',
        textAlign: 'center',
        display: 'block',
        width: '100%',
        color: theme.palette.primary.white,
        transition: theme.transitions.create('background-color'),
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: theme.palette.secondaryAccent.main,
        },
      },
    },
  },
  notificationList: {
    padding: theme.spacing(3, 2),
    boxSizing: 'border-box',
    height: '100%',
    overflow: 'auto',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },
  notification: {
    border: theme.shape.border,
    borderColor: theme.palette.notification.border,
    backgroundColor: theme.palette.notification.white,
    padding: theme.spacing(2),
    width: '100%',
    borderRadius: '3px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
  },
  notificationUnRead: {
    backgroundColor: theme.palette.notification.unread,
  },
  notificationRead: {
    backgroundColor: theme.palette.notification.read,
  },
  notificationSelected: {
    backgroundColor: theme.palette.notification.read,
  },
  notificationForHeader: {
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    border: 'none',
    borderBottom: theme.shape.border,
    borderColor: ({ forHeader }) =>
      forHeader
        ? theme.palette.notification.borderDark
        : theme.palette.notification.border,
    backgroundColor: 'transparent',
    '& *': {
      textAlign: 'left !important',
    },
  },
  notificationBoxTitle: {
    maxWidth: 'calc(100% - 180px)',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'calc(100% - 115px)',
    },
  },
  notificationTitle: {
    color: ({ forHeader }) =>
      forHeader
        ? theme.palette.notification.titleLight
        : theme.palette.notification.titleDark,
    cursor: 'pointer',
    fontSize: ({ forHeader }) => (forHeader ? '.85rem' : '1rem'),
    [theme.breakpoints.down('lg')]: {
      fontSize: '.85rem !important',
    },
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  notificationTitleUnread: {
    fontWeight: theme.typography.fontWeightBold,
  },
  notificationDate: {
    color: theme.palette.notification.date,
    fontSize: ({ forHeader }) => (forHeader ? '.65rem' : '.85rem'),
    padding: '0 !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  notificationItemButton: {
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: 0,
    border: 'none',
    '& span svg': {
      marginRight: '0',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.gray,
      borderColor: theme.palette.primary.white,
    },
  },
  notificationIcon: {
    cursor: 'pointer',
    margin: ({ forHeader }) =>
      forHeader ? theme.spacing(0, 1) : theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      margin: ({ forHeader }) =>
        forHeader ? theme.spacing(0, 2) : theme.spacing(0, 4),
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 6px !important',
    },
  },
  notificationSelectRoot: {
    margin: 0,
  },
  iconContainer: {
    borderRadius: '50%',
    border: ({ forHeader }) =>
      `1px solid ${
        forHeader ? theme.palette.primary.white : theme.palette.secondary.main
      }`,
  },
  notificationDialogMain: {
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  notificationListRoot: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogButton: {
    padding: theme.spacing(0, 4),
    marginRight: theme.spacing(2),
  },
  buttonBox: {
    marginTop: theme.spacing(2),
  },
  iconAvatar: {
    height: ({ forHeader }) => (forHeader ? '30px' : '40px'),
    width: ({ forHeader }) => (forHeader ? '30px' : '40px'),
  },
  dialogContainer: {
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    },
  },
  notificationHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogText: {
    padding: theme.spacing(4, 0),
  },
  commentBox: {
    border: '1px solid #DBDBDB',
    paddingBottom: '100px',
    paddingLeft: '5px',
  },
}));
