import ability from './Ability';
import { AbilityContext } from './Can';

export function AbilityProvider({ children }) {
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export default AbilityContext;
