import { Container, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterializeCommonStyles } from '../../MaterializeStylesCommons';
import AuthService from '../../services/AuthService';
import { NotificationType } from '../../utils/Constants';
import CloseIcon from '@mui/icons-material/Close';
import { NewFollowerNotification } from './NotificationContent/NewFollowerNotification';
import { TipsNotification } from './NotificationContent/TipsNotification';
import { PublishedNotification } from './NotificationContent/PublishedNotification';
import { FormEventNotification } from './NotificationContent/FormEventNotification';
import { NoticeEventNotification } from './NotificationContent/NoticeEventNotification';
import { DefaultNotification } from './NotificationContent/DefaultNotification';
import { EventNotification } from './NotificationContent/EventNotification';
import { BulkNotificationContent } from './NotificationContent/BulkNotificationContent';
import { useNotificationTabStyles } from './useNotificationTabStyles';
import { HiddenTipContent } from './NotificationContent/HiddenTipContent';

export const NotificationDetails = ({ notification, doClose }) => {
  const { t } = useTranslation();
  const commonStyles = MaterializeCommonStyles();
  const classes = useNotificationTabStyles();
  const firstName = AuthService.getUserInfo().first_name;
  const translationPrefix =
    'messages.notifications.dialogs.' + notification?.type ?? 'default';
  const types = [
    NotificationType.EVENT,
    NotificationType.NOTICE_STATUS,
    NotificationType.NOTICE,
    NotificationType.NEXT_MEETING,
    NotificationType.HIDDEN_TIP,
  ];

  const shouldUseTitleNotification = () => {
    return types.includes(notification?.type);
  };

  const title = () => {
    if (notification?.type === NotificationType.BULK_NOTIFICATION) {
      return null;
    }
    return shouldUseTitleNotification()
      ? notification.title_notification
      : t(translationPrefix + '.title', {
          ...notification,
          entity: notification?.record_relation?.name,
        });
  };

  let content;

  switch (notification?.type) {
    case NotificationType.FOLLOW:
      content = (
        <NewFollowerNotification
          notification={notification}
          firstName={firstName}
          onClose={doClose}
        />
      );
      break;
    case NotificationType.TIP:
      content = (
        <TipsNotification
          notification={notification}
          firstName={firstName}
          onClose={doClose}
        />
      );
      break;
    case NotificationType.PUBLISHED_ITEM:
      content = (
        <PublishedNotification
          notification={notification}
          firstName={firstName}
          onClose={doClose}
        />
      );
      break;
    case NotificationType.FORM_EVENT:
      content = (
        <FormEventNotification
          notification={notification}
          firstName={firstName}
          onClose={doClose}
        />
      );
      break;

    case NotificationType.EVENT:
      content = (
        <EventNotification notification={notification} onClose={doClose} />
      );
      break;

    case NotificationType.BULK_NOTIFICATION:
      content = (
        <BulkNotificationContent
          notification={notification}
          onClose={doClose}
        />
      );

      break;
    case NotificationType.HIDDEN_TIP:
      content = (
        <HiddenTipContent notification={notification} onClose={doClose} />
      );

      break;

    case NotificationType.NOTICE:
    case NotificationType.NOTICE_STATUS:
    case NotificationType.NEXT_MEETING:
      content = (
        <NoticeEventNotification
          notification={notification}
          onClose={doClose}
        />
      );
      break;
    default:
      content = (
        <DefaultNotification notification={notification} onClose={doClose} />
      );
      break;
  }

  return !notification ? (
    <></>
  ) : (
    <Container disableGutters classes={{ root: commonStyles.contentFormRoot }}>
      <Container disableGutters className={classes.notificationHeaderContainer}>
        <Typography variant='h2' className={commonStyles.contentFormTitle}>
          {title()}
        </Typography>
        <IconButton onClick={doClose} disableRipple size='large'>
          <CloseIcon />
        </IconButton>
      </Container>
      <div
        style={{ marginTop: '1rem', minHeight: '100vh' }}
        className={classes.notificationDialogMain}
      >
        {content}
      </div>
    </Container>
  );
};
