import { CallToActionSecondary } from './CallToActionSecondary';
import { CallToActionAdd } from './CallToActionAdd';
import { CallToActionCancel } from './CallToActionCancel';
import { CallToActionGreen } from './CallToActionGreen';
import { CallToActionBlue } from './CallToActionBlue';
import { CallToActionSecondaryBig } from './CallToActionSecondaryBig';
import { CallToActionExtra } from './CallToActionExtra';
import { CallToActionIcon } from './CallToActionIcon';
import { CallToActionIconNotFill } from './CallToActionIconNotFill';
import { CallToActionPrimary } from './CallToActionPrimary';
import { CallToActionExtraWhite } from './CallToActionExtraWhite';
import { CallToActionPrimaryBO } from './CallToActionPrimaryBO';
import { CallToActionUnderlined } from './CallToActionUnderlined';
import { CallToActionDelete } from './CallToActionDelete';
import { CallToActionWithIcon } from './CallToActionWithIcon';
import { CallToActionExtraPrimary } from './CallToActionExtraPrimary';
import { CallToActionGroup } from './CallToActionGroup';

export const CallToAction = (props) => {
  switch (props.kind) {
    case 'withIcon':
      return <CallToActionWithIcon {...props} />;
    case 'group':
      return <CallToActionGroup {...props} />;
    case 'add':
      return <CallToActionAdd {...props} />;
    case 'cancel':
      return <CallToActionCancel {...props} />;
    case 'green':
      return <CallToActionGreen {...props} />;
    case 'blue':
      return <CallToActionBlue {...props} />;
    case 'secondary':
      return <CallToActionSecondary {...props} />;
    case 'secondaryBig':
      return <CallToActionSecondaryBig {...props} />;
    case 'extra':
      return <CallToActionExtra {...props} />;
    case 'extra-white':
      return <CallToActionExtraWhite {...props} />;
    case 'icon':
      return <CallToActionIcon {...props} />;
    case 'primary-icon':
      return <CallToActionIcon {...props} />;
    case 'extra-primary':
      return <CallToActionExtraPrimary {...props} />;
    case 'notfill-icon':
      return <CallToActionIconNotFill {...props} />;
    case 'primaryBO':
      return <CallToActionPrimaryBO {...props} />;
    case 'underlined':
      return <CallToActionUnderlined {...props} />;
    case 'delete':
      return <CallToActionDelete {...props} />;

    case 'primary':
    default:
      return <CallToActionPrimary {...props} />;
  }
};
