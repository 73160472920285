import { useState } from 'react';
import {
  MaterializeCommonStyles,
  TextFieldsCommonPropStyles,
} from '../../MaterializeStylesCommons';
import { useTranslation } from 'react-i18next';
import { Box, Container, Dialog, TextField, Typography } from '@mui/material';
import { CallToAction } from '../CallToAction/CallToAction';
import { useBusinessRoundStyles } from '../Rounds/useBusinessRoundStyles';

function LinkDialog(props) {
  const classes = useBusinessRoundStyles();
  const commonClasses = MaterializeCommonStyles();
  const { t } = useTranslation();
  const {
    onClose,
    open,
    link,
    title = t('rounds.state.confirmed.share'),
  } = props;
  const [textCopied, setTextCopied] = useState(null);

  const hideTextIsCopied = () => {
    setTimeout(function () {
      setTextCopied(null);
    }, 5000);
  };

  const copyLinkToClipboard = () => {
    setTextCopied(t('dialogs.shareDialog.copied'));
    hideTextIsCopied();
    navigator.clipboard.writeText(link);
  };

  return (
    <Dialog maxWidth='xl' open={open} onBackdropClick={onClose}>
      <Container maxWidth='xl'>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          className={classes.modalContainer}
        >
          {title && <Typography align='center'>{title}</Typography>}

          <Box
            display='flex'
            alignItems='center'
            width='100%'
            className={classes.linkContainer}
          >
            <TextField
              {...TextFieldsCommonPropStyles(commonClasses)}
              autoComplete='sharableLink'
              name='sharableLink'
              variant='standard'
              fullWidth
              id='sharableLink'
              value={link}
              disabled
              className={classes.shareableLinkInput}
            />
            {textCopied ? (
              <CallToAction
                kind='extra'
                label={t('rounds.state.confirmed.copied')}
                classes={classes.copyLinkButton}
              />
            ) : (
              <CallToAction
                kind='extra'
                label={t('rounds.state.confirmed.copy-link')}
                classes={classes.copyLinkButton}
                onClickHandler={copyLinkToClipboard}
              />
            )}
          </Box>
          <CallToAction
            kind='secondary'
            label={t('rounds.state.confirmed.done')}
            onClickHandler={onClose}
          />
        </Box>
      </Container>
    </Dialog>
  );
}

export default LinkDialog;
