import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: 'underline',
    borderRadius: theme.artistActionButtons.borderRadius,
    color: theme.palette.secondaryAccent.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondaryAccent.light,
    },
    fontWeight: 'light',
    fontSize: '.85rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const CallToActionUnderlined = (props) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Button
      ref={props.forwardedRef}
      className={[classes.button, extraClasses].join(' ')}
      onClick={(e) => props.onClickHandler?.(e)}
    >
      <Typography variant='button'>
        {props.icon && props.icon(classes.buttonIcon)}
        {props.label}
      </Typography>
    </Button>
  );
};
