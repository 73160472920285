import { Container, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavbarStyles } from './NavbarStyles';

export default function AdminPreNavbar({ logInCloseMenu }) {
  const { t } = useTranslation();
  const classes = NavbarStyles();
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Container className={classes.navbarAdmin}>
      {isUpLg ? (
        <>
          <Container className={classes.adminContainer}>
            <span> {t('navbar.admin.info')}</span>
            <Link to='/administracion' className={classes.btnExtraBlack}>
              {t('navbar.admin.goToBO')}
            </Link>
          </Container>
          <Container className={classes.actionsContainer}>
            <span>{t('navbar.admin.isAnError')}</span>
            <Link
              to='/cerrar-sesion'
              className={classes.adminLogOut}
              onClick={logInCloseMenu}
            >
              {t('navbar.admin.logOut')}
            </Link>
          </Container>
        </>
      ) : (
        <>
          <span className={classes.adminShortInfo}>
            {t('navbar.admin.shortInfo')}
          </span>
          <Link to='/administracion' className={classes.btnExtraBlack}>
            {t('navbar.admin.goToBO')}
          </Link>
        </>
      )}
    </Container>
  );
}
