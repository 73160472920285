import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import {
  // CircularProgress,
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { theme } from './theme';
import { FeaturesProvider } from './context/FeaturesContext';
import { Features } from './Features';
import AppErrorBoundary from './components/AppErrorBoundary/AppErrorBoundary';
import { LoginProvider } from './context/LoginContext';
import { SectorsProvider } from './context/SectorsContext';
import { AbilityProvider } from './context/ability/AbilityContext';
import { CurrentUserProvider } from './context/CurrentUserContext';
import { HelmetProvider } from 'react-helmet-async';
import { FormDataProvider } from './context/FormDataContext';
import { GeoDataProvider } from './context/GeoDataContext';

export default function App() {
  const [loginData, setLoginData] = useState({
    isOpen: false,
    previousLocation: '',
  });
  const loginValue = { loginData, setLoginData };

  return (
    <GeoDataProvider>
      <FormDataProvider>
        <AbilityProvider>
          <HelmetProvider>
            <SectorsProvider>
              <FeaturesProvider value={Features}>
                <CurrentUserProvider>
                  <LoginProvider value={loginValue}>
                    <StylesProvider injectFirst>
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <AppErrorBoundary>
                            <BrowserRouter
                              getUserConfirmation={(message, callback) => {
                                const allowTransition = window.confirm(message);
                                callback(allowTransition);
                              }}
                            >
                              <Routes />{' '}
                            </BrowserRouter>
                          </AppErrorBoundary>
                        </ThemeProvider>
                      </StyledEngineProvider>
                    </StylesProvider>
                  </LoginProvider>
                </CurrentUserProvider>
              </FeaturesProvider>
            </SectorsProvider>
          </HelmetProvider>
        </AbilityProvider>
      </FormDataProvider>
    </GeoDataProvider>
  );
}

// const Loader = () => (
//   <div>
//     {/* <img src={logo} className="App-logo" alt="logo"/> */}
//     {/* <div>Cargando MICA...</div> */}
//     <CircularProgress />
//   </div>
// );
