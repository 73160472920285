import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: ({ withBorder }) => (withBorder ? theme.shape.border : ''),
    width: '100%',
    height: '100%',
  },
  'size-sm': {
    width: 15,
    height: 15,
    [theme.breakpoints.up('md')]: {
      width: 20,
      height: 20,
    },
  },
  'size-md': {
    width: 35,
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: 50,
      height: 50,
    },
  },
  'size-lg': {
    width: 70,
    height: 70,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('lg')]: {
      width: 160,
      height: 160,
    },
  },
  'size-xl': {
    width: 100,
    height: 100,
    [theme.breakpoints.up('md')]: {
      width: 160,
      height: 160,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
      height: 200,
    },
  },
  'size-xxl': {
    width: 100,
    height: 100,
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.up('lg')]: {
      width: 300,
      height: 300,
    },
  },
  'size-max': {
    width: '100%',
    height: '100%',
    maxHeight: '200px',
    '& img': {
      objectFit: 'contain',
    },
  },
}));

function PortfolioImage(props) {
  const classes = useStyles({ withBorder: props.withBorder ?? true });

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Avatar
      alt={props.alt}
      src={props.imageSrc}
      variant={props.square ? 'square' : 'circle'}
      className={[
        props.size ? classes[`size-${props.size ?? 'sm'}`] : '',
        classes.avatar,
        ...extraClasses,
      ].join(' ')}
    />
  );
}

export default PortfolioImage;
