import { ErrorOutline } from '@mui/icons-material';
import { MaterializeCommonStyles } from '../MaterializeStylesCommons';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLoginStyles } from '../components/Login/LoginStyles';

export default function LoginValidateMail({ onClose, email }) {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  const loginClasses = useLoginStyles();
  const goToReSend = () => {
    onClose();
  };

  return (
    <Container className={classes.loginWarning}>
      <ErrorOutline htmlColor={'orange'} />
      <div>
        <Typography variant='body1'>{t('login.inactive')}</Typography>
        <div className={loginClasses.resendContainer}>
          <Typography variant='body1'>
            {t('login.form.links.didnt-receive')}
          </Typography>
          <Link
            onClick={goToReSend}
            to={`/registro/validado-incorrecto/${email}`}
            className={classes.formAdditionalLinks}
          >
            {t('login.form.links.re-send')}
          </Link>
        </div>
      </div>
    </Container>
  );
}
