import { Avatar, Badge } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../../utils/Constants';
import {
  buildInitials,
  formatDate,
  getChatNickname,
  getChatNicknameMessages,
} from '../../utils/formHelper';
import { MessagePreviewStyles } from './MessagePreviewStyles';

const MessagePreview = ({ conversation, handleClose }) => {
  const {
    to_user,
    id_conversation,
    last_message,
    unread_messages_in_conv,
    node,
  } = conversation;
  const classes = MessagePreviewStyles();
  const { t } = useTranslation();

  const getName = () => {
    if (conversation?.node) {
      return getChatNicknameMessages(conversation);
    }
    return (
      to_user?.portfolio_published?.project_name ??
      to_user?.first_name + ' ' + to_user?.last_name
    );
  };

  const getAvatar = () => {
    const toUser = to_user;
    const avatarUrl =
      node?.image?.versions[0]?.url ||
      to_user?.portfolio_published?.image?.versions[0]?.url;
    if (avatarUrl) {
      return <Avatar className={classes.avatar} src={avatarUrl} />;
    } else {
      const firstName = to_user?.first_name
        ? getChatNickname(toUser)
        : getChatNicknameMessages(conversation);
      const lastName = to_user?.last_name ?? '';
      return (
        <Avatar className={classes.avatar}>
          {buildInitials(firstName, lastName)}
        </Avatar>
      );
    }
  };

  return (
    <Link
      to={'/mensajes/conversacion/' + id_conversation}
      className={classes.container}
      onClick={() => handleClose()}
      aria-label={t('accessibility.navbar.messageIcon.messageFrom', {
        user: to_user?.first_name
          ? to_user?.first_name + to_user?.last_name
          : getChatNicknameMessages(conversation),
      })}
    >
      {getAvatar()}

      <div className={classes.infoMessage}>
        <div className={classes.nameUser}>{getName()}</div>
        <div className={classes.message}>{last_message?.message}</div>
      </div>

      <div className={classes.infoEnd}>
        <div className={classes.badgeContainer}>
          <div className={classes.badge}>
            <Badge
              className={classes.badge}
              badgeContent={unread_messages_in_conv}
              color='secondary'
            />
          </div>
        </div>
        <div className={classes.containerDate}>
          {formatDate(
            last_message?.date_send,
            DATE_FORMAT.STANDARD_SHORT_YEAR,
            i18next?.language
          )}
        </div>
      </div>
    </Link>
  );
};

export default MessagePreview;
