import { Box, Container, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CallToAction } from '../../CallToAction/CallToAction';
import { useNotificationTabStyles } from '../useNotificationTabStyles';

export const FormEventNotification = ({ notification, onClose }) => {
  const { t } = useTranslation();
  const classes = useNotificationTabStyles();
  const history = useHistory();
  const theme = useTheme();

  const goToForm = () => {
    onClose();
    history.push('/tu-mica/rondas/perfil/negocio');
  };
  return (
    <Container disableGutters maxWidth='md' className={classes.dialogContainer}>
      <Box
        display='flex'
        alignItems='center'
        style={{ margin: theme.spacing(3, 0) }}
      >
        <Typography variant='body1'>
          {t('messages.notifications.dialogs.form_event.text-1')}
          <br />
          <br />
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='flex-start'>
        <CallToAction
          kind='primary'
          onClickHandler={goToForm}
          classes={[classes.dialogButton]}
          label={t('messages.notifications.dialogs.form_event.button.form')}
        />
      </Box>
    </Container>
  );
};
