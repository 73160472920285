import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import classnames from 'classnames';

import { CallToAction } from '../../../components/CallToAction/CallToAction';
import { CustomIcon } from '../../../components/CustomIcons/CustomIcons';
import { MaterializeCommonStyles } from '../../../MaterializeStylesCommons';
import { createFriendlyLink, showPortfolioId } from '../../../utils/utils';
import { useRoundsBOStyles } from '../../../components/backoffice/businessRound/useRoundsBOStyles';
import {
  isPending,
  isPortfolioApproved,
  shouldShowUnapprovedPortfolioButton,
} from '../helpers/validationHelper';
import { Box } from '@mui/system';
import { EVENT_TYPE_BO_ROUTE } from '../../../utils/Constants';
import { ValidationTooltip } from '../../../components/CustomTooltip/ValidationTooltip';
import { useSectorStyles } from '../../../components/Sectors/useSectorStyles';

export const PortfolioPublishedLink = ({ id }) => {
  const path = buildPortfolioPublishedPath(id);
  return <OpenPublishedLink path={path} />;
};

export const SectionPublishedLink = ({ row }) => {
  const id = showPortfolioId(row);
  const path = buildSectionPublishedPath(id, row?.id_section);
  return <OpenPublishedLink path={path} />;
};

export const ArticlePublishedLink = ({ row }) => {
  const id = showPortfolioId(row);
  const path = buildArticlePublishedPath(id, row?.id_article);
  return <OpenPublishedLink path={path} />;
};

export const buildPortfolioPublishedPath = (id) => {
  return `/usuario/${id}`;
};
export const buildSectionPublishedPath = (id, id_section) => {
  return `/usuario/${id}/portfolio/seccion/${id_section}`;
};
export const buildArticlePublishedPath = (id, id_article) => {
  return `/usuario/${id}/catalogo/articulo/${id_article}`;
};
export const buildActivityPublishedPath = (id_activity) => {
  return `/actividades/${id_activity}`;
};
export const buildNoveltyPublishedPath = (id_novelty) => {
  return `/notas/${id_novelty}`;
};
export const buildCyclePublishedPath = (id_item) => {
  return `/actividades/ciclos/${id_item}`;
};
export const buildSectorPublishedPath = (id_item) => {
  return `/sector/${id_item}`;
};
export const buildPagePublishedPath = (link) => {
  return `${link}`;
};
export const buildDomainPublishedPath = (id, title) => {
  return `/programa/${createFriendlyLink(id, title)}`;
};

export const buildPublicNodeUrl = (idDomain, idNode, title) => {
  return `/programa/${idDomain}/${createFriendlyLink(idNode, title)}`;
};

export const buildBOBreadcrumbPath = (type) => {
  return `/administracion/${EVENT_TYPE_BO_ROUTE[type]}/:eventId/participantes`;
};

export const buildBOBreadcrumbUrl = (type, eventId) => {
  return `/administracion/${EVENT_TYPE_BO_ROUTE[type]}/${eventId}/participantes/`;
};

export const buildBONodeListUrl = (idDomain) => {
  return `/administracion/programas/${idDomain}/puntos-de-accion`;
};

export const buildBODomainLandingUrl = (idDomain) => {
  return `/administracion/programas/${idDomain}/landing`;
};

export const buildBODomainTabUrl = (idDomain, tabRoute) => {
  return `/administracion/programas/${idDomain}/${tabRoute}`;
};

export const buildBONodeTabUrl = (idDomain, idNode, tabRoute) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/${tabRoute}`;
};

export const buildNewProgramUrl = (idDomain) => {
  return `/administracion/programas/detalle/${idDomain}`;
};

export const buildNewNodeUrl = (idDomain) => {
  return `/administracion/programas/${idDomain}/detalle-de-punto-de-accion/`;
};

export const buildPatchNodeUrl = (idDomain, idNode) => {
  return `/administracion/programas/${idDomain}/detalle-de-punto-de-accion/${idNode}`;
};

export const buildBONodeLandingUrl = (idDomain, idNode) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/landing`;
};

export const buildBONodeFollowersUrl = (idDomain, idNode) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/seguidores`;
};

export const buildBONodeLandingHighlightUrl = (idDomain, idNode) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/landing/destacado`;
};

export const buildBODomainLandingMultipleHighlightUrl = (idDomain) => {
  return `/administracion/programas/${idDomain}/landing/destacado-secundario`;
};

export const buildBODomainPatchUrl = (idDomain) => {
  return `/administracion/programas/detalle/${idDomain}`;
};

export const buildBODomainLandingSection = (idDomain, sectionName) => {
  return `/administracion/programas/${idDomain}/landing/${sectionName}`;
};

export const buildBONodeLandingSection = (idDomain, idNode, sectionName) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/landing/${sectionName}`;
};

export const buildBODomainMultipleHighlight = (idDomain) => {
  return `/administracion/programas/${idDomain}/landing/slider-secundario`;
};

export const buildBONodeMultipleHighlight = (idDomain, idNode) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/landing/slider-secundario`;
};

export const buildBODomainMultipleHighlightSlide = (idDomain, idSlide) => {
  return `/administracion/programas/${idDomain}/landing/slider-secundario/${idSlide}`;
};

export const buildBOProgramPresentation = (idDomain) => {
  return `/administracion/programas/${idDomain}/landing/presentacion-general`;
};

export const BO_PROGRAMS_LIST_URL = '/administracion/programas/lista';
export const BO_PROGRAM_TAB_NAME =
  '/administracion/programas/:idProgram/:tabName?';
export const BO_NODE_TAB_NAME =
  '/administracion/programas/:idProgram/punto-de-accion/:idPoint/:tabName?';
export const BO_PROGRAM_TAB_NAME_PRESENTATION_PATH =
  '/administracion/programas/:idProgram/:tabName?/presentacion-general';
export const BO_PROGRAM_NEW_PATH =
  '/administracion/programas/detalle/:idProgram?';
export const BO_PROGRAM_NEW = '/administracion/programas/detalle/';
export const BO_PROGRAM_LANDING_PATH =
  '/administracion/programas/:idProgram/landing';
export const BO_PROGRAM_LANDING_SECTION_PATH =
  '/administracion/programas/:idProgram/landing/:landingSection?';
export const BO_NODE_PATCH_PATH =
  '/administracion/programas/:idProgram/detalle-de-punto-de-accion/:idPoint?';
export const BO_PROGRAM_ACTION_POINTS =
  '/administracion/programas/:idProgram/puntos-de-accion';
export const BO_NODE_LANDING_PATH =
  '/administracion/programas/:idProgram/punto-de-accion/:idPoint/landing/';
export const BO_NODE_LANDING_SECTION_PATH =
  '/administracion/programas/:idProgram/punto-de-accion/:idPoint/landing/:landingSection?';

export const buildBONodeMultipleHighlightSlide = (
  idDomain,
  idNode,
  idSlide
) => {
  return `/administracion/programas/${idDomain}/punto-de-accion/${idNode}/landing/slider-secundario/${idSlide}`;
};

export const OpenPublishedLink = (props) => {
  const { path, children } = props;
  return (
    <Link to={path}>
      <OpenPublishedButton {...props}>{children}</OpenPublishedButton>
    </Link>
  );
};

export const OpenPublishedButton = (props) => {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  const { title = t('backoffice.shared.buttons.openPublished'), children } =
    props;
  return (
    <span>
      <CallToAction
        classes={classes.boIconButtons}
        kind='notfill-icon'
        icon={
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/IconoIr.svg`}
            size={'20'}
          />
        }
        title={title}
        onClickHandler={props?.onClickHandler}
      />
      {children}
    </span>
  );
};
export const OpenExternalLink = ({ url, target = '_blank' }) => {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  return (
    <a href={url} target={target} rel='noreferrer'>
      <CallToAction
        classes={classes.boIconButtons}
        kind='notfill-icon'
        icon={
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/IconoIr.svg`}
            size={'20'}
          />
        }
        title={t('backoffice.shared.buttons.openExternalLink')}
      />
    </a>
  );
};
export const LinkWithRoute = ({ path, children, classes }) => {
  const baseClasses = MaterializeCommonStyles();
  const extraClasses = Array.isArray(classes) ? classes : [classes];
  return (
    <Link
      className={classnames(baseClasses.noDecorationLink, ...extraClasses)}
      to={path}
    >
      {children}
    </Link>
  );
};
export const OpenHistoryButton = ({ dialogOpen }) => {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  return (
    <div className={classes.historyContainer} onClick={() => dialogOpen()}>
      <CallToAction
        classes={classnames(classes.boIconButtons, classes.iconHistory)}
        kind='notfill-icon'
        icon={
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/IconoHistorialTablasBO.svg`}
            size={'18'}
          />
        }
        title={t('backoffice.shared.buttons.openHistory')}
      />
      <span className={classes.item}>
        {t('backoffice.userFile.table.viewHistory')}
      </span>
    </div>
  );
};

export const OpenFileButton = ({ id_user }) => {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  const path = `/administracion/usuarios/ficha/${id_user}`;
  return (
    <Link to={path}>
      <CallToAction
        classes={classes.boIconButtons}
        kind='notfill-icon'
        icon={
          <CustomIcon
            iconSrc={`${process.env.PUBLIC_URL}/icons/userfile.svg`}
            size={'20'}
          />
        }
        title={t('backoffice.shared.buttons.userfile')}
      />
    </Link>
  );
};

export const UserStatusButton = ({ row, canWrite, onClickHandler }) => {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();
  const iconSrcActive = `userCircleCrossedOut.svg`;
  const iconSrcInactive = `userCircleCrossedOutRed.svg`;
  const iconSrc = `${process.env.PUBLIC_URL}/icons/${
    row.active ? iconSrcActive : iconSrcInactive
  }`;
  return (
    <CallToAction
      classes={classes.boIconButtons}
      kind='notfill-icon'
      icon={<CustomIcon iconSrc={iconSrc} size={'22'} />}
      onClickHandler={() => onClickHandler()}
      title={t(
        row.active
          ? 'backoffice.shared.buttons.activeUser'
          : canWrite
          ? 'backoffice.shared.buttons.inactiveUser'
          : 'backoffice.shared.buttons.permanentInactiveUser'
      )}
    />
  );
};

export const EditButton = ({ row, onClickHandler, title = '' }) => {
  const classes = MaterializeCommonStyles();
  return (
    <CallToAction
      classes={classes.boIconButtons}
      kind='notfill-icon'
      icon={<EditIcon />}
      onClickHandler={() => row.active && onClickHandler()}
      title={title}
    />
  );
};

export const buildAddParticipant = (role) => {
  return `agregar/${role}`;
};

export const OpenAddParticipant = ({ label, roleOptions, TRANSLATION_KEY }) => {
  const classesCustom = useRoundsBOStyles();
  const { t } = useTranslation();
  const [dropdown, setDropdown] = useState(false);

  const handleBlur = ({ currentTarget, relatedTarget }) => {
    if (!currentTarget.contains(relatedTarget)) {
      setDropdown(!dropdown);
    }
  };
  return (
    <div>
      <Box className={classesCustom.addParticipantContent}>
        <CallToAction
          classes={classesCustom.actionButton}
          aria-label='moderator'
          variant='contained'
          color='primary'
          kind='primary'
          label={label}
          onClickHandler={() => setDropdown(!dropdown)}
        />
        {dropdown && (
          <Box className={classesCustom.addParticipantBox}>
            <Paper className={classesCustom.addParticipantMenu}>
              <MenuList
                autoFocus
                onBlur={(e) => {
                  handleBlur(e);
                }}
                elevation={0}
              >
                {roleOptions.map((role) => (
                  <Link
                    to={buildAddParticipant(role)}
                    className={classesCustom.addParticipantLink}
                    key={role}
                  >
                    <MenuItem value={role}>
                      {t(TRANSLATION_KEY + 'participants.role.' + role)}
                    </MenuItem>
                  </Link>
                ))}
              </MenuList>
            </Paper>
          </Box>
        )}
      </Box>
    </div>
  );
};

export const OpenAddTurn = ({ role, label }) => {
  const classesCustom = useRoundsBOStyles();
  return (
    <Link to={buildAddParticipant(role)} className={classesCustom.actionButton}>
      <CallToAction
        classes={classesCustom.actionButton}
        aria-label='moderator'
        variant='contained'
        color='primary'
        kind='primary'
        label={label}
      />
    </Link>
  );
};

export const OpenSchedule = ({ path, label }) => {
  const classesCustom = useRoundsBOStyles();
  return (
    <Link to={path} className={classesCustom.addParticipantButton}>
      <CallToAction
        classes={classesCustom.openSchedule}
        aria-label='schedule'
        variant='contained'
        kind='blue'
        label={label}
      />
    </Link>
  );
};

export const buildEventAddParticipant = (idEvent, role, isMeeting, nodeId) => {
  return nodeId
    ? `../../${idEvent}/participantes/agregar/${role}`
    : isMeeting
    ? `../${idEvent}/participantes/agregar/${role}`
    : `${idEvent}/participantes/agregar/${role}`;
};
export const OpenPlusParticipant = ({
  eventId,
  role,
  tooltipTitle,
  isMeeting,
  nodeId,
}) => {
  const classesCustom = useRoundsBOStyles();
  return (
    <Link to={buildEventAddParticipant(eventId, role, isMeeting, nodeId)}>
      <IconButton className={classesCustom.iconButtons} size='large'>
        <Tooltip arrow title={tooltipTitle}>
          <AddCircleOutlineIcon fontSize='small' />
        </Tooltip>
      </IconButton>
    </Link>
  );
};

export const buildEventParticipants = (idEvent, isMeeting, nodeId) => {
  return nodeId
    ? `../../${idEvent}/participantes/`
    : isMeeting
    ? `../${idEvent}/participantes/`
    : `${idEvent}/participantes/`;
};

export const OpenListParticipants = ({
  eventId,
  tooltipTitle,
  isMeeting,
  nodeId,
}) => {
  const classesCustom = useRoundsBOStyles();
  return (
    <Link to={buildEventParticipants(eventId, isMeeting, nodeId)}>
      <IconButton className={classesCustom.iconButtonEdit} size='large'>
        <Tooltip arrow title={tooltipTitle}>
          <ListIcon />
        </Tooltip>
      </IconButton>
    </Link>
  );
};

export const ViewPendingPortfolioButton = ({
  togglePortfolioModal,
  row,
  index,
}) => {
  const { t } = useTranslation();
  const classes = useSectorStyles();
  return (
    <Tooltip title={t('backoffice.warnings.unapproved-portfolio')}>
      <ButtonBase
        className={classes.viewPortfolio}
        onClick={() => togglePortfolioModal(row, index)}
      >
        {t('backoffice.table.view-portfolio')}
      </ButtonBase>
    </Tooltip>
  );
};

export const ValidationsButton = ({
  togglePortfolioModal,
  toggleActionModal,
  row,
  index,
}) => {
  const { t } = useTranslation();
  const classes = useSectorStyles();
  return (
    <div>
      {isPending(row?.workflow) &&
        (shouldShowUnapprovedPortfolioButton(row) ? (
          <ViewPendingPortfolioButton
            row={row}
            index={index}
            togglePortfolioModal={togglePortfolioModal}
          />
        ) : (
          <ValidationTooltip row={row}>
            <div>
              <ButtonBase
                disabled={!isPortfolioApproved(row)}
                className={classnames(
                  isPortfolioApproved(row)
                    ? classes.viewButton
                    : classes.viewDisabledButton
                )}
                onClick={() => toggleActionModal(row, index)}
              >
                {t('backoffice.table.view')}
              </ButtonBase>
            </div>
          </ValidationTooltip>
        ))}
    </div>
  );
};
