import AuthService from './AuthService';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';

const MESSENGER_API_URL = '/messenger/';
class MessengerService {
  getNotifications(params) {
    let filters = '';
    filters += params.unOpened ? 'opened=false' : '';
    filters += params.title
      ? '&title_notification=' + encodeURIComponent(params.title)
      : '';
    if (!!params.type) {
      params.type.map((type) => (filters += '&type=' + type));
    }
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    return axiosInstanceWithInterceptors.get(
      MESSENGER_API_URL + 'notification?' + filters
    );
  }

  getNotification(id) {
    return axiosInstanceWithInterceptors.get(
      MESSENGER_API_URL + 'notification/' + id
    );
  }

  getMassiveNotifications(params) {
    let filters = '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    filters += params.state ? '&state=' + params.state : '';
    return axiosInstanceWithInterceptors.get(
      MESSENGER_API_URL + 'notification/bulk/?' + filters
    );
  }

  getMassiveNotification(id) {
    return axiosInstanceWithInterceptors.get(
      MESSENGER_API_URL + 'notification/bulk/' + id
    );
  }

  getMassiveNotificationRecipients(params) {
    let filters = new URLSearchParams();
    if (params.user_data) filters.append('user_data', params.user_data);
    if (params.sort_order) filters.append('sort_order', params.sort_order);
    return axiosInstanceWithInterceptors.get(
      MESSENGER_API_URL +
        'notification/bulk/report/' +
        params.id +
        '?' +
        filters.toString()
    );
  }

  initMassiveNotification = async () => {
    return await axiosInstanceWithInterceptors.post(
      `${MESSENGER_API_URL}notification/bulk/`,
      null
    );
  };

  uploadMassiveNotificationImage(file, fileData) {
    const url = `${MESSENGER_API_URL}notification/bulk/image/${fileData.id}`;
    const body = file;
    body.append('alt_text', fileData.alt_text);
    body.append('description', fileData.description);
    return axiosInstanceWithInterceptors.post(url, body);
  }

  deleteMassiveNotificationImage(IdNotification) {
    const url = `${MESSENGER_API_URL}notification/bulk/image/${IdNotification}`;
    return axiosInstanceWithInterceptors.delete(url);
  }

  getMassiveNotificationReport(id) {
    return axiosInstanceWithInterceptors.get(
      `${MESSENGER_API_URL}notification/bulk/export/` + id,
      AuthService.getAuthHeaderBlob()
    );
  }

  saveMassiveNotification(id, data) {
    return axiosInstanceWithInterceptors.patch(
      `${MESSENGER_API_URL}notification/bulk/${id}`,
      data
    );
  }

  changeStateMassiveNotification = async (data, id, newState) => {
    return await axiosInstanceWithInterceptors.patch(
      `${MESSENGER_API_URL}notification/bulk/${id}/${newState}`,
      data
    );
  };

  sendMassiveNotification = async (id, status) => {
    return await axiosInstanceWithInterceptors.post(
      `${MESSENGER_API_URL}notification/bulk/send/${id}?action=${status}`,
      null
    );
  };

  deleteMassiveNotification(id) {
    const url = `${MESSENGER_API_URL}notification/bulk/${id}`;
    return axiosInstanceWithInterceptors.delete(url);
  }

  duplicateMassiveNotification(id) {
    const url = `${MESSENGER_API_URL}notification/duplicate/${id}`;
    return axiosInstanceWithInterceptors.post(url, null);
  }

  deleteMassiveNotificationRecipients(id, users) {
    const url = `${MESSENGER_API_URL}notification/bulk/${id}`;
    return axiosInstanceWithInterceptors.delete(url, {
      data: users,
    });
  }

  patchNotificationOpened(params) {
    return axiosInstanceWithInterceptors.patch(
      MESSENGER_API_URL +
        'notification/open/' +
        params.id +
        '/' +
        params.opened,
      null
    );
  }

  patchMultipleNotificationsOpened(params) {
    let idsToPatch = '?id_notification=';
    if (params.ids.length !== 0) {
      idsToPatch += params.ids.join('&id_notification=');
    }
    return axiosInstanceWithInterceptors.patch(
      MESSENGER_API_URL + 'notification/open/' + params.opened + idsToPatch,
      null
    );
  }

  //conversation
  getConversations(params) {
    let filters = '';
    if (params) {
      filters = '?';
      filters += params.unOpened ? 'opened=false' : '';
      filters += params.unStored ? '&store=false' : '';
      filters += params.pageSize ? '&page_size=' + params.pageSize : '';
      filters +=
        typeof params.pageNumber === 'number'
          ? '&page_num=' + params.pageNumber
          : '';
      filters += params.title ? '&q=' + encodeURIComponent(params.title) : '';
    }

    return axiosInstanceWithInterceptors.get(
      `${MESSENGER_API_URL}conversation` + filters
    );
  }
  createConversation(conversation) {
    return axiosInstanceWithInterceptors.post(
      `${MESSENGER_API_URL}conversation`,
      conversation
    );
  }
  editConversation(params) {
    return axiosInstanceWithInterceptors.patch(
      `${MESSENGER_API_URL}conversation/${params.id}`,
      params.status
    );
  }

  patchMultipleConversations(conversations) {
    return axiosInstanceWithInterceptors.patch(
      `${MESSENGER_API_URL}conversation`,
      conversations
    );
  }
  getChat(id_conversation, isNode) {
    return axiosInstanceWithInterceptors.get(
      `${MESSENGER_API_URL}chat/${id_conversation}?node=${isNode}`
    );
  }
  createChat(id_conversation, message) {
    return axiosInstanceWithInterceptors.post(
      `${MESSENGER_API_URL}chat/${id_conversation}`,
      message
    );
  }
  markChatDeleted(id_conversation, id_chat) {
    return axiosInstanceWithInterceptors.patch(
      `${MESSENGER_API_URL}chat/deleted/${id_conversation}/${id_chat}`,
      null
    );
  }
  getUnread() {
    return axiosInstanceWithInterceptors.get(
      `${MESSENGER_API_URL}cache/unread`
    );
  }
}

// eslint-disable-next-line
export default new MessengerService();
