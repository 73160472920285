import React from 'react';
import SlickCarousel from '../../../../components/SlickCarousel/slickCarousel';
import { useState } from 'react';
import { HomeSectorsArrow } from './HomeSectorsArrows';
import leftIcon from '../../../../icons/CaretCircleLeft.svg';
import rightIcon from '../../../../icons/CaretCircleRight.svg';
import { isTouchDevice } from '../../../../utils/utils';
import { useSlickCarouselStyles } from '../../../../components/SlickCarousel/useSlickCarouselStyles';

function SlickCarrouselInHome(props) {
  const {
    slidesToShow = 1,
    children,
    name,
    height,
    initInfinity = !isTouchDevice(),
  } = props;
  const classes = useSlickCarouselStyles();
  const [infinity, setInfinity] = useState(initInfinity);
  const [tabMode, setTabMode] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      setInfinity(false);
      setTabMode(true);
    }
  };
  return (
    <div tabIndex={-1} style={{ paddingBottom: '8px' }}>
      <div
        tabIndex={0}
        onKeyDown={handleKeyDown}
        aria-label='Carrousel con elementos'
        className={classes.focusCarrousel}
      >
        <SlickCarousel
          className={'slider variable-width ' + name}
          arrows={true}
          infinite={infinity}
          rightArrow={
            <HomeSectorsArrow
              src={rightIcon}
              isLeft={false}
              home={false}
              isArrowDisabled={tabMode}
              height={height}
            />
          }
          leftArrow={
            <HomeSectorsArrow
              src={leftIcon}
              isLeft={true}
              home={false}
              isArrowDisabled={tabMode}
              height={height}
            />
          }
          slidesToScroll={slidesToShow}
          slidesToShow={slidesToShow}
        >
          {children}
        </SlickCarousel>
      </div>
    </div>
  );
}

export default SlickCarrouselInHome;
