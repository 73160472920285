import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from "i18next-http-backend";

//Ver demo básica online: https://codesandbox.io/s/1zxox032q
// VER uso y configuraciones acá: https://react.i18next.com/latest/using-with-hooks
// create-react-app no deja importar carpetas de /public asi que me traje la carpeta para /src
// Ver backoffice de translations acá https://github.com/i18next/react-i18next/tree/master/example/locize

//Configuraciones de las translations
import translationES from './locales/es/translation.json';
import translationEN from './locales/en/translation.json';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend) // para activar y que cargue asincronicamente desde /public/localeesto hay que comentar en el init resources
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'es',
    supportedLngs: ['en', 'es'],
    debug: false,
    keySeparator: '.', // we use content as keys
    parseMissingKeyHandler: (key) => {
      return process.env.NODE_ENV !== 'development' ? '' : `"${key}"`;
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
