import { Box, InputBase, Modal } from '@mui/material';
import classnames from 'classnames';
import { NavbarStyles } from './NavbarStyles';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import SearchFiltersContext from '../context/SearchFiltersContext';
import { urlParameter } from '../utils/Constants';
import { CustomIcon } from '../components/CustomIcons/CustomIcons';

export const SearchModal = ({
  setSearchQuery,
  isMobile,
  handleClose,
  searchQuery,
  searchOpen,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();
  const { remplaceRouteParameter } = useContext(SearchFiltersContext);

  const goToSearch = () => {
    handleClose(true);
    remplaceRouteParameter(urlParameter.QUERY, searchQuery);
  };
  const iconSrc = `${process.env.PUBLIC_URL}/icons/LupitaBlack.svg`;

  return (
    <Modal open={searchOpen} onClose={handleClose}>
      <Box className={classes.searchBoxInputExpanded}>
        <form id='search' role='search' onSubmit={(e) => e.preventDefault()}>
          <div
            className={classnames(
              classes.search,
              classes.searchInputExpanded,
              classes.searchBottomLine
            )}
          >
            <InputBase
              placeholder={
                isMobile
                  ? t('navbar.search.placeholder-mobile')
                  : t('navbar.search.placeholder')
              }
              style={{
                width: '100%',
                fontSize: '24px',
                fontWeight: 700,
              }}
              autoFocus={true}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  goToSearch();
                }
              }}
              value={searchQuery}
              inputProps={{
                'aria-label': t('navbar.search.aria-label'),
              }}
              type='search'
              id='search-input'
              spellCheck='false'
              autoComplete='off'
            />
            <div className={classes.searchIconContainerModal}>
              <div
                onClick={() => searchOpen && goToSearch()}
                className={classes.searchIconModal}
              >
                <CustomIcon iconSrc={iconSrc} color='transparent' size={15} />
              </div>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
