import makeStyles from '@mui/styles/makeStyles';

export const useRoundsBOStyles = makeStyles((theme) => ({
  questionHeader: {
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(2),
    justifyContent: 'center',
    display: 'flex',
  },
  qaContainer: {
    margin: theme.spacing(0, 4),
  },
  rowContainer: {
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  filterGrouperDate: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },

  textContainer: {
    marginBottom: theme.spacing(2),
  },
  titleChatModal: {
    padding: theme.spacing(2, 4, 0, 4),
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  iconButtons: {
    padding: 0,
    marginLeft: '-20px',
  },
  iconDarkUseSite: {
    borderRadius: '50%',
    cursor: 'pointer',
    padding: theme.spacing(1.4375, 1.625, 1.4375, 1.625),
    marginBottom: '15px !important',
    backgroundColor: 'black !important',
    width: 'fit-content',
    height: 'fit-content',
    border: 'none',
    color: 'white !important',
    '&:hover': {
      color: 'white',
    },
  },
  iconUseSiteTransparentBackground: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: 'fit-content',
    height: 'fit-content',
    color: '#000000',
    padding: '0px',
    border: '0px',

    '&:hover': {
      color: '#000000',
    },
  },
  iconUseSiteTransparentBackgroundMargin: {
    backgroundColor: 'transparent',
    width: 'fit-content',
    height: 'fit-content',
    color: '#000000',
    padding: '0px',
    border: '0px',
    margin: '10px',
    '&:hover': {
      color: '#000000',
    },
  },
  containerExportExcel: { position: 'absolute', right: '20px', bottom: '20px' },
  containerPieGraph: { position: 'relative' },
  containerPortfolioGraph: { textAlign: 'center', paddingBottom: '30px' },
  iconEditButton: {
    padding: 0,
    marginLeft: '-6px',
  },
  iconButtonEdit: {
    padding: 0,
  },
  iconUseSite: {
    marginTop: '15px',
  },
  participantsDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& div': {
      textAlign: 'end',
      minWidth: '55px',
      paddingRight: '24px',
    },
  },
  roundsTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  cyclesTitle: {
    fontSize: '26px',
    fontWeight: 600,
    paddingTop: '20px',
  },
  statsTitle: {
    fontSize: '2rem',
    fontWeight: '600',
    padding: '20px 25px',
  },
  titlePadding: {
    padding: 0,
    paddingLeft: theme.spacing(2),
  },
  titleMargin: {
    marginRight: theme.spacing(4),
  },
  headerButton: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  headerButtonCreate: {
    // margin: theme.spacing(2, 0, 0, 2),
  },
  goBackContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.darkGrey,
    '& tr': {
      color: 'white',
    },
  },
  tableFooter: {
    backgroundColor: theme.palette.primary.dark,
    '& .MuiTablePagination-root': {
      color: '#535353',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
      padding: theme.spacing(0.5, 0),
    },
    '& .MuiTablePagination-selectLabel': {
      color: '#535353',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    '& .MuiTablePagination-selectIcon': {
      color: '#535353',
      fontSize: 28,
      paddingBottom: theme.spacing(0.25),
    },
    '& .MuiTablePagination-actions': {
      backgroundColor: 'red',
    },
    '& .MuiTablePagination-displayedRows': {
      color: '#535353',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    '& .MuiTablePagination-select': {
      margin: theme.spacing(0.75),
    },
  },
  unassigned: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipText: {
    width: '100%',
  },
  cancelLink: {
    cursor: 'pointer',
  },
  statusIcon: {
    padding: 0,
    paddingLeft: '10px',
    transform: 'rotate: 180deg',
  },
  actionButton: {
    margin: '5px',
    marginBottom: '0px',
    textDecoration: 'none',
  },
  addParticipantButton: {
    paddingBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    textDecoration: 'none',
  },
  gridAddParticipantButton: {
    display: 'flex',
    alignItems: 'self-end',
    justifyContent: 'end',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'start',
    },
  },
  gridContentButtonAdd: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
  },
  isLoadingParticipants: { backgroundColor: 'white', textAlign: 'center' },
  actionButtonBlue: {
    margin: '0 5px',
    textDecoration: 'none',
  },
  openSchedule: {
    margin: '16px -6px 0 0',
  },
  massiveButton: {
    marginTop: '10px',
    marginBottom: '0px',
  },
  actionButtonText: {
    color: '#6A6A6A',
    textTransform: 'none',
  },
  actionButtonWithText: {
    margin: '5px',
    marginBottom: '0px',
    textDecoration: 'none',
    color: '#6A6A6A',
    textTransform: 'none',
  },
  unreadBadgeRoot: {
    width: '24px',
    height: '24px',
  },
  filterWrap: {
    flexFlow: 'wrap',
  },
  filterInput: {
    marginLeft: theme.spacing(2),
  },
  actionContainer: {
    backgroundColor: theme.palette.primary.dark,
  },
  searchField: {
    '&.MuiFormControl-root': {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  textFieldSearch: {
    maxWidth: '100%',
    width: '330px',
  },
  tableRowSelected: {
    color: 'lightgray',
    backgroundColor: '#fafafa',
    '& input[type="checkbox"]:disabled + svg': {
      visibility: 'hidden',
      color: 'red',
    },
  },
  alignEnd: {
    alignSelf: 'flex-end',
  },
  btnCustom: {
    padding: theme.spacing(0),
  },
  tableHeaderItem: {
    border: 'none',
  },
  tableRowCustom: {
    '& th': {
      lineHeight: '1.2',
      verticalAlign: 'bottom',
    },
  },
  participantsColumn: {
    backgroundColor: theme.palette.secondaryAccent.almostWhite,
  },
  tableRowBodyCustom: {
    '& td': {
      lineHeight: '1.2',
      padding: '6px 12px',
      '&:nth-of-type(4)': {
        whiteSpace: (props) => (props.activityTable ? 'nowrap' : 'normal'),
      },
    },
  },
  tableRowDivider: {
    height: '15px',
  },
  tableRowHeadCustom: {
    '& th': {
      padding: '6px 12px',
    },
  },
  menuBtn: {
    position: 'relative',
  },
  menuOptions: {
    // position: 'absolute',
    backgroundColor: theme.palette.secondaryAccent.light,
  },
  paperCustom: {
    // backgroundColor: theme.palette.secondaryAccent.light,
    boxShadow: 'none',
    borderRadius: '6px !important',
    color: 'white',
    '&::before': {
      content: '',
      width: '0',
      height: '0',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: '10px solid #555',
      marginLeft: '85px',
    },
  },
  popoverItem: {
    backgroundColor: theme.palette.secondaryAccent.light,
    borderBottom: '1px solid black',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&.MuiListItem-button:hover': {
      backgroundColor: theme.palette.secondaryAccent.light,
      color: theme.palette.secondaryAccent.dark,
    },
  },
  disabledLabel: {
    '&.MuiListItem-root.Mui-disabled': {
      opacity: 1,
    },
    color: theme.palette.secondaryAccent.dark,
  },
  divider: {
    borderBottom: '1px solid #53535342',
  },
  disclaimer: {
    backgroundColor: theme.palette.info.light,
    fontStyle: 'italic',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  btnClearFilters: {
    marginLeft: '30px',
    maxHeight: '32px',
  },
  roundsFilter: {
    marginTop: 0,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
  filterDropdowm: {
    borderRadius: '2px',
    maxWidth: '158px',
  },
  filterLabelContainer: {
    minWidth: '75px',
  },
  filterLabel: {
    display: 'flex !important',
  },
  filterLabelFilter: {
    marginRight: '5px',
  },
  actionIcon: {
    padding: 0,
  },
  boIconButtons: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
  },
  tableRowDate: {
    textAlign: 'center',
  },
  participantCheckbox: {
    marginLeft: '7px',
  },
  participantSelect: {
    marginTop: '6px',
  },
  selectedBadge: {
    borderRadius: '3px',
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noData: {
    padding: '80px',
    fontWeight: '700',
  },
  breadcrumbs: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 24,
    marginTop: 32,
    width: 320,
    '& p': {
      color: '#757575',
      fontSize: 13,
      fontWeight: 600,
    },
  },
  location: {
    color: '#262626',
    marginRight: 28,
    marginTop: 32,
    '& p': {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 17,
      fontWeight: 500,
    },
  },
  appointmentsLocation: {
    color: '#262626',
    marginRight: 28,
    '& p': {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 17,
      fontWeight: 500,
    },
  },
  appointmentOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0, 2, -2),
  },
  filterAppointmentContainer: {
    width: '600px',
    '& > *': {
      marginLeft: theme.spacing(2),
      width: 280,
    },
  },
  buttonsAppointmentContainer: {
    display: 'flex',
    width: 340,
    '& > *:nth-of-type(1)': {
      width: 220,
    },
    '& > *:nth-of-type(2)': {
      height: 32,
      marginTop: 10,
      width: 120,
    },
  },
  icon: {
    marginRight: 16,
  },
  filtersAddParticipants: {
    maxWidth: 1480,
    width: '96vw',
  },
  lockedTurn: {
    color: '#8B8B8B',
  },

  addParticipantContent: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'end',
  },
  addParticipantBox: {
    display: 'flex',
    justifyContent: 'end',
  },
  addParticipantMenu: {
    position: 'absolute',
    zIndex: '1',
  },
  addParticipantLink: {
    textDecoration: 'none',
    color: 'black',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70px',
  },
  cyclesTitleContainer: {
    paddingTop: '10px',
    paddingLeft: '40px',
  },
  graphContentTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  portfolioExportTitle: {
    textAlign: 'left',
    fontWeight: 400,
    width: '70%',
    paddingLeft: '20px',
  },
  graphContentTitle: {
    textAlign: 'left',
    fontWeight: 400,
    width: '80%',
    paddingLeft: '20px',
  },
  disabled: {
    color: '#8B8B8B',
  },
  domainCard: {
    marginBottom: '24px',
  },
  domainCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '74px',
  },
  domainCardTitle: {
    fontWeight: 700,
    fontSize: '14px',
  },
  buttonDomain: {
    border: (props) =>
      props?.colors?.main
        ? `1px solid ${props?.colors?.dark}`
        : '1px solid #262626',
    borderRadius: 30,
    background: 'rgba(227, 49, 61, 1)',
    color: 'white',
    display: 'flex',
    padding: theme.spacing(0.5, 2.5),
    '& p': {
      fontSize: 13,
      fontWeight: 600,
    },
    '&:hover': {
      backgroundColor: '#000',
      border: '1px solid #262626',
      color: '#FFF',
    },
  },
}));
