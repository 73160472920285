import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { MaterializeCommonStyles } from '../MaterializeStylesCommons';

export default function LoginInactiveUser() {
  const { t } = useTranslation();
  const classes = MaterializeCommonStyles();

  return (
    <Container className={classes.loginWarning}>
      <ErrorOutline htmlColor={'orange'} />
      <div>
        <Typography variant='body1' sx={{ mt: 0.25 }}>
          {t('login.inactiveUser')}
        </Typography>
      </div>
    </Container>
  );
}
