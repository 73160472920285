import makeStyles from '@mui/styles/makeStyles';

export const useProfileActionsStyles = makeStyles((theme) => ({
  link: {
    padding: 0,
    textDecoration: 'none',
    '&.MuiButton-root': {
      minWidth: 44,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonWithAvatar: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 30,
    color: theme.palette.primary.white,
    padding: theme.spacing(0.375, 0.5, 0.375, 2),
    textDecoration: 'none',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      width: 'unset',
    },
    '& > p': {
      fontSize: 14,
      fontWeight: 700,
      marginRight: theme.spacing(1.5),
    },
  },
  avatarInButton: {
    height: 40,
    width: 40,
  },
  orangeAvatarInButton: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.main,
    height: 40,
    width: 40,
  },
}));
