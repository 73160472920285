import { useRef, useState } from 'react';
import {
  Badge,
  Button,
  ClickAwayListener,
  Collapse,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { IconWithMenuStyles } from './IconWithMenuStyles.css';
import { CustomIcon } from '../CustomIcons/CustomIcons';
import MessagePreview from './MessagePreview';
import { onKeyDownEscape } from '../../utils/formHelper';

function MessageIcon({ onClick, isLargeWindow, unread, checkUnread }) {
  const classes = IconWithMenuStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const largeWindow = useMediaQuery(theme.breakpoints.up('lg'));
  const conversations = unread?.conversations?.messages_list;
  const unreadCount = unread?.conversations?.total_unread;
  const hasConvertionsUnRead = conversations?.length > 0 && unreadCount === 0;

  const handleClose = () => setOpen(false);

  const handleIconClick = () => {
    isMobile ? goToView() : toggleMenu();
    onClick?.();
  };

  const goToView = () => history.push('/mensajes');

  const toggleMenu = () => {
    const newStatus = !open;
    setOpen(newStatus);
    if (newStatus) {
      checkUnread();
    }
  };

  const onClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const labelMenu = () => {
    return unreadCount
      ? isLargeWindow
        ? t('accessibility.navbar.messageIcon.labelIconWhitMessages', {
            count: unreadCount,
          }) + t('accessibility.navbar.lateralMenu.tab')
        : t('accessibility.navbar.messageIcon.labelIconWhitMessages', {
            count: unreadCount,
          })
      : t('accessibility.navbar.messageIcon.labelIcon');
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.hasNotification]: unreadCount > 0,
      })}
      onKeyDown={(e) => onKeyDownEscape(e, setOpen)}
    >
      <Button
        className={classes.itemRoot}
        onClick={handleIconClick}
        disableRipple
        ref={anchorRef}
        aria-label={labelMenu()}
      >
        <Badge
          badgeContent={hasConvertionsUnRead ? '' : unreadCount}
          color='secondary'
          className={
            hasConvertionsUnRead
              ? classes.badgeConvertionsUnRead
              : classes.badge
          }
          role='none'
        >
          <CustomIcon
            color='white'
            iconSrc={`${process.env.PUBLIC_URL}/icons/message.svg`}
            size='40'
            iconAlt=''
          />
        </Badge>
      </Button>
      <Popper
        open={!isMobile && open}
        anchorEl={anchorRef.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: isMd ? [-100, 0] : [-20, 0],
            },
          },
        ]}
        style={{ paddingTop: largeWindow ? '' : '10px' }}
      >
        {({ TransitionProps }) => (
          <Collapse in={open} {...TransitionProps}>
            <Paper className={classes.messagePaper}>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList
                  id='MessageIcon-inner-papper'
                  autoFocusItem={open}
                  disablePadding
                >
                  <MenuItem
                    className={classnames(
                      classes.messageHeaderWrapper,
                      unreadCount && classes.messageNotEmpty
                    )}
                    disableRipple
                  >
                    {unreadCount || hasConvertionsUnRead ? (
                      <Typography className={classes.messageHeader}>
                        {t('navbar.messages.not-empty')}
                      </Typography>
                    ) : (
                      <Typography className={classes.messageHeader}>
                        {t('navbar.messages.empty')}
                      </Typography>
                    )}
                  </MenuItem>
                  {conversations?.map((conversation, index) => (
                    <MenuItem key={index}>
                      <MessagePreview
                        conversation={conversation}
                        handleClose={handleClose}
                      />
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={handleClose}
                    className={classes.messageFooterWrapper}
                  >
                    <Link className={classes.allMenssage} to={'/mensajes'}>
                      <Typography className={classes.messageFooter}>
                        {t('navbar.messages.view-all')}
                      </Typography>
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Collapse>
        )}
      </Popper>
    </div>
  );
}

export default MessageIcon;
