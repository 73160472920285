import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import classnames from 'classnames';

import {
  DATE_FORMAT,
  RoundsLocation,
  RoundsRoles,
} from '../../../utils/Constants';
import { capitalizeText, formatDate } from '../../../utils/formHelper';
import { useMassiveNotificationStyles } from '../../../components/backoffice/NotificationsMassive/useMassiveNotificationStyles';
import AddToCalendarDetailEvent from './AddToCalendarDetailEvent';

export const TurnInfo = ({ event, participantRol }) => {
  const { t } = useTranslation();
  const classes = useMassiveNotificationStyles();
  const isSeller = participantRol === RoundsRoles.SELLER;
  const hasTurn = event?.has_a_turn;
  const setDate = (date) => {
    capitalizeText(
      formatDate(
        event?.participants?.[0]?.schedule_start,
        DATE_FORMAT.READABLE_DAY_WITH_READABLE_DAY
      )
    );
  };

  const date = isSeller
    ? hasTurn
      ? setDate(event?.participants?.[0]?.schedule_start)
      : setDate(event?.date_start)
    : formatDate(event?.date_end, DATE_FORMAT.STANDARD) !==
      formatDate(event?.date_start, DATE_FORMAT.STANDARD)
    ? t(`rounds.dates`, {
        dateStart: formatDate(
          event?.date_start,
          DATE_FORMAT.STANDARD_WITHOUT_YEAR
        ),
        dateEnd: formatDate(event?.date_end, DATE_FORMAT.STANDARD_WITHOUT_YEAR),
      })
    : capitalizeText(
        formatDate(
          event?.date_start,
          DATE_FORMAT.READABLE_DAY_WITH_READABLE_DAY
        )
      );
  const timeStart = isSeller
    ? hasTurn
      ? formatDate(
          event?.participants?.[0]?.schedule_start,
          DATE_FORMAT.TIME_WITH_DOTS
        )
      : formatDate(event?.date_start, DATE_FORMAT.TIME_WITH_DOTS)
    : formatDate(event?.date_start, DATE_FORMAT.TIME_WITH_DOTS);
  const timeEnd = isSeller
    ? hasTurn
      ? formatDate(
          event?.participants?.[0]?.schedule_end,
          DATE_FORMAT.TIME_WITH_DOTS
        )
      : formatDate(event?.date_end, DATE_FORMAT.TIME_WITH_DOTS)
    : formatDate(event?.date_end, DATE_FORMAT.TIME_WITH_DOTS);

  const times = t('rounds.times', { from: timeStart, to: timeEnd });

  return (
    <Container
      className={classes.appointmentDetailsContainer}
      sx={{
        marginBottom: '20px',
      }}
    >
      <Container disableGutters className={classes.appoint}>
        <Container disableGutters>
          <Container
            disableGutters
            className={classes.appointmentDetailsTitleContainer}
          >
            <EventAvailableIcon style={{ color: '#1C6BFB' }} />
            <Typography
              className={classnames(
                classes.appointmentDetailsTitle,
                classes.calendarIconBlue
              )}
            >
              {t('round-notification-appointment-detail.title')}
            </Typography>
          </Container>
        </Container>

        <Container disableGutters className={classes.appointmentDetails}>
          <div className={classes.datePadding}>
            <Typography
              variant='body1'
              className={classes.appointmentDetailsSubtitle}
            >
              {t('round-notification-appointment-detail.date')}
            </Typography>
            <Typography component='p' className={classes.text}>
              {date}
            </Typography>
            <Typography component='p' className={classes.text}>
              {times}
            </Typography>
          </div>
          <div>
            <Typography
              variant='body1'
              className={classes.appointmentDetailsSubtitle}
            >
              {t('round-notification-appointment-detail.location')}
            </Typography>
            <Typography component='p' className={classes.text}>
              {event?.location_desc
                ? capitalizeText(event?.location_desc)
                : null}
            </Typography>
            <Typography component='p' className={classes.text}>
              {event?.location === RoundsLocation.OTHER
                ? capitalizeText(event?.location_address)
                : t('rounds.location.virtual_mica')}
            </Typography>
          </div>
        </Container>
        {event && (
          <AddToCalendarDetailEvent eventView event={event} classes={classes} />
        )}
      </Container>
    </Container>
  );
};

export default TurnInfo;
