import { Button, MenuItem, Typography } from '@mui/material';
import { NavbarStyles } from '../../NavbarStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import SubLateralProgramsMenuNavbar from '../../SubLateralProgramsMenuNavbar';
import DropDownPrograms from './DropDownPrograms';

const ButtonProgramMenu = ({
  isMobile,
  setMenuCyclesOpen,
  isLargeWindow,
  setMenuSectorsOpen,
  closeMenu,
  setMenuDomainsOpen,
  menuDomainsOpen,
  menuDomainsAnchorRef,
  domains,
}) => {
  const classes = NavbarStyles();
  const { t } = useTranslation();

  const labelMenu = () => {
    return isLargeWindow
      ? t('accessibility.navbar.subMenu.domains') +
          t('accessibility.navbar.subMenu.button') +
          t('accessibility.navbar.lateralMenu.tab')
      : t('accessibility.navbar.subMenu.domains') +
          t('accessibility.navbar.subMenu.button');
  };
  return (
    <>
      <MenuItem
        id='domain-button'
        onClick={() => {
          setMenuDomainsOpen(!menuDomainsOpen);
          setMenuCyclesOpen(false);
          setMenuSectorsOpen(false);
        }}
        className={classes.mainMenuItemWrapper}
        ref={menuDomainsAnchorRef}
        aria-expanded={menuDomainsOpen}
      >
        <Button className={classes.boxSector}>
          <Typography className={classes.mainMenuItem}>
            {t('navbar.links.main-menu.domains.title')}
          </Typography>
          <span
            aria-label={labelMenu()}
            size='large'
            className={classes.mainMenuItemIcon}
          >
            {!isMobile ? (
              <KeyboardArrowRightIcon />
            ) : menuDomainsOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </span>
        </Button>
      </MenuItem>
      <SubLateralProgramsMenuNavbar
        menuDomainsOpen={menuDomainsOpen}
        isMobile={isMobile}
        menuDomainsAnchorRef={menuDomainsAnchorRef}
        setMenuDomainsOpen={setMenuDomainsOpen}
        closeMenu={closeMenu}
        domains={domains}
      />
      <DropDownPrograms
        isMobile={isMobile}
        menuDomainsOpen={menuDomainsOpen}
        closeMenu={closeMenu}
        domains={domains}
      />
    </>
  );
};

export default ButtonProgramMenu;
